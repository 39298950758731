const regionalSelect = [
    {"ed":"010001","congress":"Congress CD 18","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"Bedford","councilDistrict":"","village":""},
    {"ed":"010002","congress":"Congress CD 18","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"Bedford","councilDistrict":"","village":""},
    {"ed":"010003","congress":"Congress CD 18","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"Bedford","councilDistrict":"","village":""},
    {"ed":"010004","congress":"Congress CD 18","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"Bedford","councilDistrict":"","village":""},
    {"ed":"010005","congress":"Congress CD 18","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"Bedford","councilDistrict":"","village":""},
    {"ed":"010006","congress":"Congress CD 18","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"Bedford","councilDistrict":"","village":""},
    {"ed":"010007","congress":"Congress CD 18","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"Bedford","councilDistrict":"","village":""},
    {"ed":"010008","congress":"Congress CD 18","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"Bedford","councilDistrict":"","village":""},
    {"ed":"010009","congress":"Congress CD 18","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"Bedford","councilDistrict":"","village":""},
    {"ed":"010010","congress":"Congress CD 18","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"Bedford","councilDistrict":"","village":""},
    {"ed":"010011","congress":"Congress CD 18","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"Bedford","councilDistrict":"","village":""},
    {"ed":"010012","congress":"Congress CD 18","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"Bedford","councilDistrict":"","village":""},
    {"ed":"010013","congress":"Congress CD 18","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"Bedford","councilDistrict":"","village":""},
    {"ed":"010014","congress":"Congress CD 18","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"Bedford","councilDistrict":"","village":""},
    {"ed":"010015","congress":"Congress CD 18","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"Bedford","councilDistrict":"","village":""},
    {"ed":"010016","congress":"Congress CD 18","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"Bedford","councilDistrict":"","village":""},
    {"ed":"010017","congress":"Congress CD 18","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"Bedford","councilDistrict":"","village":""},
    {"ed":"010018","congress":"Congress CD 18","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"Bedford","councilDistrict":"","village":""},
    {"ed":"020001","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 1","townCity":"Cortlandt","councilDistrict":"","village":"Buchanan"},
    {"ed":"020002","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 1","townCity":"Cortlandt","councilDistrict":"","village":"Buchanan"},
    {"ed":"020003","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 1","townCity":"Cortlandt","councilDistrict":"","village":""},
    {"ed":"020004","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 1","townCity":"Cortlandt","councilDistrict":"","village":""},
    {"ed":"020005","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Cortlandt","councilDistrict":"","village":""},
    {"ed":"020006","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Cortlandt","councilDistrict":"","village":"Croton"},
    {"ed":"020007","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Cortlandt","councilDistrict":"","village":"Croton"},
    {"ed":"020008","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Cortlandt","councilDistrict":"","village":"Croton"},
    {"ed":"020009","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Cortlandt","councilDistrict":"","village":"Croton"},
    {"ed":"020010","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Cortlandt","councilDistrict":"","village":"Croton"},
    {"ed":"020011","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Cortlandt","councilDistrict":"","village":""},
    {"ed":"020012","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 1","townCity":"Cortlandt","councilDistrict":"","village":""},
    {"ed":"020013","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Cortlandt","councilDistrict":"","village":""},
    {"ed":"020014","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 1","townCity":"Cortlandt","councilDistrict":"","village":""},
    {"ed":"020015","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 1","townCity":"Cortlandt","councilDistrict":"","village":""},
    {"ed":"020016","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 1","townCity":"Cortlandt","councilDistrict":"","village":""},
    {"ed":"020017","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Cortlandt","councilDistrict":"","village":""},
    {"ed":"020018","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 1","townCity":"Cortlandt","councilDistrict":"","village":""},
    {"ed":"020019","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Cortlandt","councilDistrict":"","village":""},
    {"ed":"020020","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 1","townCity":"Cortlandt","councilDistrict":"","village":""},
    {"ed":"020021","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 1","townCity":"Cortlandt","councilDistrict":"","village":""},
    {"ed":"020022","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Cortlandt","councilDistrict":"","village":""},
    {"ed":"020023","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Cortlandt","councilDistrict":"","village":""},
    {"ed":"020024","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 1","townCity":"Cortlandt","councilDistrict":"","village":""},
    {"ed":"020025","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Cortlandt","councilDistrict":"","village":"Croton"},
    {"ed":"020026","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 1","townCity":"Cortlandt","councilDistrict":"","village":""},
    {"ed":"020027","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 1","townCity":"Cortlandt","councilDistrict":"","village":""},
    {"ed":"020028","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 1","townCity":"Cortlandt","councilDistrict":"","village":""},
    {"ed":"020029","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Cortlandt","councilDistrict":"","village":""},
    {"ed":"020030","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Cortlandt","councilDistrict":"","village":""},
    {"ed":"020031","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Cortlandt","councilDistrict":"","village":"Croton"},
    {"ed":"020032","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Cortlandt","councilDistrict":"","village":""},
    {"ed":"020033","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Cortlandt","councilDistrict":"","village":""},
    {"ed":"020034","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 1","townCity":"Cortlandt","councilDistrict":"","village":""},
    {"ed":"020035","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 1","townCity":"Cortlandt","councilDistrict":"","village":""},
    {"ed":"020038","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Cortlandt","councilDistrict":"","village":""},
    {"ed":"020039","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Cortlandt","councilDistrict":"","village":"Croton"},
    {"ed":"030001","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"Eastchester","councilDistrict":"","village":""},
    {"ed":"030002","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"Eastchester","councilDistrict":"","village":""},
    {"ed":"030003","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"Eastchester","councilDistrict":"","village":""},
    {"ed":"030004","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"Eastchester","councilDistrict":"","village":""},
    {"ed":"030005","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"Eastchester","councilDistrict":"","village":""},
    {"ed":"030006","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"Eastchester","councilDistrict":"","village":""},
    {"ed":"030007","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"Eastchester","councilDistrict":"","village":""},
    {"ed":"030008","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"Eastchester","councilDistrict":"","village":""},
    {"ed":"030009","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"Eastchester","councilDistrict":"","village":""},
    {"ed":"030010","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"Eastchester","councilDistrict":"","village":"Tuckahoe"},
    {"ed":"030011","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"Eastchester","councilDistrict":"","village":"Tuckahoe"},
    {"ed":"030012","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"Eastchester","councilDistrict":"","village":"Tuckahoe"},
    {"ed":"030013","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"Eastchester","councilDistrict":"","village":"Tuckahoe"},
    {"ed":"030014","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"Eastchester","councilDistrict":"","village":"Tuckahoe"},
    {"ed":"030015","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"Eastchester","councilDistrict":"","village":""},
    {"ed":"030016","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 15","townCity":"Eastchester","councilDistrict":"","village":"Bronxville"},
    {"ed":"030017","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 15","townCity":"Eastchester","councilDistrict":"","village":"Bronxville"},
    {"ed":"030018","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 15","townCity":"Eastchester","councilDistrict":"","village":"Bronxville"},
    {"ed":"030019","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 15","townCity":"Eastchester","councilDistrict":"","village":"Bronxville"},
    {"ed":"030020","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 15","townCity":"Eastchester","councilDistrict":"","village":"Bronxville"},
    {"ed":"030021","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 15","townCity":"Eastchester","councilDistrict":"","village":"Bronxville"},
    {"ed":"030022","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 15","townCity":"Eastchester","councilDistrict":"","village":"Bronxville"},
    {"ed":"030023","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"Eastchester","councilDistrict":"","village":""},
    {"ed":"030024","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"Eastchester","councilDistrict":"","village":""},
    {"ed":"030025","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"Eastchester","councilDistrict":"","village":""},
    {"ed":"030026","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"Eastchester","councilDistrict":"","village":""},
    {"ed":"030027","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"Eastchester","councilDistrict":"","village":""},
    {"ed":"030028","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"Eastchester","councilDistrict":"","village":""},
    {"ed":"030029","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"Eastchester","councilDistrict":"","village":""},
    {"ed":"030030","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"Eastchester","councilDistrict":"","village":""},
    {"ed":"030031","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"Eastchester","councilDistrict":"","village":""},
    {"ed":"030032","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"Eastchester","councilDistrict":"","village":"Tuckahoe"},
    {"ed":"030033","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"Eastchester","councilDistrict":"","village":"Tuckahoe"},
    {"ed":"030034","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"Eastchester","councilDistrict":"","village":""},
    {"ed":"030035","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"Eastchester","councilDistrict":"","village":""},
    {"ed":"030036","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"Eastchester","councilDistrict":"","village":""},
    {"ed":"030037","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"Eastchester","councilDistrict":"","village":""},
    {"ed":"030038","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"Eastchester","councilDistrict":"","village":""},
    {"ed":"030039","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"Eastchester","councilDistrict":"","village":""},
    {"ed":"040001","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 8","townCity":"Greenburgh","councilDistrict":"","village":"Tarrytown"},
    {"ed":"040002","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 8","townCity":"Greenburgh","councilDistrict":"","village":"Tarrytown"},
    {"ed":"040003","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 8","townCity":"Greenburgh","councilDistrict":"","village":"Tarrytown"},
    {"ed":"040004","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 8","townCity":"Greenburgh","councilDistrict":"","village":"Tarrytown"},
    {"ed":"040005","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 8","townCity":"Greenburgh","councilDistrict":"","village":"Tarrytown"},
    {"ed":"040006","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 8","townCity":"Greenburgh","councilDistrict":"","village":"Tarrytown"},
    {"ed":"040007","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 8","townCity":"Greenburgh","councilDistrict":"","village":"Tarrytown"},
    {"ed":"040008","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":"Irvington"},
    {"ed":"040009","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":"Irvington"},
    {"ed":"040010","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":"Irvington"},
    {"ed":"040011","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":"Irvington"},
    {"ed":"040012","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":"Dobbs Ferry"},
    {"ed":"040013","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":"Dobbs Ferry"},
    {"ed":"040014","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":"Dobbs Ferry"},
    {"ed":"040015","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":"Dobbs Ferry"},
    {"ed":"040016","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":"Dobbs Ferry"},
    {"ed":"040017","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":"Dobbs Ferry"},
    {"ed":"040018","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":"Hastings"},
    {"ed":"040019","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":"Hastings"},
    {"ed":"040020","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":"Hastings"},
    {"ed":"040021","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":"Hastings"},
    {"ed":"040022","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":"Hastings"},
    {"ed":"040023","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":"Hastings"},
    {"ed":"040024","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":"Ardsley"},
    {"ed":"040025","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":"Ardsley"},
    {"ed":"040026","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 8","townCity":"Greenburgh","councilDistrict":"","village":"Elmsford"},
    {"ed":"040027","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 8","townCity":"Greenburgh","councilDistrict":"","village":"Elmsford"},
    {"ed":"040028","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 8","townCity":"Greenburgh","councilDistrict":"","village":"Elmsford"},
    {"ed":"040029","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 3","townCity":"Greenburgh","councilDistrict":"","village":""},
    {"ed":"040030","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 8","townCity":"Greenburgh","councilDistrict":"","village":""},
    {"ed":"040031","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":""},
    {"ed":"040032","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":""},
    {"ed":"040033","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":""},
    {"ed":"040034","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":""},
    {"ed":"040035","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":""},
    {"ed":"040036","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":""},
    {"ed":"040037","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":""},
    {"ed":"040038","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":""},
    {"ed":"040039","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":""},
    {"ed":"040040","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":""},
    {"ed":"040041","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 8","townCity":"Greenburgh","councilDistrict":"","village":""},
    {"ed":"040042","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 8","townCity":"Greenburgh","councilDistrict":"","village":""},
    {"ed":"040043","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":""},
    {"ed":"040044","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 8","townCity":"Greenburgh","councilDistrict":"","village":""},
    {"ed":"040045","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 8","townCity":"Greenburgh","councilDistrict":"","village":""},
    {"ed":"040046","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 8","townCity":"Greenburgh","councilDistrict":"","village":""},
    {"ed":"040047","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 8","townCity":"Greenburgh","councilDistrict":"","village":""},
    {"ed":"040048","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 3","townCity":"Greenburgh","councilDistrict":"","village":""},
    {"ed":"040049","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":""},
    {"ed":"040050","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":""},
    {"ed":"040051","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":""},
    {"ed":"040052","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":"Hastings"},
    {"ed":"040053","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":"Ardsley"},
    {"ed":"040054","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":"Ardsley"},
    {"ed":"040055","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 8","townCity":"Greenburgh","councilDistrict":"","village":"Tarrytown"},
    {"ed":"040056","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 8","townCity":"Greenburgh","councilDistrict":"","village":""},
    {"ed":"040057","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":""},
    {"ed":"040058","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":"Dobbs Ferry"},
    {"ed":"040059","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":"Hastings"},
    {"ed":"040060","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":"Irvington"},
    {"ed":"040061","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 8","townCity":"Greenburgh","councilDistrict":"","village":""},
    {"ed":"040062","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":""},
    {"ed":"040063","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 8","townCity":"Greenburgh","councilDistrict":"","village":""},
    {"ed":"040064","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 8","townCity":"Greenburgh","councilDistrict":"","village":""},
    {"ed":"040065","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":"Dobbs Ferry"},
    {"ed":"040066","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":""},
    {"ed":"040067","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 3","townCity":"Greenburgh","councilDistrict":"","village":""},
    {"ed":"040068","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 8","townCity":"Greenburgh","councilDistrict":"","village":""},
    {"ed":"040069","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":""},
    {"ed":"040070","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":""},
    {"ed":"040071","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 8","townCity":"Greenburgh","councilDistrict":"","village":""},
    {"ed":"040072","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":""},
    {"ed":"040073","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":""},
    {"ed":"040074","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 8","townCity":"Greenburgh","councilDistrict":"","village":"Tarrytown"},
    {"ed":"040075","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":""},
    {"ed":"040076","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 8","townCity":"Greenburgh","councilDistrict":"","village":"Tarrytown"},
    {"ed":"040077","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":""},
    {"ed":"040078","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 8","townCity":"Greenburgh","councilDistrict":"","village":""},
    {"ed":"040079","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":"Irvington"},
    {"ed":"040080","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 12","townCity":"Greenburgh","councilDistrict":"","village":"Dobbs Ferry"},
    {"ed":"040081","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 8","townCity":"Greenburgh","councilDistrict":"","village":""},
    {"ed":"050001","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 5","townCity":"Harrison","councilDistrict":"","village":""},
    {"ed":"050002","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 5","townCity":"Harrison","councilDistrict":"","village":""},
    {"ed":"050003","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 6","townCity":"Harrison","councilDistrict":"","village":""},
    {"ed":"050004","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 6","townCity":"Harrison","councilDistrict":"","village":""},
    {"ed":"050005","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 7","townCity":"Harrison","councilDistrict":"","village":""},
    {"ed":"050006","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 6","townCity":"Harrison","councilDistrict":"","village":""},
    {"ed":"050007","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 6","townCity":"Harrison","councilDistrict":"","village":""},
    {"ed":"050008","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 6","townCity":"Harrison","councilDistrict":"","village":""},
    {"ed":"050009","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 6","townCity":"Harrison","councilDistrict":"","village":""},
    {"ed":"050010","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 6","townCity":"Harrison","councilDistrict":"","village":""},
    {"ed":"050011","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 5","townCity":"Harrison","councilDistrict":"","village":""},
    {"ed":"050012","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 7","townCity":"Harrison","councilDistrict":"","village":""},
    {"ed":"050013","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 6","townCity":"Harrison","councilDistrict":"","village":""},
    {"ed":"050014","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 6","townCity":"Harrison","councilDistrict":"","village":""},
    {"ed":"050015","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 6","townCity":"Harrison","councilDistrict":"","village":""},
    {"ed":"050016","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 6","townCity":"Harrison","councilDistrict":"","village":""},
    {"ed":"050017","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 6","townCity":"Harrison","councilDistrict":"","village":""},
    {"ed":"050018","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 6","townCity":"Harrison","councilDistrict":"","village":""},
    {"ed":"050019","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 6","townCity":"Harrison","councilDistrict":"","village":""},
    {"ed":"050020","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 7","townCity":"Harrison","councilDistrict":"","village":""},
    {"ed":"060001","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"Lewisboro","councilDistrict":"","village":""},
    {"ed":"060002","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"Lewisboro","councilDistrict":"","village":""},
    {"ed":"060003","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"Lewisboro","councilDistrict":"","village":""},
    {"ed":"060004","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"Lewisboro","councilDistrict":"","village":""},
    {"ed":"060005","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"Lewisboro","councilDistrict":"","village":""},
    {"ed":"060006","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"Lewisboro","councilDistrict":"","village":""},
    {"ed":"060007","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"Lewisboro","councilDistrict":"","village":""},
    {"ed":"060008","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"Lewisboro","councilDistrict":"","village":""},
    {"ed":"060009","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"Lewisboro","councilDistrict":"","village":""},
    {"ed":"060010","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"Lewisboro","councilDistrict":"","village":""},
    {"ed":"060011","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"Lewisboro","councilDistrict":"","village":""},
    {"ed":"060012","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"Lewisboro","councilDistrict":"","village":""},
    {"ed":"070001","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Mamaroneck","councilDistrict":"","village":"Mamaroneck Village"},
    {"ed":"070002","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Mamaroneck","councilDistrict":"","village":"Mamaroneck Village"},
    {"ed":"070003","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Mamaroneck","councilDistrict":"","village":"Mamaroneck Village"},
    {"ed":"070004","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Mamaroneck","councilDistrict":"","village":"Mamaroneck Village"},
    {"ed":"070005","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Mamaroneck","councilDistrict":"","village":"Mamaroneck Village"},
    {"ed":"070006","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Mamaroneck","councilDistrict":"","village":"Larchmont"},
    {"ed":"070007","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Mamaroneck","councilDistrict":"","village":"Larchmont"},
    {"ed":"070008","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Mamaroneck","councilDistrict":"","village":"Larchmont"},
    {"ed":"070009","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Mamaroneck","councilDistrict":"","village":"Larchmont"},
    {"ed":"070010","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Mamaroneck","councilDistrict":"","village":"Larchmont"},
    {"ed":"070011","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Mamaroneck","councilDistrict":"","village":""},
    {"ed":"070012","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Mamaroneck","councilDistrict":"","village":""},
    {"ed":"070013","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Mamaroneck","councilDistrict":"","village":""},
    {"ed":"070014","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Mamaroneck","councilDistrict":"","village":""},
    {"ed":"070015","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Mamaroneck","councilDistrict":"","village":""},
    {"ed":"070016","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Mamaroneck","councilDistrict":"","village":""},
    {"ed":"070017","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Mamaroneck","councilDistrict":"","village":"Mamaroneck Village"},
    {"ed":"070018","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Mamaroneck","councilDistrict":"","village":""},
    {"ed":"070019","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Mamaroneck","councilDistrict":"","village":""},
    {"ed":"070020","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Mamaroneck","councilDistrict":"","village":""},
    {"ed":"070021","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Mamaroneck","councilDistrict":"","village":""},
    {"ed":"070022","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Mamaroneck","councilDistrict":"","village":"Mamaroneck Village"},
    {"ed":"070023","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Mamaroneck","councilDistrict":"","village":"Mamaroneck Village"},
    {"ed":"070024","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Mamaroneck","councilDistrict":"","village":""},
    {"ed":"070025","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Mamaroneck","councilDistrict":"","village":"Larchmont"},
    {"ed":"070026","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Mamaroneck","councilDistrict":"","village":"Larchmont"},
    {"ed":"070027","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Mamaroneck","councilDistrict":"","village":"Mamaroneck Village"},
    {"ed":"070028","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Mamaroneck","councilDistrict":"","village":""},
    {"ed":"080001","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"Mount Kisco","councilDistrict":"","village":""},
    {"ed":"080002","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"Mount Kisco","councilDistrict":"","village":""},
    {"ed":"080003","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"Mount Kisco","councilDistrict":"","village":""},
    {"ed":"080004","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"Mount Kisco","councilDistrict":"","village":""},
    {"ed":"080005","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"Mount Kisco","councilDistrict":"","village":""},
    {"ed":"080006","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"Mount Kisco","councilDistrict":"","village":""},
    {"ed":"080007","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"Mount Kisco","councilDistrict":"","village":""},
    {"ed":"090001","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 3","townCity":"Mount Pleasant","councilDistrict":"","village":"Sleepy Hollow"},
    {"ed":"090002","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 3","townCity":"Mount Pleasant","councilDistrict":"","village":"Sleepy Hollow"},
    {"ed":"090003","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 8","townCity":"Mount Pleasant","councilDistrict":"","village":"Sleepy Hollow"},
    {"ed":"090004","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 3","townCity":"Mount Pleasant","councilDistrict":"","village":"Sleepy Hollow"},
    {"ed":"090005","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 3","townCity":"Mount Pleasant","councilDistrict":"","village":"Sleepy Hollow"},
    {"ed":"090006","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 3","townCity":"Mount Pleasant","councilDistrict":"","village":"Sleepy Hollow"},
    {"ed":"090007","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 8","townCity":"Mount Pleasant","councilDistrict":"","village":"Sleepy Hollow"},
    {"ed":"090009","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 3","townCity":"Mount Pleasant","councilDistrict":"","village":""},
    {"ed":"090010","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 3","townCity":"Mount Pleasant","councilDistrict":"","village":""},
    {"ed":"090011","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 3","townCity":"Mount Pleasant","councilDistrict":"","village":""},
    {"ed":"090012","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 3","townCity":"Mount Pleasant","councilDistrict":"","village":""},
    {"ed":"090013","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 3","townCity":"Mount Pleasant","councilDistrict":"","village":"Pleasantville"},
    {"ed":"090014","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 3","townCity":"Mount Pleasant","councilDistrict":"","village":"Pleasantville"},
    {"ed":"090015","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 3","townCity":"Mount Pleasant","councilDistrict":"","village":"Pleasantville"},
    {"ed":"090016","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 3","townCity":"Mount Pleasant","councilDistrict":"","village":""},
    {"ed":"090017","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 3","townCity":"Mount Pleasant","councilDistrict":"","village":"Briarcliff"},
    {"ed":"090018","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 3","townCity":"Mount Pleasant","councilDistrict":"","village":""},
    {"ed":"090019","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 3","townCity":"Mount Pleasant","councilDistrict":"","village":""},
    {"ed":"090020","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 3","townCity":"Mount Pleasant","councilDistrict":"","village":"Pleasantville"},
    {"ed":"090022","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 3","townCity":"Mount Pleasant","councilDistrict":"","village":""},
    {"ed":"090023","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 3","townCity":"Mount Pleasant","councilDistrict":"","village":""},
    {"ed":"090024","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 3","townCity":"Mount Pleasant","councilDistrict":"","village":""},
    {"ed":"090025","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 3","townCity":"Mount Pleasant","councilDistrict":"","village":""},
    {"ed":"090026","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 3","townCity":"Mount Pleasant","councilDistrict":"","village":""},
    {"ed":"090027","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 3","townCity":"Mount Pleasant","councilDistrict":"","village":""},
    {"ed":"090028","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 3","townCity":"Mount Pleasant","councilDistrict":"","village":""},
    {"ed":"090029","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 3","townCity":"Mount Pleasant","councilDistrict":"","village":""},
    {"ed":"090030","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 3","townCity":"Mount Pleasant","councilDistrict":"","village":""},
    {"ed":"090031","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 3","townCity":"Mount Pleasant","councilDistrict":"","village":"Pleasantville"},
    {"ed":"090032","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 3","townCity":"Mount Pleasant","councilDistrict":"","village":""},
    {"ed":"090033","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 3","townCity":"Mount Pleasant","councilDistrict":"","village":"Sleepy Hollow"},
    {"ed":"090034","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 3","townCity":"Mount Pleasant","councilDistrict":"","village":""},
    {"ed":"090035","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 3","townCity":"Mount Pleasant","councilDistrict":"","village":""},
    {"ed":"090036","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 3","townCity":"Mount Pleasant","councilDistrict":"","village":""},
    {"ed":"090037","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 3","townCity":"Mount Pleasant","councilDistrict":"","village":""},
    {"ed":"090038","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 3","townCity":"Mount Pleasant","councilDistrict":"","village":""},
    {"ed":"090039","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 3","townCity":"Mount Pleasant","councilDistrict":"","village":""},
    {"ed":"090040","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 3","townCity":"Mount Pleasant","councilDistrict":"","village":""},
    {"ed":"090041","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 3","townCity":"Mount Pleasant","councilDistrict":"","village":""},
    {"ed":"090042","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 3","townCity":"Mount Pleasant","councilDistrict":"","village":""},
    {"ed":"090043","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 3","townCity":"Mount Pleasant","councilDistrict":"","village":"Pleasantville"},
    {"ed":"090044","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 92","countyLegislator":"County Legislator LD 3","townCity":"Mount Pleasant","councilDistrict":"","village":""},
    {"ed":"100001","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 4","townCity":"New Castle","councilDistrict":"","village":""},
    {"ed":"100002","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 4","townCity":"New Castle","councilDistrict":"","village":""},
    {"ed":"100003","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 4","townCity":"New Castle","councilDistrict":"","village":""},
    {"ed":"100004","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 4","townCity":"New Castle","councilDistrict":"","village":""},
    {"ed":"100005","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 4","townCity":"New Castle","councilDistrict":"","village":""},
    {"ed":"100006","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 4","townCity":"New Castle","councilDistrict":"","village":""},
    {"ed":"100007","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 4","townCity":"New Castle","councilDistrict":"","village":""},
    {"ed":"100008","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 4","townCity":"New Castle","councilDistrict":"","village":""},
    {"ed":"100009","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 4","townCity":"New Castle","councilDistrict":"","village":""},
    {"ed":"100010","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 4","townCity":"New Castle","councilDistrict":"","village":""},
    {"ed":"100011","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 4","townCity":"New Castle","councilDistrict":"","village":""},
    {"ed":"100012","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 4","townCity":"New Castle","councilDistrict":"","village":""},
    {"ed":"100013","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 4","townCity":"New Castle","councilDistrict":"","village":""},
    {"ed":"100014","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 4","townCity":"New Castle","councilDistrict":"","village":""},
    {"ed":"100015","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 4","townCity":"New Castle","councilDistrict":"","village":""},
    {"ed":"100016","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 4","townCity":"New Castle","councilDistrict":"","village":""},
    {"ed":"110001","congress":"Congress CD 18","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 3","townCity":"North Castle","councilDistrict":"","village":""},
    {"ed":"110002","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 3","townCity":"North Castle","councilDistrict":"","village":""},
    {"ed":"110003","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 3","townCity":"North Castle","councilDistrict":"","village":""},
    {"ed":"110004","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 3","townCity":"North Castle","councilDistrict":"","village":""},
    {"ed":"110005","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 3","townCity":"North Castle","councilDistrict":"","village":""},
    {"ed":"110006","congress":"Congress CD 18","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 3","townCity":"North Castle","councilDistrict":"","village":""},
    {"ed":"110007","congress":"Congress CD 18","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 3","townCity":"North Castle","councilDistrict":"","village":""},
    {"ed":"110008","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 3","townCity":"North Castle","councilDistrict":"","village":""},
    {"ed":"110009","congress":"Congress CD 18","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 3","townCity":"North Castle","councilDistrict":"","village":""},
    {"ed":"110010","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 3","townCity":"North Castle","councilDistrict":"","village":""},
    {"ed":"110011","congress":"Congress CD 18","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 3","townCity":"North Castle","councilDistrict":"","village":""},
    {"ed":"120001","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"North Salem","councilDistrict":"","village":""},
    {"ed":"120002","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"North Salem","councilDistrict":"","village":""},
    {"ed":"120003","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"North Salem","councilDistrict":"","village":""},
    {"ed":"120004","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"North Salem","councilDistrict":"","village":""},
    {"ed":"120005","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"North Salem","councilDistrict":"","village":""},
    {"ed":"120006","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"North Salem","councilDistrict":"","village":""},
    {"ed":"120007","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"North Salem","councilDistrict":"","village":""},
    {"ed":"120008","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"North Salem","councilDistrict":"","village":""},
    {"ed":"130001","congress":"Congress CD 17","stateSenate":"State Senate SD 38","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Ossining","councilDistrict":"","village":"Ossining Village"},
    {"ed":"130002","congress":"Congress CD 17","stateSenate":"State Senate SD 38","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Ossining","councilDistrict":"","village":"Ossining Village"},
    {"ed":"130003","congress":"Congress CD 17","stateSenate":"State Senate SD 38","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Ossining","councilDistrict":"","village":"Ossining Village"},
    {"ed":"130004","congress":"Congress CD 17","stateSenate":"State Senate SD 38","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Ossining","councilDistrict":"","village":"Ossining Village"},
    {"ed":"130005","congress":"Congress CD 17","stateSenate":"State Senate SD 38","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Ossining","councilDistrict":"","village":"Ossining Village"},
    {"ed":"130006","congress":"Congress CD 17","stateSenate":"State Senate SD 38","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Ossining","councilDistrict":"","village":"Ossining Village"},
    {"ed":"130007","congress":"Congress CD 17","stateSenate":"State Senate SD 38","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Ossining","councilDistrict":"","village":"Ossining Village"},
    {"ed":"130008","congress":"Congress CD 17","stateSenate":"State Senate SD 38","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Ossining","councilDistrict":"","village":"Ossining Village"},
    {"ed":"130009","congress":"Congress CD 17","stateSenate":"State Senate SD 38","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Ossining","councilDistrict":"","village":"Ossining Village"},
    {"ed":"130010","congress":"Congress CD 17","stateSenate":"State Senate SD 38","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Ossining","councilDistrict":"","village":"Ossining Village"},
    {"ed":"130011","congress":"Congress CD 17","stateSenate":"State Senate SD 38","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Ossining","councilDistrict":"","village":"Ossining Village"},
    {"ed":"130012","congress":"Congress CD 17","stateSenate":"State Senate SD 38","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Ossining","councilDistrict":"","village":"Ossining Village"},
    {"ed":"130013","congress":"Congress CD 17","stateSenate":"State Senate SD 38","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Ossining","councilDistrict":"","village":""},
    {"ed":"130014","congress":"Congress CD 17","stateSenate":"State Senate SD 38","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Ossining","councilDistrict":"","village":""},
    {"ed":"130015","congress":"Congress CD 17","stateSenate":"State Senate SD 38","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Ossining","councilDistrict":"","village":"Briarcliff"},
    {"ed":"130016","congress":"Congress CD 17","stateSenate":"State Senate SD 38","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Ossining","councilDistrict":"","village":"Briarcliff"},
    {"ed":"130017","congress":"Congress CD 17","stateSenate":"State Senate SD 38","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Ossining","councilDistrict":"","village":""},
    {"ed":"130018","congress":"Congress CD 17","stateSenate":"State Senate SD 38","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Ossining","councilDistrict":"","village":"Ossining Village"},
    {"ed":"130019","congress":"Congress CD 17","stateSenate":"State Senate SD 38","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 3","townCity":"Ossining","councilDistrict":"","village":"Briarcliff"},
    {"ed":"130020","congress":"Congress CD 17","stateSenate":"State Senate SD 38","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Ossining","councilDistrict":"","village":""},
    {"ed":"130021","congress":"Congress CD 17","stateSenate":"State Senate SD 38","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Ossining","councilDistrict":"","village":"Ossining Village"},
    {"ed":"130022","congress":"Congress CD 17","stateSenate":"State Senate SD 38","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Ossining","councilDistrict":"","village":"Ossining Village"},
    {"ed":"130023","congress":"Congress CD 17","stateSenate":"State Senate SD 38","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Ossining","councilDistrict":"","village":"Ossining Village"},
    {"ed":"130024","congress":"Congress CD 17","stateSenate":"State Senate SD 38","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Ossining","councilDistrict":"","village":""},
    {"ed":"130025","congress":"Congress CD 17","stateSenate":"State Senate SD 38","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Ossining","councilDistrict":"","village":""},
    {"ed":"130026","congress":"Congress CD 17","stateSenate":"State Senate SD 38","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Ossining","councilDistrict":"","village":"Briarcliff"},
    {"ed":"130027","congress":"Congress CD 17","stateSenate":"State Senate SD 38","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Ossining","councilDistrict":"","village":"Briarcliff"},
    {"ed":"130028","congress":"Congress CD 17","stateSenate":"State Senate SD 38","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Ossining","councilDistrict":"","village":""},
    {"ed":"130029","congress":"Congress CD 17","stateSenate":"State Senate SD 38","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Ossining","councilDistrict":"","village":"Briarcliff"},
    {"ed":"130030","congress":"Congress CD 17","stateSenate":"State Senate SD 38","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Ossining","councilDistrict":"","village":"Ossining Village"},
    {"ed":"140001","congress":"Congress CD 16","stateSenate":"State Senate SD 34","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 11","townCity":"Pelham","councilDistrict":"","village":"Pelham Village"},
    {"ed":"140002","congress":"Congress CD 16","stateSenate":"State Senate SD 34","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 11","townCity":"Pelham","councilDistrict":"","village":"Pelham Village"},
    {"ed":"140003","congress":"Congress CD 16","stateSenate":"State Senate SD 34","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 11","townCity":"Pelham","councilDistrict":"","village":"Pelham Village"},
    {"ed":"140004","congress":"Congress CD 16","stateSenate":"State Senate SD 34","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 11","townCity":"Pelham","councilDistrict":"","village":"Pelham Village"},
    {"ed":"140005","congress":"Congress CD 16","stateSenate":"State Senate SD 34","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 11","townCity":"Pelham","councilDistrict":"","village":"Pelham Village"},
    {"ed":"140006","congress":"Congress CD 16","stateSenate":"State Senate SD 34","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 11","townCity":"Pelham","councilDistrict":"","village":"Pelham Manor"},
    {"ed":"140007","congress":"Congress CD 16","stateSenate":"State Senate SD 34","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 11","townCity":"Pelham","councilDistrict":"","village":"Pelham Manor"},
    {"ed":"140008","congress":"Congress CD 16","stateSenate":"State Senate SD 34","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 11","townCity":"Pelham","councilDistrict":"","village":"Pelham Manor"},
    {"ed":"140009","congress":"Congress CD 16","stateSenate":"State Senate SD 34","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 11","townCity":"Pelham","councilDistrict":"","village":"Pelham Manor"},
    {"ed":"140010","congress":"Congress CD 16","stateSenate":"State Senate SD 34","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 11","townCity":"Pelham","councilDistrict":"","village":"Pelham Manor"},
    {"ed":"150001","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"Pound Ridge","councilDistrict":"","village":""},
    {"ed":"150002","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"Pound Ridge","councilDistrict":"","village":""},
    {"ed":"150003","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"Pound Ridge","councilDistrict":"","village":""},
    {"ed":"150004","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 2","townCity":"Pound Ridge","councilDistrict":"","village":""},
    {"ed":"160001","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Rye Town","councilDistrict":"","village":"Mamaroneck Village"},
    {"ed":"160002","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Rye Town","councilDistrict":"","village":"Mamaroneck Village"},
    {"ed":"160003","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Rye Town","councilDistrict":"","village":"Mamaroneck Village"},
    {"ed":"160004","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Rye Town","councilDistrict":"","village":"Mamaroneck Village"},
    {"ed":"160005","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 6","townCity":"Rye Town","councilDistrict":"","village":"Port Chester"},
    {"ed":"160006","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 6","townCity":"Rye Town","councilDistrict":"","village":"Port Chester"},
    {"ed":"160007","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 6","townCity":"Rye Town","councilDistrict":"","village":"Port Chester"},
    {"ed":"160008","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 6","townCity":"Rye Town","councilDistrict":"","village":"Port Chester"},
    {"ed":"160009","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 6","townCity":"Rye Town","councilDistrict":"","village":"Port Chester"},
    {"ed":"160010","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 6","townCity":"Rye Town","councilDistrict":"","village":"Port Chester"},
    {"ed":"160011","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 6","townCity":"Rye Town","councilDistrict":"","village":"Port Chester"},
    {"ed":"160012","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 6","townCity":"Rye Town","councilDistrict":"","village":"Port Chester"},
    {"ed":"160013","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 6","townCity":"Rye Town","councilDistrict":"","village":"Port Chester"},
    {"ed":"160014","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 6","townCity":"Rye Town","councilDistrict":"","village":"Port Chester"},
    {"ed":"160015","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 6","townCity":"Rye Town","councilDistrict":"","village":"Port Chester"},
    {"ed":"160016","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 6","townCity":"Rye Town","councilDistrict":"","village":"Port Chester"},
    {"ed":"160017","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 6","townCity":"Rye Town","councilDistrict":"","village":"Port Chester"},
    {"ed":"160018","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 6","townCity":"Rye Town","councilDistrict":"","village":"Port Chester"},
    {"ed":"160019","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 6","townCity":"Rye Town","councilDistrict":"","village":"Port Chester"},
    {"ed":"160020","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 6","townCity":"Rye Town","councilDistrict":"","village":"Rye Brook"},
    {"ed":"160021","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 6","townCity":"Rye Town","councilDistrict":"","village":"Rye Brook"},
    {"ed":"160022","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 6","townCity":"Rye Town","councilDistrict":"","village":"Rye Brook"},
    {"ed":"160023","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 6","townCity":"Rye Town","councilDistrict":"","village":"Rye Brook"},
    {"ed":"160024","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Rye Town","councilDistrict":"","village":"Mamaroneck Village"},
    {"ed":"160025","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 6","townCity":"Rye Town","councilDistrict":"","village":"Port Chester"},
    {"ed":"160026","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 6","townCity":"Rye Town","councilDistrict":"","village":"Rye Brook"},
    {"ed":"160027","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 6","townCity":"Rye Town","councilDistrict":"","village":"Rye Brook"},
    {"ed":"160028","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Rye Town","councilDistrict":"","village":"Mamaroneck Village"},
    {"ed":"160029","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 6","townCity":"Rye Town","councilDistrict":"","village":"Rye Brook"},
    {"ed":"160030","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 6","townCity":"Rye Town","councilDistrict":"","village":"Rye Brook"},
    {"ed":"160031","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 6","townCity":"Rye Town","councilDistrict":"","village":"Rye Brook"},
    {"ed":"170001","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 5","townCity":"Scarsdale","councilDistrict":"","village":""},
    {"ed":"170002","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 5","townCity":"Scarsdale","councilDistrict":"","village":""},
    {"ed":"170003","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 5","townCity":"Scarsdale","councilDistrict":"","village":""},
    {"ed":"170004","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 5","townCity":"Scarsdale","councilDistrict":"","village":""},
    {"ed":"170005","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 5","townCity":"Scarsdale","councilDistrict":"","village":""},
    {"ed":"170006","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 5","townCity":"Scarsdale","councilDistrict":"","village":""},
    {"ed":"170007","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 5","townCity":"Scarsdale","councilDistrict":"","village":""},
    {"ed":"170008","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 5","townCity":"Scarsdale","councilDistrict":"","village":""},
    {"ed":"170009","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 5","townCity":"Scarsdale","councilDistrict":"","village":""},
    {"ed":"170010","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 5","townCity":"Scarsdale","councilDistrict":"","village":""},
    {"ed":"170011","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 5","townCity":"Scarsdale","councilDistrict":"","village":""},
    {"ed":"170012","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 5","townCity":"Scarsdale","councilDistrict":"","village":""},
    {"ed":"170013","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 5","townCity":"Scarsdale","councilDistrict":"","village":""},
    {"ed":"170014","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 5","townCity":"Scarsdale","councilDistrict":"","village":""},
    {"ed":"170015","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 5","townCity":"Scarsdale","councilDistrict":"","village":""},
    {"ed":"170016","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 5","townCity":"Scarsdale","councilDistrict":"","village":""},
    {"ed":"170017","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 5","townCity":"Scarsdale","councilDistrict":"","village":""},
    {"ed":"170018","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 5","townCity":"Scarsdale","councilDistrict":"","village":""},
    {"ed":"170019","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 5","townCity":"Scarsdale","councilDistrict":"","village":""},
    {"ed":"180001","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 2","townCity":"Somers","councilDistrict":"","village":""},
    {"ed":"180002","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 2","townCity":"Somers","councilDistrict":"","village":""},
    {"ed":"180003","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 4","townCity":"Somers","councilDistrict":"","village":""},
    {"ed":"180004","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 4","townCity":"Somers","councilDistrict":"","village":""},
    {"ed":"180005","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 4","townCity":"Somers","councilDistrict":"","village":""},
    {"ed":"180006","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 4","townCity":"Somers","councilDistrict":"","village":""},
    {"ed":"180007","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 4","townCity":"Somers","councilDistrict":"","village":""},
    {"ed":"180008","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 4","townCity":"Somers","councilDistrict":"","village":""},
    {"ed":"180009","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 4","townCity":"Somers","councilDistrict":"","village":""},
    {"ed":"180010","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 4","townCity":"Somers","councilDistrict":"","village":""},
    {"ed":"180011","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 4","townCity":"Somers","councilDistrict":"","village":""},
    {"ed":"180012","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 4","townCity":"Somers","councilDistrict":"","village":""},
    {"ed":"180013","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 4","townCity":"Somers","councilDistrict":"","village":""},
    {"ed":"180014","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 4","townCity":"Somers","councilDistrict":"","village":""},
    {"ed":"180015","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 4","townCity":"Somers","councilDistrict":"","village":""},
    {"ed":"180016","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 2","townCity":"Somers","councilDistrict":"","village":""},
    {"ed":"180017","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 4","townCity":"Somers","councilDistrict":"","village":""},
    {"ed":"180018","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 4","townCity":"Somers","councilDistrict":"","village":""},
    {"ed":"190001","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 1","townCity":"Yorktown","councilDistrict":"","village":""},
    {"ed":"190002","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 4","townCity":"Yorktown","councilDistrict":"","village":""},
    {"ed":"190003","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 4","townCity":"Yorktown","councilDistrict":"","village":""},
    {"ed":"190004","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 4","townCity":"Yorktown","councilDistrict":"","village":""},
    {"ed":"190005","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 1","townCity":"Yorktown","councilDistrict":"","village":""},
    {"ed":"190006","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 4","townCity":"Yorktown","councilDistrict":"","village":""},
    {"ed":"190007","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 4","townCity":"Yorktown","councilDistrict":"","village":""},
    {"ed":"190008","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 4","townCity":"Yorktown","councilDistrict":"","village":""},
    {"ed":"190009","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 1","townCity":"Yorktown","councilDistrict":"","village":""},
    {"ed":"190010","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 1","townCity":"Yorktown","councilDistrict":"","village":""},
    {"ed":"190011","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 4","townCity":"Yorktown","councilDistrict":"","village":""},
    {"ed":"190012","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 4","townCity":"Yorktown","councilDistrict":"","village":""},
    {"ed":"190013","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 1","townCity":"Yorktown","councilDistrict":"","village":""},
    {"ed":"190014","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 1","townCity":"Yorktown","councilDistrict":"","village":""},
    {"ed":"190015","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 4","townCity":"Yorktown","councilDistrict":"","village":""},
    {"ed":"190016","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 4","townCity":"Yorktown","councilDistrict":"","village":""},
    {"ed":"190017","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 4","townCity":"Yorktown","councilDistrict":"","village":""},
    {"ed":"190018","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 4","townCity":"Yorktown","councilDistrict":"","village":""},
    {"ed":"190019","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 1","townCity":"Yorktown","councilDistrict":"","village":""},
    {"ed":"190020","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 4","townCity":"Yorktown","councilDistrict":"","village":""},
    {"ed":"190021","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 4","townCity":"Yorktown","councilDistrict":"","village":""},
    {"ed":"190022","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 1","townCity":"Yorktown","councilDistrict":"","village":""},
    {"ed":"190023","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 1","townCity":"Yorktown","councilDistrict":"","village":""},
    {"ed":"190024","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 4","townCity":"Yorktown","councilDistrict":"","village":""},
    {"ed":"190025","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 4","townCity":"Yorktown","councilDistrict":"","village":""},
    {"ed":"190026","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 4","townCity":"Yorktown","councilDistrict":"","village":""},
    {"ed":"190027","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 1","townCity":"Yorktown","councilDistrict":"","village":""},
    {"ed":"190028","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 1","townCity":"Yorktown","councilDistrict":"","village":""},
    {"ed":"190029","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 4","townCity":"Yorktown","councilDistrict":"","village":""},
    {"ed":"190030","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 4","townCity":"Yorktown","councilDistrict":"","village":""},
    {"ed":"190031","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 4","townCity":"Yorktown","councilDistrict":"","village":""},
    {"ed":"190032","congress":"Congress CD 18","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 4","townCity":"Yorktown","councilDistrict":"","village":""},
    {"ed":"190033","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 4","townCity":"Yorktown","councilDistrict":"","village":""},
    {"ed":"190034","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 4","townCity":"Yorktown","councilDistrict":"","village":""},
    {"ed":"190035","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 4","townCity":"Yorktown","councilDistrict":"","village":""},
    {"ed":"190036","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 1","townCity":"Yorktown","councilDistrict":"","village":""},
    {"ed":"190037","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 4","townCity":"Yorktown","councilDistrict":"","village":""},
    {"ed":"190038","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 1","townCity":"Yorktown","councilDistrict":"","village":""},
    {"ed":"190039","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 1","townCity":"Yorktown","councilDistrict":"","village":""},
    {"ed":"190040","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 1","townCity":"Yorktown","councilDistrict":"","village":""},
    {"ed":"190041","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 1","townCity":"Yorktown","councilDistrict":"","village":""},
    {"ed":"190042","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 1","townCity":"Yorktown","councilDistrict":"","village":""},
    {"ed":"190043","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 94","countyLegislator":"County Legislator LD 4","townCity":"Yorktown","councilDistrict":"","village":""},
    {"ed":"200001","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200002","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200003","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200004","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200005","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200006","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200007","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200008","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200009","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200010","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200011","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200012","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200013","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200014","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200015","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200016","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200017","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200018","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200019","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200020","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200021","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 14","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200022","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 14","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200023","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200024","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200025","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200026","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 14","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200027","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 14","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200028","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 14","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200029","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200030","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200031","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200032","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200033","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200034","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 14","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200035","congress":"Congress CD 16","stateSenate":"State Senate SD 34","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 14","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200036","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200037","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200038","congress":"Congress CD 16","stateSenate":"State Senate SD 34","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 14","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200039","congress":"Congress CD 16","stateSenate":"State Senate SD 34","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 14","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200040","congress":"Congress CD 16","stateSenate":"State Senate SD 34","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 14","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200041","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200042","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200043","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200044","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200045","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200046","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 14","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200047","congress":"Congress CD 16","stateSenate":"State Senate SD 34","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 14","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200048","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200049","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 14","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200050","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 14","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200051","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200052","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200053","congress":"Congress CD 16","stateSenate":"State Senate SD 34","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200054","congress":"Congress CD 16","stateSenate":"State Senate SD 34","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200055","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200056","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200057","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200058","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200059","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200060","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200061","congress":"Congress CD 16","stateSenate":"State Senate SD 34","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200062","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200064","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200065","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200066","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200067","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200068","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200069","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200070","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200071","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200072","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200073","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200074","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"200076","congress":"Congress CD 16","stateSenate":"State Senate SD 36","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 13","townCity":"Mount Vernon","councilDistrict":"","village":""},
    {"ed":"210001","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 11","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 2","village":""},
    {"ed":"210002","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 10","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 3","village":""},
    {"ed":"210003","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 11","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 2","village":""},
    {"ed":"210004","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 11","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 2","village":""},
    {"ed":"210005","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 11","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 3","village":""},
    {"ed":"210006","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 11","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 4","village":""},
    {"ed":"210007","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 11","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 6","village":""},
    {"ed":"210008","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 11","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 2","village":""},
    {"ed":"210009","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 11","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 4","village":""},
    {"ed":"210010","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 3","village":""},
    {"ed":"210011","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 7","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 5","village":""},
    {"ed":"210012","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 5","village":""},
    {"ed":"210013","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 5","village":""},
    {"ed":"210014","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 5","village":""},
    {"ed":"210015","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 3","village":""},
    {"ed":"210016","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 7","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 5","village":""},
    {"ed":"210018","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 11","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 2","village":""},
    {"ed":"210019","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 11","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 4","village":""},
    {"ed":"210021","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 7","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 5","village":""},
    {"ed":"210022","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 11","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 2","village":""},
    {"ed":"210023","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 11","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 1","village":""},
    {"ed":"210024","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 11","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 2","village":""},
    {"ed":"210025","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 11","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 1","village":""},
    {"ed":"210026","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 11","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 4","village":""},
    {"ed":"210027","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 5","village":""},
    {"ed":"210028","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 11","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 2","village":""},
    {"ed":"210029","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 11","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 4","village":""},
    {"ed":"210030","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 6","village":""},
    {"ed":"210031","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 6","village":""},
    {"ed":"210032","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 6","village":""},
    {"ed":"210033","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 6","village":""},
    {"ed":"210034","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 11","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 6","village":""},
    {"ed":"210035","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 5","village":""},
    {"ed":"210036","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 11","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 6","village":""},
    {"ed":"210037","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 11","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 6","village":""},
    {"ed":"210038","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 7","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 5","village":""},
    {"ed":"210039","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 6","village":""},
    {"ed":"210040","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 6","village":""},
    {"ed":"210041","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 5","village":""},
    {"ed":"210042","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 5","village":""},
    {"ed":"210043","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 6","village":""},
    {"ed":"210044","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 5","village":""},
    {"ed":"210045","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 6","village":""},
    {"ed":"210046","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 6","village":""},
    {"ed":"210047","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 6","village":""},
    {"ed":"210048","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 11","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 6","village":""},
    {"ed":"210049","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 3","village":""},
    {"ed":"210050","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 10","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 4","village":""},
    {"ed":"210051","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 10","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 1","village":""},
    {"ed":"210052","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 11","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 1","village":""},
    {"ed":"210053","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 3","village":""},
    {"ed":"210054","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 11","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 1","village":""},
    {"ed":"210055","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 11","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 1","village":""},
    {"ed":"210056","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 11","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 4","village":""},
    {"ed":"210057","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 4","village":""},
    {"ed":"210058","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 3","village":""},
    {"ed":"210059","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 3","village":""},
    {"ed":"210060","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 3","village":""},
    {"ed":"210061","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 11","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 5","village":""},
    {"ed":"210063","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 4","village":""},
    {"ed":"210064","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 10","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 3","village":""},
    {"ed":"210065","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 11","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 2","village":""},
    {"ed":"210066","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 3","village":""},
    {"ed":"210067","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 6","village":""},
    {"ed":"210068","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 11","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 5","village":""},
    {"ed":"210069","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 11","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 2","village":""},
    {"ed":"210070","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 11","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 5","village":""},
    {"ed":"210071","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 11","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 2","village":""},
    {"ed":"210072","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 11","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 5","village":""},
    {"ed":"210073","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 11","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 2","village":""},
    {"ed":"210074","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 11","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 1","village":""},
    {"ed":"210075","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 11","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 4","village":""},
    {"ed":"210076","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 10","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 2","village":""},
    {"ed":"210077","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 11","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 2","village":""},
    {"ed":"210078","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 11","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 3","village":""},
    {"ed":"210079","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 11","townCity":"New Rochelle","councilDistrict":"New Rochelle City Council 4","village":""},
    {"ed":"220001","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 1","townCity":"Peekskill","councilDistrict":"","village":""},
    {"ed":"220002","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 1","townCity":"Peekskill","councilDistrict":"","village":""},
    {"ed":"220003","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 1","townCity":"Peekskill","councilDistrict":"","village":""},
    {"ed":"220004","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 1","townCity":"Peekskill","councilDistrict":"","village":""},
    {"ed":"220005","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 1","townCity":"Peekskill","councilDistrict":"","village":""},
    {"ed":"220006","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 1","townCity":"Peekskill","councilDistrict":"","village":""},
    {"ed":"220007","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 1","townCity":"Peekskill","councilDistrict":"","village":""},
    {"ed":"220008","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 1","townCity":"Peekskill","councilDistrict":"","village":""},
    {"ed":"220009","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 1","townCity":"Peekskill","councilDistrict":"","village":""},
    {"ed":"220010","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 1","townCity":"Peekskill","councilDistrict":"","village":""},
    {"ed":"220011","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 1","townCity":"Peekskill","councilDistrict":"","village":""},
    {"ed":"220013","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Peekskill","councilDistrict":"","village":""},
    {"ed":"220014","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 1","townCity":"Peekskill","councilDistrict":"","village":""},
    {"ed":"220015","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 1","townCity":"Peekskill","councilDistrict":"","village":""},
    {"ed":"220016","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 1","townCity":"Peekskill","councilDistrict":"","village":""},
    {"ed":"220017","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 1","townCity":"Peekskill","councilDistrict":"","village":""},
    {"ed":"220018","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 1","townCity":"Peekskill","councilDistrict":"","village":""},
    {"ed":"220019","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 1","townCity":"Peekskill","councilDistrict":"","village":""},
    {"ed":"220020","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 9","townCity":"Peekskill","councilDistrict":"","village":""},
    {"ed":"220021","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 1","townCity":"Peekskill","councilDistrict":"","village":""},
    {"ed":"220022","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 1","townCity":"Peekskill","councilDistrict":"","village":""},
    {"ed":"220023","congress":"Congress CD 17","stateSenate":"State Senate SD 40","stateAssembly":"State Assembly AD 95","countyLegislator":"County Legislator LD 1","townCity":"Peekskill","councilDistrict":"","village":""},
    {"ed":"230001","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Rye City","councilDistrict":"","village":""},
    {"ed":"230002","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Rye City","councilDistrict":"","village":""},
    {"ed":"230003","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Rye City","councilDistrict":"","village":""},
    {"ed":"230004","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Rye City","councilDistrict":"","village":""},
    {"ed":"230005","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Rye City","councilDistrict":"","village":""},
    {"ed":"230006","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Rye City","councilDistrict":"","village":""},
    {"ed":"230007","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Rye City","councilDistrict":"","village":""},
    {"ed":"230008","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Rye City","councilDistrict":"","village":""},
    {"ed":"230009","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Rye City","councilDistrict":"","village":""},
    {"ed":"230010","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Rye City","councilDistrict":"","village":""},
    {"ed":"230011","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Rye City","councilDistrict":"","village":""},
    {"ed":"230012","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Rye City","councilDistrict":"","village":""},
    {"ed":"230013","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Rye City","councilDistrict":"","village":""},
    {"ed":"230014","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 91","countyLegislator":"County Legislator LD 7","townCity":"Rye City","councilDistrict":"","village":""},
    {"ed":"240001","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 5","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240002","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 5","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240003","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 5","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240004","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 5","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240005","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 5","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240006","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 5","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240007","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 5","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240008","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 5","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240009","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 5","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240010","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 5","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240011","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 5","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240012","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 5","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240013","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 5","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240014","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 5","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240015","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 5","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240016","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 8","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240017","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 5","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240018","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 5","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240019","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 5","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240020","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 5","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240021","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 5","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240022","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 5","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240023","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 5","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240024","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 5","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240025","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 5","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240026","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 5","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240027","congress":"Congress CD 17","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 5","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240028","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 8","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240029","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 8","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240030","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 5","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240031","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 8","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240032","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 8","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240033","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 8","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240034","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 8","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240035","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 5","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240036","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 5","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240037","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 5","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240038","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 8","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240039","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 8","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240040","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 8","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240041","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 8","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240042","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 5","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240043","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 5","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240044","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 8","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240045","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 88","countyLegislator":"County Legislator LD 5","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"240046","congress":"Congress CD 17","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 93","countyLegislator":"County Legislator LD 8","townCity":"White Plains","councilDistrict":"","village":""},
    {"ed":"250101","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 1","village":""},
    {"ed":"250102","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 1","village":""},
    {"ed":"250103","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250104","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 2","village":""},
    {"ed":"250105","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 1","village":""},
    {"ed":"250106","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 1","village":""},
    {"ed":"250107","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 1","village":""},
    {"ed":"250108","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 1","village":""},
    {"ed":"250109","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 1","village":""},
    {"ed":"250110","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 1","village":""},
    {"ed":"250111","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 1","village":""},
    {"ed":"250112","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 1","village":""},
    {"ed":"250118","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 1","village":""},
    {"ed":"250122","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 1","village":""},
    {"ed":"250126","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250201","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 4","village":""},
    {"ed":"250202","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 4","village":""},
    {"ed":"250203","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 4","village":""},
    {"ed":"250204","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"250205","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 4","village":""},
    {"ed":"250206","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 4","village":""},
    {"ed":"250207","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250208","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 4","village":""},
    {"ed":"250209","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 4","village":""},
    {"ed":"250210","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 4","village":""},
    {"ed":"250211","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 4","village":""},
    {"ed":"250212","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"250213","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 5","village":""},
    {"ed":"250214","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250216","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"250218","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 2","village":""},
    {"ed":"250220","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 4","village":""},
    {"ed":"250222","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 4","village":""},
    {"ed":"250223","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 4","village":""},
    {"ed":"250224","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 4","village":""},
    {"ed":"250226","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250229","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250301","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250302","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250303","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 1","village":""},
    {"ed":"250304","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 1","village":""},
    {"ed":"250305","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250306","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250307","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 1","village":""},
    {"ed":"250308","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 1","village":""},
    {"ed":"250310","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250311","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250312","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 1","village":""},
    {"ed":"250313","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 1","village":""},
    {"ed":"250315","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250316","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 1","village":""},
    {"ed":"250317","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 1","village":""},
    {"ed":"250319","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"250320","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250321","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250323","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 1","village":""},
    {"ed":"250324","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 1","village":""},
    {"ed":"250325","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250326","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250327","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 1","village":""},
    {"ed":"250328","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 1","village":""},
    {"ed":"250329","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250401","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 1","village":""},
    {"ed":"250402","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 2","village":""},
    {"ed":"250403","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250404","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 2","village":""},
    {"ed":"250405","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 2","village":""},
    {"ed":"250406","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 2","village":""},
    {"ed":"250407","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250408","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 2","village":""},
    {"ed":"250409","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 2","village":""},
    {"ed":"250410","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250411","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 2","village":""},
    {"ed":"250412","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250415","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 2","village":""},
    {"ed":"250416","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 2","village":""},
    {"ed":"250417","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 2","village":""},
    {"ed":"250418","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 2","village":""},
    {"ed":"250419","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 2","village":""},
    {"ed":"250421","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250501","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 5","village":""},
    {"ed":"250502","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"250503","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"250504","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"250505","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 5","village":""},
    {"ed":"250506","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 5","village":""},
    {"ed":"250507","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"250508","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 5","village":""},
    {"ed":"250509","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 5","village":""},
    {"ed":"250510","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"250511","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"250512","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 5","village":""},
    {"ed":"250513","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 5","village":""},
    {"ed":"250514","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 5","village":""},
    {"ed":"250515","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 5","village":""},
    {"ed":"250516","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"250517","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"250518","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"250519","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"250520","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"250522","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"250523","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"250524","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"250601","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 1","village":""},
    {"ed":"250602","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250603","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250604","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250606","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250607","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"250608","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"250610","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250611","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250612","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250613","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 1","village":""},
    {"ed":"250614","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250616","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250617","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250618","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"250620","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 1","village":""},
    {"ed":"250621","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 1","village":""},
    {"ed":"250622","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 1","village":""},
    {"ed":"250624","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250628","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 1","village":""},
    {"ed":"250632","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250633","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250701","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250702","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 2","village":""},
    {"ed":"250703","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 1","village":""},
    {"ed":"250704","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"250705","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 2","village":""},
    {"ed":"250706","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 2","village":""},
    {"ed":"250707","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 2","village":""},
    {"ed":"250708","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 2","village":""},
    {"ed":"250709","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 2","village":""},
    {"ed":"250711","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 2","village":""},
    {"ed":"250713","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 2","village":""},
    {"ed":"250714","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"250715","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"250716","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"250717","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 2","village":""},
    {"ed":"250719","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"250720","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"250721","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250723","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 2","village":""},
    {"ed":"250724","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 1","village":""},
    {"ed":"250725","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 2","village":""},
    {"ed":"250726","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 2","village":""},
    {"ed":"250729","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 2","village":""},
    {"ed":"250730","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"250731","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"250801","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250802","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250803","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250805","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250806","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 4","village":""},
    {"ed":"250807","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250809","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250810","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 2","village":""},
    {"ed":"250811","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250812","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 2","village":""},
    {"ed":"250813","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250814","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 2","village":""},
    {"ed":"250815","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250817","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 2","village":""},
    {"ed":"250822","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250823","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"250824","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 2","village":""},
    {"ed":"250826","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 2","village":""},
    {"ed":"250828","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 17","townCity":"Yonkers","councilDistrict":"Yonkers City Council 1","village":""},
    {"ed":"250901","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 4","village":""},
    {"ed":"250902","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 4","village":""},
    {"ed":"250903","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 4","village":""},
    {"ed":"250904","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 4","village":""},
    {"ed":"250905","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 4","village":""},
    {"ed":"250906","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 4","village":""},
    {"ed":"250907","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 4","village":""},
    {"ed":"250908","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 4","village":""},
    {"ed":"250909","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 4","village":""},
    {"ed":"250910","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 4","village":""},
    {"ed":"250911","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 4","village":""},
    {"ed":"250912","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 4","village":""},
    {"ed":"250913","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 4","village":""},
    {"ed":"250915","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 4","village":""},
    {"ed":"250916","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 4","village":""},
    {"ed":"250917","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 4","village":""},
    {"ed":"251001","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 5","village":""},
    {"ed":"251002","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"251003","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"251004","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"251005","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"251006","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"251007","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"251008","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"251009","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"251011","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"251012","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 5","village":""},
    {"ed":"251013","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"251014","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"251015","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 5","village":""},
    {"ed":"251016","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"251017","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 3","village":""},
    {"ed":"251018","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"251020","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"251021","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"251025","congress":"Congress CD 16","stateSenate":"State Senate SD 35","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"251101","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 5","village":""},
    {"ed":"251102","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 5","village":""},
    {"ed":"251103","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 5","village":""},
    {"ed":"251104","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 5","village":""},
    {"ed":"251105","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 5","village":""},
    {"ed":"251106","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"251107","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 5","village":""},
    {"ed":"251108","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 5","village":""},
    {"ed":"251109","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 6","village":""},
    {"ed":"251110","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 5","village":""},
    {"ed":"251111","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 5","village":""},
    {"ed":"251112","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 5","village":""},
    {"ed":"251113","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 5","village":""},
    {"ed":"251114","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 16","townCity":"Yonkers","councilDistrict":"Yonkers City Council 5","village":""},
    {"ed":"251116","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 5","village":""},
    {"ed":"251117","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 5","village":""},
    {"ed":"251118","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 5","village":""},
    {"ed":"251119","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 5","village":""},
    {"ed":"251120","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 5","village":""},
    {"ed":"251121","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 5","village":""},
    {"ed":"251201","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 89","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 4","village":""},
    {"ed":"251202","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 5","village":""},
    {"ed":"251203","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 4","village":""},
    {"ed":"251204","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 5","village":""},
    {"ed":"251205","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 4","village":""},
    {"ed":"251206","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 5","village":""},
    {"ed":"251207","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 5","village":""},
    {"ed":"251208","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 5","village":""},
    {"ed":"251209","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 5","village":""},
    {"ed":"251210","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 4","village":""},
    {"ed":"251211","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 4","village":""},
    {"ed":"251212","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 5","village":""},
    {"ed":"251213","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 15","townCity":"Yonkers","councilDistrict":"Yonkers City Council 5","village":""},
    {"ed":"251215","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 4","village":""},
    {"ed":"251216","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 4","village":""},
    {"ed":"251217","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 4","village":""},
    {"ed":"251218","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 5","village":""},
    {"ed":"251219","congress":"Congress CD 16","stateSenate":"State Senate SD 37","stateAssembly":"State Assembly AD 90","countyLegislator":"County Legislator LD 14","townCity":"Yonkers","councilDistrict":"Yonkers City Council 5","village":""}
    ]
    
    export default regionalSelect;