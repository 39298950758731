const regionPicked = [{"muniType":"Town/City","region":"Bedford","ed":"010001"},
{"muniType":"Town/City","region":"Bedford","ed":"010002"},
{"muniType":"Town/City","region":"Bedford","ed":"010003"},
{"muniType":"Town/City","region":"Bedford","ed":"010004"},
{"muniType":"Town/City","region":"Bedford","ed":"010005"},
{"muniType":"Town/City","region":"Bedford","ed":"010006"},
{"muniType":"Town/City","region":"Bedford","ed":"010007"},
{"muniType":"Town/City","region":"Bedford","ed":"010008"},
{"muniType":"Town/City","region":"Bedford","ed":"010009"},
{"muniType":"Town/City","region":"Bedford","ed":"010010"},
{"muniType":"Town/City","region":"Bedford","ed":"010011"},
{"muniType":"Town/City","region":"Bedford","ed":"010012"},
{"muniType":"Town/City","region":"Bedford","ed":"010013"},
{"muniType":"Town/City","region":"Bedford","ed":"010014"},
{"muniType":"Town/City","region":"Bedford","ed":"010015"},
{"muniType":"Town/City","region":"Bedford","ed":"010016"},
{"muniType":"Town/City","region":"Bedford","ed":"010017"},
{"muniType":"Town/City","region":"Bedford","ed":"010018"},
{"muniType":"Town/City","region":"Cortlandt","ed":"020001"},
{"muniType":"Town/City","region":"Cortlandt","ed":"020002"},
{"muniType":"Town/City","region":"Cortlandt","ed":"020003"},
{"muniType":"Town/City","region":"Cortlandt","ed":"020004"},
{"muniType":"Town/City","region":"Cortlandt","ed":"020005"},
{"muniType":"Town/City","region":"Cortlandt","ed":"020006"},
{"muniType":"Town/City","region":"Cortlandt","ed":"020007"},
{"muniType":"Town/City","region":"Cortlandt","ed":"020008"},
{"muniType":"Town/City","region":"Cortlandt","ed":"020009"},
{"muniType":"Town/City","region":"Cortlandt","ed":"020010"},
{"muniType":"Town/City","region":"Cortlandt","ed":"020011"},
{"muniType":"Town/City","region":"Cortlandt","ed":"020012"},
{"muniType":"Town/City","region":"Cortlandt","ed":"020013"},
{"muniType":"Town/City","region":"Cortlandt","ed":"020014"},
{"muniType":"Town/City","region":"Cortlandt","ed":"020015"},
{"muniType":"Town/City","region":"Cortlandt","ed":"020016"},
{"muniType":"Town/City","region":"Cortlandt","ed":"020017"},
{"muniType":"Town/City","region":"Cortlandt","ed":"020018"},
{"muniType":"Town/City","region":"Cortlandt","ed":"020019"},
{"muniType":"Town/City","region":"Cortlandt","ed":"020020"},
{"muniType":"Town/City","region":"Cortlandt","ed":"020021"},
{"muniType":"Town/City","region":"Cortlandt","ed":"020022"},
{"muniType":"Town/City","region":"Cortlandt","ed":"020023"},
{"muniType":"Town/City","region":"Cortlandt","ed":"020024"},
{"muniType":"Town/City","region":"Cortlandt","ed":"020025"},
{"muniType":"Town/City","region":"Cortlandt","ed":"020026"},
{"muniType":"Town/City","region":"Cortlandt","ed":"020027"},
{"muniType":"Town/City","region":"Cortlandt","ed":"020028"},
{"muniType":"Town/City","region":"Cortlandt","ed":"020029"},
{"muniType":"Town/City","region":"Cortlandt","ed":"020030"},
{"muniType":"Town/City","region":"Cortlandt","ed":"020031"},
{"muniType":"Town/City","region":"Cortlandt","ed":"020032"},
{"muniType":"Town/City","region":"Cortlandt","ed":"020033"},
{"muniType":"Town/City","region":"Cortlandt","ed":"020034"},
{"muniType":"Town/City","region":"Cortlandt","ed":"020035"},
{"muniType":"Town/City","region":"Cortlandt","ed":"020038"},
{"muniType":"Town/City","region":"Cortlandt","ed":"020039"},
{"muniType":"Town/City","region":"Eastchester","ed":"030001"},
{"muniType":"Town/City","region":"Eastchester","ed":"030002"},
{"muniType":"Town/City","region":"Eastchester","ed":"030003"},
{"muniType":"Town/City","region":"Eastchester","ed":"030004"},
{"muniType":"Town/City","region":"Eastchester","ed":"030005"},
{"muniType":"Town/City","region":"Eastchester","ed":"030006"},
{"muniType":"Town/City","region":"Eastchester","ed":"030007"},
{"muniType":"Town/City","region":"Eastchester","ed":"030008"},
{"muniType":"Town/City","region":"Eastchester","ed":"030009"},
{"muniType":"Town/City","region":"Eastchester","ed":"030010"},
{"muniType":"Town/City","region":"Eastchester","ed":"030011"},
{"muniType":"Town/City","region":"Eastchester","ed":"030012"},
{"muniType":"Town/City","region":"Eastchester","ed":"030013"},
{"muniType":"Town/City","region":"Eastchester","ed":"030014"},
{"muniType":"Town/City","region":"Eastchester","ed":"030015"},
{"muniType":"Town/City","region":"Eastchester","ed":"030016"},
{"muniType":"Town/City","region":"Eastchester","ed":"030017"},
{"muniType":"Town/City","region":"Eastchester","ed":"030018"},
{"muniType":"Town/City","region":"Eastchester","ed":"030019"},
{"muniType":"Town/City","region":"Eastchester","ed":"030020"},
{"muniType":"Town/City","region":"Eastchester","ed":"030021"},
{"muniType":"Town/City","region":"Eastchester","ed":"030022"},
{"muniType":"Town/City","region":"Eastchester","ed":"030023"},
{"muniType":"Town/City","region":"Eastchester","ed":"030024"},
{"muniType":"Town/City","region":"Eastchester","ed":"030025"},
{"muniType":"Town/City","region":"Eastchester","ed":"030026"},
{"muniType":"Town/City","region":"Eastchester","ed":"030027"},
{"muniType":"Town/City","region":"Eastchester","ed":"030028"},
{"muniType":"Town/City","region":"Eastchester","ed":"030029"},
{"muniType":"Town/City","region":"Eastchester","ed":"030030"},
{"muniType":"Town/City","region":"Eastchester","ed":"030031"},
{"muniType":"Town/City","region":"Eastchester","ed":"030032"},
{"muniType":"Town/City","region":"Eastchester","ed":"030033"},
{"muniType":"Town/City","region":"Eastchester","ed":"030034"},
{"muniType":"Town/City","region":"Eastchester","ed":"030035"},
{"muniType":"Town/City","region":"Eastchester","ed":"030036"},
{"muniType":"Town/City","region":"Eastchester","ed":"030037"},
{"muniType":"Town/City","region":"Eastchester","ed":"030038"},
{"muniType":"Town/City","region":"Eastchester","ed":"030039"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040001"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040002"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040003"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040004"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040005"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040006"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040007"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040008"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040009"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040010"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040011"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040012"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040013"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040014"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040015"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040016"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040017"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040018"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040019"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040020"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040021"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040022"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040023"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040024"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040025"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040026"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040027"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040028"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040029"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040030"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040031"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040032"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040033"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040034"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040035"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040036"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040037"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040038"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040039"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040040"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040041"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040042"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040043"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040044"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040045"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040046"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040047"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040048"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040049"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040050"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040051"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040052"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040053"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040054"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040055"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040056"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040057"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040058"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040059"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040060"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040061"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040062"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040063"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040064"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040065"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040066"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040067"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040068"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040069"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040070"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040071"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040072"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040073"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040074"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040075"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040076"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040077"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040078"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040079"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040080"},
{"muniType":"Town/City","region":"Greenburgh","ed":"040081"},
{"muniType":"Town/City","region":"Harrison","ed":"050001"},
{"muniType":"Town/City","region":"Harrison","ed":"050002"},
{"muniType":"Town/City","region":"Harrison","ed":"050003"},
{"muniType":"Town/City","region":"Harrison","ed":"050004"},
{"muniType":"Town/City","region":"Harrison","ed":"050005"},
{"muniType":"Town/City","region":"Harrison","ed":"050006"},
{"muniType":"Town/City","region":"Harrison","ed":"050007"},
{"muniType":"Town/City","region":"Harrison","ed":"050008"},
{"muniType":"Town/City","region":"Harrison","ed":"050009"},
{"muniType":"Town/City","region":"Harrison","ed":"050010"},
{"muniType":"Town/City","region":"Harrison","ed":"050011"},
{"muniType":"Town/City","region":"Harrison","ed":"050012"},
{"muniType":"Town/City","region":"Harrison","ed":"050013"},
{"muniType":"Town/City","region":"Harrison","ed":"050014"},
{"muniType":"Town/City","region":"Harrison","ed":"050015"},
{"muniType":"Town/City","region":"Harrison","ed":"050016"},
{"muniType":"Town/City","region":"Harrison","ed":"050017"},
{"muniType":"Town/City","region":"Harrison","ed":"050018"},
{"muniType":"Town/City","region":"Harrison","ed":"050019"},
{"muniType":"Town/City","region":"Harrison","ed":"050020"},
{"muniType":"Town/City","region":"Lewisboro","ed":"060001"},
{"muniType":"Town/City","region":"Lewisboro","ed":"060002"},
{"muniType":"Town/City","region":"Lewisboro","ed":"060003"},
{"muniType":"Town/City","region":"Lewisboro","ed":"060004"},
{"muniType":"Town/City","region":"Lewisboro","ed":"060005"},
{"muniType":"Town/City","region":"Lewisboro","ed":"060006"},
{"muniType":"Town/City","region":"Lewisboro","ed":"060007"},
{"muniType":"Town/City","region":"Lewisboro","ed":"060008"},
{"muniType":"Town/City","region":"Lewisboro","ed":"060009"},
{"muniType":"Town/City","region":"Lewisboro","ed":"060010"},
{"muniType":"Town/City","region":"Lewisboro","ed":"060011"},
{"muniType":"Town/City","region":"Lewisboro","ed":"060012"},
{"muniType":"Town/City","region":"Mamaroneck","ed":"070001"},
{"muniType":"Town/City","region":"Mamaroneck","ed":"070002"},
{"muniType":"Town/City","region":"Mamaroneck","ed":"070003"},
{"muniType":"Town/City","region":"Mamaroneck","ed":"070004"},
{"muniType":"Town/City","region":"Mamaroneck","ed":"070005"},
{"muniType":"Town/City","region":"Mamaroneck","ed":"070006"},
{"muniType":"Town/City","region":"Mamaroneck","ed":"070007"},
{"muniType":"Town/City","region":"Mamaroneck","ed":"070008"},
{"muniType":"Town/City","region":"Mamaroneck","ed":"070009"},
{"muniType":"Town/City","region":"Mamaroneck","ed":"070010"},
{"muniType":"Town/City","region":"Mamaroneck","ed":"070011"},
{"muniType":"Town/City","region":"Mamaroneck","ed":"070012"},
{"muniType":"Town/City","region":"Mamaroneck","ed":"070013"},
{"muniType":"Town/City","region":"Mamaroneck","ed":"070014"},
{"muniType":"Town/City","region":"Mamaroneck","ed":"070015"},
{"muniType":"Town/City","region":"Mamaroneck","ed":"070016"},
{"muniType":"Town/City","region":"Mamaroneck","ed":"070017"},
{"muniType":"Town/City","region":"Mamaroneck","ed":"070018"},
{"muniType":"Town/City","region":"Mamaroneck","ed":"070019"},
{"muniType":"Town/City","region":"Mamaroneck","ed":"070020"},
{"muniType":"Town/City","region":"Mamaroneck","ed":"070021"},
{"muniType":"Town/City","region":"Mamaroneck","ed":"070022"},
{"muniType":"Town/City","region":"Mamaroneck","ed":"070023"},
{"muniType":"Town/City","region":"Mamaroneck","ed":"070024"},
{"muniType":"Town/City","region":"Mamaroneck","ed":"070025"},
{"muniType":"Town/City","region":"Mamaroneck","ed":"070026"},
{"muniType":"Town/City","region":"Mamaroneck","ed":"070027"},
{"muniType":"Town/City","region":"Mamaroneck","ed":"070028"},
{"muniType":"Town/City","region":"Mount Kisco","ed":"080001"},
{"muniType":"Town/City","region":"Mount Kisco","ed":"080002"},
{"muniType":"Town/City","region":"Mount Kisco","ed":"080003"},
{"muniType":"Town/City","region":"Mount Kisco","ed":"080004"},
{"muniType":"Town/City","region":"Mount Kisco","ed":"080005"},
{"muniType":"Town/City","region":"Mount Kisco","ed":"080006"},
{"muniType":"Town/City","region":"Mount Kisco","ed":"080007"},
{"muniType":"Town/City","region":"Mount Pleasant","ed":"090001"},
{"muniType":"Town/City","region":"Mount Pleasant","ed":"090002"},
{"muniType":"Town/City","region":"Mount Pleasant","ed":"090003"},
{"muniType":"Town/City","region":"Mount Pleasant","ed":"090004"},
{"muniType":"Town/City","region":"Mount Pleasant","ed":"090005"},
{"muniType":"Town/City","region":"Mount Pleasant","ed":"090006"},
{"muniType":"Town/City","region":"Mount Pleasant","ed":"090007"},
{"muniType":"Town/City","region":"Mount Pleasant","ed":"090009"},
{"muniType":"Town/City","region":"Mount Pleasant","ed":"090010"},
{"muniType":"Town/City","region":"Mount Pleasant","ed":"090011"},
{"muniType":"Town/City","region":"Mount Pleasant","ed":"090012"},
{"muniType":"Town/City","region":"Mount Pleasant","ed":"090013"},
{"muniType":"Town/City","region":"Mount Pleasant","ed":"090014"},
{"muniType":"Town/City","region":"Mount Pleasant","ed":"090015"},
{"muniType":"Town/City","region":"Mount Pleasant","ed":"090016"},
{"muniType":"Town/City","region":"Mount Pleasant","ed":"090017"},
{"muniType":"Town/City","region":"Mount Pleasant","ed":"090018"},
{"muniType":"Town/City","region":"Mount Pleasant","ed":"090019"},
{"muniType":"Town/City","region":"Mount Pleasant","ed":"090020"},
{"muniType":"Town/City","region":"Mount Pleasant","ed":"090022"},
{"muniType":"Town/City","region":"Mount Pleasant","ed":"090023"},
{"muniType":"Town/City","region":"Mount Pleasant","ed":"090024"},
{"muniType":"Town/City","region":"Mount Pleasant","ed":"090025"},
{"muniType":"Town/City","region":"Mount Pleasant","ed":"090026"},
{"muniType":"Town/City","region":"Mount Pleasant","ed":"090027"},
{"muniType":"Town/City","region":"Mount Pleasant","ed":"090028"},
{"muniType":"Town/City","region":"Mount Pleasant","ed":"090029"},
{"muniType":"Town/City","region":"Mount Pleasant","ed":"090030"},
{"muniType":"Town/City","region":"Mount Pleasant","ed":"090031"},
{"muniType":"Town/City","region":"Mount Pleasant","ed":"090032"},
{"muniType":"Town/City","region":"Mount Pleasant","ed":"090033"},
{"muniType":"Town/City","region":"Mount Pleasant","ed":"090034"},
{"muniType":"Town/City","region":"Mount Pleasant","ed":"090035"},
{"muniType":"Town/City","region":"Mount Pleasant","ed":"090036"},
{"muniType":"Town/City","region":"Mount Pleasant","ed":"090037"},
{"muniType":"Town/City","region":"Mount Pleasant","ed":"090038"},
{"muniType":"Town/City","region":"Mount Pleasant","ed":"090039"},
{"muniType":"Town/City","region":"Mount Pleasant","ed":"090040"},
{"muniType":"Town/City","region":"Mount Pleasant","ed":"090041"},
{"muniType":"Town/City","region":"Mount Pleasant","ed":"090042"},
{"muniType":"Town/City","region":"Mount Pleasant","ed":"090043"},
{"muniType":"Town/City","region":"Mount Pleasant","ed":"090044"},
{"muniType":"Town/City","region":"New Castle","ed":"100001"},
{"muniType":"Town/City","region":"New Castle","ed":"100002"},
{"muniType":"Town/City","region":"New Castle","ed":"100003"},
{"muniType":"Town/City","region":"New Castle","ed":"100004"},
{"muniType":"Town/City","region":"New Castle","ed":"100005"},
{"muniType":"Town/City","region":"New Castle","ed":"100006"},
{"muniType":"Town/City","region":"New Castle","ed":"100007"},
{"muniType":"Town/City","region":"New Castle","ed":"100008"},
{"muniType":"Town/City","region":"New Castle","ed":"100009"},
{"muniType":"Town/City","region":"New Castle","ed":"100010"},
{"muniType":"Town/City","region":"New Castle","ed":"100011"},
{"muniType":"Town/City","region":"New Castle","ed":"100012"},
{"muniType":"Town/City","region":"New Castle","ed":"100013"},
{"muniType":"Town/City","region":"New Castle","ed":"100014"},
{"muniType":"Town/City","region":"New Castle","ed":"100015"},
{"muniType":"Town/City","region":"New Castle","ed":"100016"},
{"muniType":"Town/City","region":"North Castle","ed":"110001"},
{"muniType":"Town/City","region":"North Castle","ed":"110002"},
{"muniType":"Town/City","region":"North Castle","ed":"110003"},
{"muniType":"Town/City","region":"North Castle","ed":"110004"},
{"muniType":"Town/City","region":"North Castle","ed":"110005"},
{"muniType":"Town/City","region":"North Castle","ed":"110006"},
{"muniType":"Town/City","region":"North Castle","ed":"110007"},
{"muniType":"Town/City","region":"North Castle","ed":"110008"},
{"muniType":"Town/City","region":"North Castle","ed":"110009"},
{"muniType":"Town/City","region":"North Castle","ed":"110010"},
{"muniType":"Town/City","region":"North Castle","ed":"110011"},
{"muniType":"Town/City","region":"North Salem","ed":"120001"},
{"muniType":"Town/City","region":"North Salem","ed":"120002"},
{"muniType":"Town/City","region":"North Salem","ed":"120003"},
{"muniType":"Town/City","region":"North Salem","ed":"120004"},
{"muniType":"Town/City","region":"North Salem","ed":"120005"},
{"muniType":"Town/City","region":"North Salem","ed":"120006"},
{"muniType":"Town/City","region":"North Salem","ed":"120007"},
{"muniType":"Town/City","region":"North Salem","ed":"120008"},
{"muniType":"Town/City","region":"Ossining","ed":"130001"},
{"muniType":"Town/City","region":"Ossining","ed":"130002"},
{"muniType":"Town/City","region":"Ossining","ed":"130003"},
{"muniType":"Town/City","region":"Ossining","ed":"130004"},
{"muniType":"Town/City","region":"Ossining","ed":"130005"},
{"muniType":"Town/City","region":"Ossining","ed":"130006"},
{"muniType":"Town/City","region":"Ossining","ed":"130007"},
{"muniType":"Town/City","region":"Ossining","ed":"130008"},
{"muniType":"Town/City","region":"Ossining","ed":"130009"},
{"muniType":"Town/City","region":"Ossining","ed":"130010"},
{"muniType":"Town/City","region":"Ossining","ed":"130011"},
{"muniType":"Town/City","region":"Ossining","ed":"130012"},
{"muniType":"Town/City","region":"Ossining","ed":"130013"},
{"muniType":"Town/City","region":"Ossining","ed":"130014"},
{"muniType":"Town/City","region":"Ossining","ed":"130015"},
{"muniType":"Town/City","region":"Ossining","ed":"130016"},
{"muniType":"Town/City","region":"Ossining","ed":"130017"},
{"muniType":"Town/City","region":"Ossining","ed":"130018"},
{"muniType":"Town/City","region":"Ossining","ed":"130019"},
{"muniType":"Town/City","region":"Ossining","ed":"130020"},
{"muniType":"Town/City","region":"Ossining","ed":"130021"},
{"muniType":"Town/City","region":"Ossining","ed":"130022"},
{"muniType":"Town/City","region":"Ossining","ed":"130023"},
{"muniType":"Town/City","region":"Ossining","ed":"130024"},
{"muniType":"Town/City","region":"Ossining","ed":"130025"},
{"muniType":"Town/City","region":"Ossining","ed":"130026"},
{"muniType":"Town/City","region":"Ossining","ed":"130027"},
{"muniType":"Town/City","region":"Ossining","ed":"130028"},
{"muniType":"Town/City","region":"Ossining","ed":"130029"},
{"muniType":"Town/City","region":"Ossining","ed":"130030"},
{"muniType":"Town/City","region":"Pelham","ed":"140001"},
{"muniType":"Town/City","region":"Pelham","ed":"140002"},
{"muniType":"Town/City","region":"Pelham","ed":"140003"},
{"muniType":"Town/City","region":"Pelham","ed":"140004"},
{"muniType":"Town/City","region":"Pelham","ed":"140005"},
{"muniType":"Town/City","region":"Pelham","ed":"140006"},
{"muniType":"Town/City","region":"Pelham","ed":"140007"},
{"muniType":"Town/City","region":"Pelham","ed":"140008"},
{"muniType":"Town/City","region":"Pelham","ed":"140009"},
{"muniType":"Town/City","region":"Pelham","ed":"140010"},
{"muniType":"Town/City","region":"Pound Ridge","ed":"150001"},
{"muniType":"Town/City","region":"Pound Ridge","ed":"150002"},
{"muniType":"Town/City","region":"Pound Ridge","ed":"150003"},
{"muniType":"Town/City","region":"Pound Ridge","ed":"150004"},
{"muniType":"Town/City","region":"Rye Town","ed":"160001"},
{"muniType":"Town/City","region":"Rye Town","ed":"160002"},
{"muniType":"Town/City","region":"Rye Town","ed":"160003"},
{"muniType":"Town/City","region":"Rye Town","ed":"160004"},
{"muniType":"Town/City","region":"Rye Town","ed":"160005"},
{"muniType":"Town/City","region":"Rye Town","ed":"160006"},
{"muniType":"Town/City","region":"Rye Town","ed":"160007"},
{"muniType":"Town/City","region":"Rye Town","ed":"160008"},
{"muniType":"Town/City","region":"Rye Town","ed":"160009"},
{"muniType":"Town/City","region":"Rye Town","ed":"160010"},
{"muniType":"Town/City","region":"Rye Town","ed":"160011"},
{"muniType":"Town/City","region":"Rye Town","ed":"160012"},
{"muniType":"Town/City","region":"Rye Town","ed":"160013"},
{"muniType":"Town/City","region":"Rye Town","ed":"160014"},
{"muniType":"Town/City","region":"Rye Town","ed":"160015"},
{"muniType":"Town/City","region":"Rye Town","ed":"160016"},
{"muniType":"Town/City","region":"Rye Town","ed":"160017"},
{"muniType":"Town/City","region":"Rye Town","ed":"160018"},
{"muniType":"Town/City","region":"Rye Town","ed":"160019"},
{"muniType":"Town/City","region":"Rye Town","ed":"160020"},
{"muniType":"Town/City","region":"Rye Town","ed":"160021"},
{"muniType":"Town/City","region":"Rye Town","ed":"160022"},
{"muniType":"Town/City","region":"Rye Town","ed":"160023"},
{"muniType":"Town/City","region":"Rye Town","ed":"160024"},
{"muniType":"Town/City","region":"Rye Town","ed":"160025"},
{"muniType":"Town/City","region":"Rye Town","ed":"160026"},
{"muniType":"Town/City","region":"Rye Town","ed":"160027"},
{"muniType":"Town/City","region":"Rye Town","ed":"160028"},
{"muniType":"Town/City","region":"Rye Town","ed":"160029"},
{"muniType":"Town/City","region":"Rye Town","ed":"160030"},
{"muniType":"Town/City","region":"Rye Town","ed":"160031"},
{"muniType":"Town/City","region":"Scarsdale","ed":"170001"},
{"muniType":"Town/City","region":"Scarsdale","ed":"170002"},
{"muniType":"Town/City","region":"Scarsdale","ed":"170003"},
{"muniType":"Town/City","region":"Scarsdale","ed":"170004"},
{"muniType":"Town/City","region":"Scarsdale","ed":"170005"},
{"muniType":"Town/City","region":"Scarsdale","ed":"170006"},
{"muniType":"Town/City","region":"Scarsdale","ed":"170007"},
{"muniType":"Town/City","region":"Scarsdale","ed":"170008"},
{"muniType":"Town/City","region":"Scarsdale","ed":"170009"},
{"muniType":"Town/City","region":"Scarsdale","ed":"170010"},
{"muniType":"Town/City","region":"Scarsdale","ed":"170011"},
{"muniType":"Town/City","region":"Scarsdale","ed":"170012"},
{"muniType":"Town/City","region":"Scarsdale","ed":"170013"},
{"muniType":"Town/City","region":"Scarsdale","ed":"170014"},
{"muniType":"Town/City","region":"Scarsdale","ed":"170015"},
{"muniType":"Town/City","region":"Scarsdale","ed":"170016"},
{"muniType":"Town/City","region":"Scarsdale","ed":"170017"},
{"muniType":"Town/City","region":"Scarsdale","ed":"170018"},
{"muniType":"Town/City","region":"Scarsdale","ed":"170019"},
{"muniType":"Town/City","region":"Somers","ed":"180001"},
{"muniType":"Town/City","region":"Somers","ed":"180002"},
{"muniType":"Town/City","region":"Somers","ed":"180003"},
{"muniType":"Town/City","region":"Somers","ed":"180004"},
{"muniType":"Town/City","region":"Somers","ed":"180005"},
{"muniType":"Town/City","region":"Somers","ed":"180006"},
{"muniType":"Town/City","region":"Somers","ed":"180007"},
{"muniType":"Town/City","region":"Somers","ed":"180008"},
{"muniType":"Town/City","region":"Somers","ed":"180009"},
{"muniType":"Town/City","region":"Somers","ed":"180010"},
{"muniType":"Town/City","region":"Somers","ed":"180011"},
{"muniType":"Town/City","region":"Somers","ed":"180012"},
{"muniType":"Town/City","region":"Somers","ed":"180013"},
{"muniType":"Town/City","region":"Somers","ed":"180014"},
{"muniType":"Town/City","region":"Somers","ed":"180015"},
{"muniType":"Town/City","region":"Somers","ed":"180016"},
{"muniType":"Town/City","region":"Somers","ed":"180017"},
{"muniType":"Town/City","region":"Somers","ed":"180018"},
{"muniType":"Town/City","region":"Yorktown","ed":"190001"},
{"muniType":"Town/City","region":"Yorktown","ed":"190002"},
{"muniType":"Town/City","region":"Yorktown","ed":"190003"},
{"muniType":"Town/City","region":"Yorktown","ed":"190004"},
{"muniType":"Town/City","region":"Yorktown","ed":"190005"},
{"muniType":"Town/City","region":"Yorktown","ed":"190006"},
{"muniType":"Town/City","region":"Yorktown","ed":"190007"},
{"muniType":"Town/City","region":"Yorktown","ed":"190008"},
{"muniType":"Town/City","region":"Yorktown","ed":"190009"},
{"muniType":"Town/City","region":"Yorktown","ed":"190010"},
{"muniType":"Town/City","region":"Yorktown","ed":"190011"},
{"muniType":"Town/City","region":"Yorktown","ed":"190012"},
{"muniType":"Town/City","region":"Yorktown","ed":"190013"},
{"muniType":"Town/City","region":"Yorktown","ed":"190014"},
{"muniType":"Town/City","region":"Yorktown","ed":"190015"},
{"muniType":"Town/City","region":"Yorktown","ed":"190016"},
{"muniType":"Town/City","region":"Yorktown","ed":"190017"},
{"muniType":"Town/City","region":"Yorktown","ed":"190018"},
{"muniType":"Town/City","region":"Yorktown","ed":"190019"},
{"muniType":"Town/City","region":"Yorktown","ed":"190020"},
{"muniType":"Town/City","region":"Yorktown","ed":"190021"},
{"muniType":"Town/City","region":"Yorktown","ed":"190022"},
{"muniType":"Town/City","region":"Yorktown","ed":"190023"},
{"muniType":"Town/City","region":"Yorktown","ed":"190024"},
{"muniType":"Town/City","region":"Yorktown","ed":"190025"},
{"muniType":"Town/City","region":"Yorktown","ed":"190026"},
{"muniType":"Town/City","region":"Yorktown","ed":"190027"},
{"muniType":"Town/City","region":"Yorktown","ed":"190028"},
{"muniType":"Town/City","region":"Yorktown","ed":"190029"},
{"muniType":"Town/City","region":"Yorktown","ed":"190030"},
{"muniType":"Town/City","region":"Yorktown","ed":"190031"},
{"muniType":"Town/City","region":"Yorktown","ed":"190032"},
{"muniType":"Town/City","region":"Yorktown","ed":"190033"},
{"muniType":"Town/City","region":"Yorktown","ed":"190034"},
{"muniType":"Town/City","region":"Yorktown","ed":"190035"},
{"muniType":"Town/City","region":"Yorktown","ed":"190036"},
{"muniType":"Town/City","region":"Yorktown","ed":"190037"},
{"muniType":"Town/City","region":"Yorktown","ed":"190038"},
{"muniType":"Town/City","region":"Yorktown","ed":"190039"},
{"muniType":"Town/City","region":"Yorktown","ed":"190040"},
{"muniType":"Town/City","region":"Yorktown","ed":"190041"},
{"muniType":"Town/City","region":"Yorktown","ed":"190042"},
{"muniType":"Town/City","region":"Yorktown","ed":"190043"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200001"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200002"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200003"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200004"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200005"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200006"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200007"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200008"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200009"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200010"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200011"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200012"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200013"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200014"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200015"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200016"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200017"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200018"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200019"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200020"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200021"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200022"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200023"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200024"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200025"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200026"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200027"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200028"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200029"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200030"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200031"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200032"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200033"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200034"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200035"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200036"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200037"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200038"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200039"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200040"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200041"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200042"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200043"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200044"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200045"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200046"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200047"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200048"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200049"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200050"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200051"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200052"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200053"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200054"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200055"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200056"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200057"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200058"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200059"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200060"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200061"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200062"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200064"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200065"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200066"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200067"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200068"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200069"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200070"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200071"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200072"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200073"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200074"},
{"muniType":"Town/City","region":"Mount Vernon","ed":"200076"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210001"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210002"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210003"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210004"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210005"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210006"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210007"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210008"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210009"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210010"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210011"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210012"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210013"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210014"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210015"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210016"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210018"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210019"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210021"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210022"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210023"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210024"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210025"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210026"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210027"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210028"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210029"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210030"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210031"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210032"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210033"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210034"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210035"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210036"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210037"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210038"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210039"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210040"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210041"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210042"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210043"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210044"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210045"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210046"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210047"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210048"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210049"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210050"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210051"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210052"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210053"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210054"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210055"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210056"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210057"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210058"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210059"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210060"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210061"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210063"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210064"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210065"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210066"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210067"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210068"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210069"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210070"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210071"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210072"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210073"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210074"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210075"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210076"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210077"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210078"},
{"muniType":"Town/City","region":"New Rochelle","ed":"210079"},
{"muniType":"Town/City","region":"Peekskill","ed":"220001"},
{"muniType":"Town/City","region":"Peekskill","ed":"220002"},
{"muniType":"Town/City","region":"Peekskill","ed":"220003"},
{"muniType":"Town/City","region":"Peekskill","ed":"220004"},
{"muniType":"Town/City","region":"Peekskill","ed":"220005"},
{"muniType":"Town/City","region":"Peekskill","ed":"220006"},
{"muniType":"Town/City","region":"Peekskill","ed":"220007"},
{"muniType":"Town/City","region":"Peekskill","ed":"220008"},
{"muniType":"Town/City","region":"Peekskill","ed":"220009"},
{"muniType":"Town/City","region":"Peekskill","ed":"220010"},
{"muniType":"Town/City","region":"Peekskill","ed":"220011"},
{"muniType":"Town/City","region":"Peekskill","ed":"220013"},
{"muniType":"Town/City","region":"Peekskill","ed":"220014"},
{"muniType":"Town/City","region":"Peekskill","ed":"220015"},
{"muniType":"Town/City","region":"Peekskill","ed":"220016"},
{"muniType":"Town/City","region":"Peekskill","ed":"220017"},
{"muniType":"Town/City","region":"Peekskill","ed":"220018"},
{"muniType":"Town/City","region":"Peekskill","ed":"220019"},
{"muniType":"Town/City","region":"Peekskill","ed":"220020"},
{"muniType":"Town/City","region":"Peekskill","ed":"220021"},
{"muniType":"Town/City","region":"Peekskill","ed":"220022"},
{"muniType":"Town/City","region":"Peekskill","ed":"220023"},
{"muniType":"Town/City","region":"Rye City","ed":"230001"},
{"muniType":"Town/City","region":"Rye City","ed":"230002"},
{"muniType":"Town/City","region":"Rye City","ed":"230003"},
{"muniType":"Town/City","region":"Rye City","ed":"230004"},
{"muniType":"Town/City","region":"Rye City","ed":"230005"},
{"muniType":"Town/City","region":"Rye City","ed":"230006"},
{"muniType":"Town/City","region":"Rye City","ed":"230007"},
{"muniType":"Town/City","region":"Rye City","ed":"230008"},
{"muniType":"Town/City","region":"Rye City","ed":"230009"},
{"muniType":"Town/City","region":"Rye City","ed":"230010"},
{"muniType":"Town/City","region":"Rye City","ed":"230011"},
{"muniType":"Town/City","region":"Rye City","ed":"230012"},
{"muniType":"Town/City","region":"Rye City","ed":"230013"},
{"muniType":"Town/City","region":"Rye City","ed":"230014"},
{"muniType":"Town/City","region":"White Plains","ed":"240001"},
{"muniType":"Town/City","region":"White Plains","ed":"240002"},
{"muniType":"Town/City","region":"White Plains","ed":"240003"},
{"muniType":"Town/City","region":"White Plains","ed":"240004"},
{"muniType":"Town/City","region":"White Plains","ed":"240005"},
{"muniType":"Town/City","region":"White Plains","ed":"240006"},
{"muniType":"Town/City","region":"White Plains","ed":"240007"},
{"muniType":"Town/City","region":"White Plains","ed":"240008"},
{"muniType":"Town/City","region":"White Plains","ed":"240009"},
{"muniType":"Town/City","region":"White Plains","ed":"240010"},
{"muniType":"Town/City","region":"White Plains","ed":"240011"},
{"muniType":"Town/City","region":"White Plains","ed":"240012"},
{"muniType":"Town/City","region":"White Plains","ed":"240013"},
{"muniType":"Town/City","region":"White Plains","ed":"240014"},
{"muniType":"Town/City","region":"White Plains","ed":"240015"},
{"muniType":"Town/City","region":"White Plains","ed":"240016"},
{"muniType":"Town/City","region":"White Plains","ed":"240017"},
{"muniType":"Town/City","region":"White Plains","ed":"240018"},
{"muniType":"Town/City","region":"White Plains","ed":"240019"},
{"muniType":"Town/City","region":"White Plains","ed":"240020"},
{"muniType":"Town/City","region":"White Plains","ed":"240021"},
{"muniType":"Town/City","region":"White Plains","ed":"240022"},
{"muniType":"Town/City","region":"White Plains","ed":"240023"},
{"muniType":"Town/City","region":"White Plains","ed":"240024"},
{"muniType":"Town/City","region":"White Plains","ed":"240025"},
{"muniType":"Town/City","region":"White Plains","ed":"240026"},
{"muniType":"Town/City","region":"White Plains","ed":"240027"},
{"muniType":"Town/City","region":"White Plains","ed":"240028"},
{"muniType":"Town/City","region":"White Plains","ed":"240029"},
{"muniType":"Town/City","region":"White Plains","ed":"240030"},
{"muniType":"Town/City","region":"White Plains","ed":"240031"},
{"muniType":"Town/City","region":"White Plains","ed":"240032"},
{"muniType":"Town/City","region":"White Plains","ed":"240033"},
{"muniType":"Town/City","region":"White Plains","ed":"240034"},
{"muniType":"Town/City","region":"White Plains","ed":"240035"},
{"muniType":"Town/City","region":"White Plains","ed":"240036"},
{"muniType":"Town/City","region":"White Plains","ed":"240037"},
{"muniType":"Town/City","region":"White Plains","ed":"240038"},
{"muniType":"Town/City","region":"White Plains","ed":"240039"},
{"muniType":"Town/City","region":"White Plains","ed":"240040"},
{"muniType":"Town/City","region":"White Plains","ed":"240041"},
{"muniType":"Town/City","region":"White Plains","ed":"240042"},
{"muniType":"Town/City","region":"White Plains","ed":"240043"},
{"muniType":"Town/City","region":"White Plains","ed":"240044"},
{"muniType":"Town/City","region":"White Plains","ed":"240045"},
{"muniType":"Town/City","region":"White Plains","ed":"240046"},
{"muniType":"Town/City","region":"Yonkers","ed":"250101"},
{"muniType":"Town/City","region":"Yonkers","ed":"250102"},
{"muniType":"Town/City","region":"Yonkers","ed":"250103"},
{"muniType":"Town/City","region":"Yonkers","ed":"250104"},
{"muniType":"Town/City","region":"Yonkers","ed":"250105"},
{"muniType":"Town/City","region":"Yonkers","ed":"250106"},
{"muniType":"Town/City","region":"Yonkers","ed":"250107"},
{"muniType":"Town/City","region":"Yonkers","ed":"250108"},
{"muniType":"Town/City","region":"Yonkers","ed":"250109"},
{"muniType":"Town/City","region":"Yonkers","ed":"250110"},
{"muniType":"Town/City","region":"Yonkers","ed":"250111"},
{"muniType":"Town/City","region":"Yonkers","ed":"250112"},
{"muniType":"Town/City","region":"Yonkers","ed":"250118"},
{"muniType":"Town/City","region":"Yonkers","ed":"250122"},
{"muniType":"Town/City","region":"Yonkers","ed":"250126"},
{"muniType":"Town/City","region":"Yonkers","ed":"250201"},
{"muniType":"Town/City","region":"Yonkers","ed":"250202"},
{"muniType":"Town/City","region":"Yonkers","ed":"250203"},
{"muniType":"Town/City","region":"Yonkers","ed":"250204"},
{"muniType":"Town/City","region":"Yonkers","ed":"250205"},
{"muniType":"Town/City","region":"Yonkers","ed":"250206"},
{"muniType":"Town/City","region":"Yonkers","ed":"250207"},
{"muniType":"Town/City","region":"Yonkers","ed":"250208"},
{"muniType":"Town/City","region":"Yonkers","ed":"250209"},
{"muniType":"Town/City","region":"Yonkers","ed":"250210"},
{"muniType":"Town/City","region":"Yonkers","ed":"250211"},
{"muniType":"Town/City","region":"Yonkers","ed":"250212"},
{"muniType":"Town/City","region":"Yonkers","ed":"250213"},
{"muniType":"Town/City","region":"Yonkers","ed":"250214"},
{"muniType":"Town/City","region":"Yonkers","ed":"250216"},
{"muniType":"Town/City","region":"Yonkers","ed":"250218"},
{"muniType":"Town/City","region":"Yonkers","ed":"250220"},
{"muniType":"Town/City","region":"Yonkers","ed":"250222"},
{"muniType":"Town/City","region":"Yonkers","ed":"250223"},
{"muniType":"Town/City","region":"Yonkers","ed":"250224"},
{"muniType":"Town/City","region":"Yonkers","ed":"250226"},
{"muniType":"Town/City","region":"Yonkers","ed":"250229"},
{"muniType":"Town/City","region":"Yonkers","ed":"250301"},
{"muniType":"Town/City","region":"Yonkers","ed":"250302"},
{"muniType":"Town/City","region":"Yonkers","ed":"250303"},
{"muniType":"Town/City","region":"Yonkers","ed":"250304"},
{"muniType":"Town/City","region":"Yonkers","ed":"250305"},
{"muniType":"Town/City","region":"Yonkers","ed":"250306"},
{"muniType":"Town/City","region":"Yonkers","ed":"250307"},
{"muniType":"Town/City","region":"Yonkers","ed":"250308"},
{"muniType":"Town/City","region":"Yonkers","ed":"250310"},
{"muniType":"Town/City","region":"Yonkers","ed":"250311"},
{"muniType":"Town/City","region":"Yonkers","ed":"250312"},
{"muniType":"Town/City","region":"Yonkers","ed":"250313"},
{"muniType":"Town/City","region":"Yonkers","ed":"250315"},
{"muniType":"Town/City","region":"Yonkers","ed":"250316"},
{"muniType":"Town/City","region":"Yonkers","ed":"250317"},
{"muniType":"Town/City","region":"Yonkers","ed":"250319"},
{"muniType":"Town/City","region":"Yonkers","ed":"250320"},
{"muniType":"Town/City","region":"Yonkers","ed":"250321"},
{"muniType":"Town/City","region":"Yonkers","ed":"250323"},
{"muniType":"Town/City","region":"Yonkers","ed":"250324"},
{"muniType":"Town/City","region":"Yonkers","ed":"250325"},
{"muniType":"Town/City","region":"Yonkers","ed":"250326"},
{"muniType":"Town/City","region":"Yonkers","ed":"250327"},
{"muniType":"Town/City","region":"Yonkers","ed":"250328"},
{"muniType":"Town/City","region":"Yonkers","ed":"250329"},
{"muniType":"Town/City","region":"Yonkers","ed":"250401"},
{"muniType":"Town/City","region":"Yonkers","ed":"250402"},
{"muniType":"Town/City","region":"Yonkers","ed":"250403"},
{"muniType":"Town/City","region":"Yonkers","ed":"250404"},
{"muniType":"Town/City","region":"Yonkers","ed":"250405"},
{"muniType":"Town/City","region":"Yonkers","ed":"250406"},
{"muniType":"Town/City","region":"Yonkers","ed":"250407"},
{"muniType":"Town/City","region":"Yonkers","ed":"250408"},
{"muniType":"Town/City","region":"Yonkers","ed":"250409"},
{"muniType":"Town/City","region":"Yonkers","ed":"250410"},
{"muniType":"Town/City","region":"Yonkers","ed":"250411"},
{"muniType":"Town/City","region":"Yonkers","ed":"250412"},
{"muniType":"Town/City","region":"Yonkers","ed":"250415"},
{"muniType":"Town/City","region":"Yonkers","ed":"250416"},
{"muniType":"Town/City","region":"Yonkers","ed":"250417"},
{"muniType":"Town/City","region":"Yonkers","ed":"250418"},
{"muniType":"Town/City","region":"Yonkers","ed":"250419"},
{"muniType":"Town/City","region":"Yonkers","ed":"250421"},
{"muniType":"Town/City","region":"Yonkers","ed":"250501"},
{"muniType":"Town/City","region":"Yonkers","ed":"250502"},
{"muniType":"Town/City","region":"Yonkers","ed":"250503"},
{"muniType":"Town/City","region":"Yonkers","ed":"250504"},
{"muniType":"Town/City","region":"Yonkers","ed":"250505"},
{"muniType":"Town/City","region":"Yonkers","ed":"250506"},
{"muniType":"Town/City","region":"Yonkers","ed":"250507"},
{"muniType":"Town/City","region":"Yonkers","ed":"250508"},
{"muniType":"Town/City","region":"Yonkers","ed":"250509"},
{"muniType":"Town/City","region":"Yonkers","ed":"250510"},
{"muniType":"Town/City","region":"Yonkers","ed":"250511"},
{"muniType":"Town/City","region":"Yonkers","ed":"250512"},
{"muniType":"Town/City","region":"Yonkers","ed":"250513"},
{"muniType":"Town/City","region":"Yonkers","ed":"250514"},
{"muniType":"Town/City","region":"Yonkers","ed":"250515"},
{"muniType":"Town/City","region":"Yonkers","ed":"250516"},
{"muniType":"Town/City","region":"Yonkers","ed":"250517"},
{"muniType":"Town/City","region":"Yonkers","ed":"250518"},
{"muniType":"Town/City","region":"Yonkers","ed":"250519"},
{"muniType":"Town/City","region":"Yonkers","ed":"250520"},
{"muniType":"Town/City","region":"Yonkers","ed":"250522"},
{"muniType":"Town/City","region":"Yonkers","ed":"250523"},
{"muniType":"Town/City","region":"Yonkers","ed":"250524"},
{"muniType":"Town/City","region":"Yonkers","ed":"250601"},
{"muniType":"Town/City","region":"Yonkers","ed":"250602"},
{"muniType":"Town/City","region":"Yonkers","ed":"250603"},
{"muniType":"Town/City","region":"Yonkers","ed":"250604"},
{"muniType":"Town/City","region":"Yonkers","ed":"250606"},
{"muniType":"Town/City","region":"Yonkers","ed":"250607"},
{"muniType":"Town/City","region":"Yonkers","ed":"250608"},
{"muniType":"Town/City","region":"Yonkers","ed":"250610"},
{"muniType":"Town/City","region":"Yonkers","ed":"250611"},
{"muniType":"Town/City","region":"Yonkers","ed":"250612"},
{"muniType":"Town/City","region":"Yonkers","ed":"250613"},
{"muniType":"Town/City","region":"Yonkers","ed":"250614"},
{"muniType":"Town/City","region":"Yonkers","ed":"250616"},
{"muniType":"Town/City","region":"Yonkers","ed":"250617"},
{"muniType":"Town/City","region":"Yonkers","ed":"250618"},
{"muniType":"Town/City","region":"Yonkers","ed":"250620"},
{"muniType":"Town/City","region":"Yonkers","ed":"250621"},
{"muniType":"Town/City","region":"Yonkers","ed":"250622"},
{"muniType":"Town/City","region":"Yonkers","ed":"250624"},
{"muniType":"Town/City","region":"Yonkers","ed":"250628"},
{"muniType":"Town/City","region":"Yonkers","ed":"250632"},
{"muniType":"Town/City","region":"Yonkers","ed":"250633"},
{"muniType":"Town/City","region":"Yonkers","ed":"250701"},
{"muniType":"Town/City","region":"Yonkers","ed":"250702"},
{"muniType":"Town/City","region":"Yonkers","ed":"250703"},
{"muniType":"Town/City","region":"Yonkers","ed":"250704"},
{"muniType":"Town/City","region":"Yonkers","ed":"250705"},
{"muniType":"Town/City","region":"Yonkers","ed":"250706"},
{"muniType":"Town/City","region":"Yonkers","ed":"250707"},
{"muniType":"Town/City","region":"Yonkers","ed":"250708"},
{"muniType":"Town/City","region":"Yonkers","ed":"250709"},
{"muniType":"Town/City","region":"Yonkers","ed":"250711"},
{"muniType":"Town/City","region":"Yonkers","ed":"250713"},
{"muniType":"Town/City","region":"Yonkers","ed":"250714"},
{"muniType":"Town/City","region":"Yonkers","ed":"250715"},
{"muniType":"Town/City","region":"Yonkers","ed":"250716"},
{"muniType":"Town/City","region":"Yonkers","ed":"250717"},
{"muniType":"Town/City","region":"Yonkers","ed":"250719"},
{"muniType":"Town/City","region":"Yonkers","ed":"250720"},
{"muniType":"Town/City","region":"Yonkers","ed":"250721"},
{"muniType":"Town/City","region":"Yonkers","ed":"250723"},
{"muniType":"Town/City","region":"Yonkers","ed":"250724"},
{"muniType":"Town/City","region":"Yonkers","ed":"250725"},
{"muniType":"Town/City","region":"Yonkers","ed":"250726"},
{"muniType":"Town/City","region":"Yonkers","ed":"250729"},
{"muniType":"Town/City","region":"Yonkers","ed":"250730"},
{"muniType":"Town/City","region":"Yonkers","ed":"250731"},
{"muniType":"Town/City","region":"Yonkers","ed":"250801"},
{"muniType":"Town/City","region":"Yonkers","ed":"250802"},
{"muniType":"Town/City","region":"Yonkers","ed":"250803"},
{"muniType":"Town/City","region":"Yonkers","ed":"250805"},
{"muniType":"Town/City","region":"Yonkers","ed":"250806"},
{"muniType":"Town/City","region":"Yonkers","ed":"250807"},
{"muniType":"Town/City","region":"Yonkers","ed":"250809"},
{"muniType":"Town/City","region":"Yonkers","ed":"250810"},
{"muniType":"Town/City","region":"Yonkers","ed":"250811"},
{"muniType":"Town/City","region":"Yonkers","ed":"250812"},
{"muniType":"Town/City","region":"Yonkers","ed":"250813"},
{"muniType":"Town/City","region":"Yonkers","ed":"250814"},
{"muniType":"Town/City","region":"Yonkers","ed":"250815"},
{"muniType":"Town/City","region":"Yonkers","ed":"250817"},
{"muniType":"Town/City","region":"Yonkers","ed":"250822"},
{"muniType":"Town/City","region":"Yonkers","ed":"250823"},
{"muniType":"Town/City","region":"Yonkers","ed":"250824"},
{"muniType":"Town/City","region":"Yonkers","ed":"250826"},
{"muniType":"Town/City","region":"Yonkers","ed":"250828"},
{"muniType":"Town/City","region":"Yonkers","ed":"250901"},
{"muniType":"Town/City","region":"Yonkers","ed":"250902"},
{"muniType":"Town/City","region":"Yonkers","ed":"250903"},
{"muniType":"Town/City","region":"Yonkers","ed":"250904"},
{"muniType":"Town/City","region":"Yonkers","ed":"250905"},
{"muniType":"Town/City","region":"Yonkers","ed":"250906"},
{"muniType":"Town/City","region":"Yonkers","ed":"250907"},
{"muniType":"Town/City","region":"Yonkers","ed":"250908"},
{"muniType":"Town/City","region":"Yonkers","ed":"250909"},
{"muniType":"Town/City","region":"Yonkers","ed":"250910"},
{"muniType":"Town/City","region":"Yonkers","ed":"250911"},
{"muniType":"Town/City","region":"Yonkers","ed":"250912"},
{"muniType":"Town/City","region":"Yonkers","ed":"250913"},
{"muniType":"Town/City","region":"Yonkers","ed":"250915"},
{"muniType":"Town/City","region":"Yonkers","ed":"250916"},
{"muniType":"Town/City","region":"Yonkers","ed":"250917"},
{"muniType":"Town/City","region":"Yonkers","ed":"251001"},
{"muniType":"Town/City","region":"Yonkers","ed":"251002"},
{"muniType":"Town/City","region":"Yonkers","ed":"251003"},
{"muniType":"Town/City","region":"Yonkers","ed":"251004"},
{"muniType":"Town/City","region":"Yonkers","ed":"251005"},
{"muniType":"Town/City","region":"Yonkers","ed":"251006"},
{"muniType":"Town/City","region":"Yonkers","ed":"251007"},
{"muniType":"Town/City","region":"Yonkers","ed":"251008"},
{"muniType":"Town/City","region":"Yonkers","ed":"251009"},
{"muniType":"Town/City","region":"Yonkers","ed":"251011"},
{"muniType":"Town/City","region":"Yonkers","ed":"251012"},
{"muniType":"Town/City","region":"Yonkers","ed":"251013"},
{"muniType":"Town/City","region":"Yonkers","ed":"251014"},
{"muniType":"Town/City","region":"Yonkers","ed":"251015"},
{"muniType":"Town/City","region":"Yonkers","ed":"251016"},
{"muniType":"Town/City","region":"Yonkers","ed":"251017"},
{"muniType":"Town/City","region":"Yonkers","ed":"251018"},
{"muniType":"Town/City","region":"Yonkers","ed":"251020"},
{"muniType":"Town/City","region":"Yonkers","ed":"251021"},
{"muniType":"Town/City","region":"Yonkers","ed":"251025"},
{"muniType":"Town/City","region":"Yonkers","ed":"251101"},
{"muniType":"Town/City","region":"Yonkers","ed":"251102"},
{"muniType":"Town/City","region":"Yonkers","ed":"251103"},
{"muniType":"Town/City","region":"Yonkers","ed":"251104"},
{"muniType":"Town/City","region":"Yonkers","ed":"251105"},
{"muniType":"Town/City","region":"Yonkers","ed":"251106"},
{"muniType":"Town/City","region":"Yonkers","ed":"251107"},
{"muniType":"Town/City","region":"Yonkers","ed":"251108"},
{"muniType":"Town/City","region":"Yonkers","ed":"251109"},
{"muniType":"Town/City","region":"Yonkers","ed":"251110"},
{"muniType":"Town/City","region":"Yonkers","ed":"251111"},
{"muniType":"Town/City","region":"Yonkers","ed":"251112"},
{"muniType":"Town/City","region":"Yonkers","ed":"251113"},
{"muniType":"Town/City","region":"Yonkers","ed":"251114"},
{"muniType":"Town/City","region":"Yonkers","ed":"251116"},
{"muniType":"Town/City","region":"Yonkers","ed":"251117"},
{"muniType":"Town/City","region":"Yonkers","ed":"251118"},
{"muniType":"Town/City","region":"Yonkers","ed":"251119"},
{"muniType":"Town/City","region":"Yonkers","ed":"251120"},
{"muniType":"Town/City","region":"Yonkers","ed":"251121"},
{"muniType":"Town/City","region":"Yonkers","ed":"251201"},
{"muniType":"Town/City","region":"Yonkers","ed":"251202"},
{"muniType":"Town/City","region":"Yonkers","ed":"251203"},
{"muniType":"Town/City","region":"Yonkers","ed":"251204"},
{"muniType":"Town/City","region":"Yonkers","ed":"251205"},
{"muniType":"Town/City","region":"Yonkers","ed":"251206"},
{"muniType":"Town/City","region":"Yonkers","ed":"251207"},
{"muniType":"Town/City","region":"Yonkers","ed":"251208"},
{"muniType":"Town/City","region":"Yonkers","ed":"251209"},
{"muniType":"Town/City","region":"Yonkers","ed":"251210"},
{"muniType":"Town/City","region":"Yonkers","ed":"251211"},
{"muniType":"Town/City","region":"Yonkers","ed":"251212"},
{"muniType":"Town/City","region":"Yonkers","ed":"251213"},
{"muniType":"Town/City","region":"Yonkers","ed":"251215"},
{"muniType":"Town/City","region":"Yonkers","ed":"251216"},
{"muniType":"Town/City","region":"Yonkers","ed":"251217"},
{"muniType":"Town/City","region":"Yonkers","ed":"251218"},
{"muniType":"Town/City","region":"Yonkers","ed":"251219"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"020001"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"020002"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"020003"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"020004"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"020012"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"020014"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"020015"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"020016"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"020018"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"020020"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"020021"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"020024"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"020026"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"020027"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"020028"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"020034"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"020035"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"190001"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"190005"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"190009"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"190010"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"190013"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"190014"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"190019"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"190022"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"190023"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"190027"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"190028"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"190036"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"190038"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"190039"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"190040"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"190041"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"190042"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"220001"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"220002"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"220003"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"220004"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"220005"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"220006"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"220007"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"220008"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"220009"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"220010"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"220011"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"220014"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"220015"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"220016"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"220017"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"220018"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"220019"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"220021"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"220022"},
{"muniType":"County Legislator","region":"County Legislator LD 1","ed":"220023"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"010001"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"010002"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"010003"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"010004"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"010005"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"010006"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"010007"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"010008"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"010009"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"010010"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"010011"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"010012"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"010013"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"010014"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"010015"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"010016"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"010017"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"010018"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"060001"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"060002"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"060003"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"060004"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"060005"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"060006"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"060007"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"060008"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"060009"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"060010"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"060011"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"060012"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"080001"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"080002"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"080003"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"080004"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"080005"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"080006"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"080007"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"120001"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"120002"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"120003"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"120004"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"120005"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"120006"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"120007"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"120008"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"150001"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"150002"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"150003"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"150004"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"180001"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"180002"},
{"muniType":"County Legislator","region":"County Legislator LD 2","ed":"180016"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"040029"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"040048"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"040067"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"090001"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"090002"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"090004"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"090005"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"090006"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"090009"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"090010"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"090011"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"090012"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"090013"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"090014"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"090015"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"090016"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"090017"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"090018"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"090019"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"090020"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"090022"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"090023"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"090024"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"090025"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"090026"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"090027"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"090028"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"090029"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"090030"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"090031"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"090032"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"090033"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"090034"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"090035"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"090036"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"090037"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"090038"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"090039"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"090040"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"090041"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"090042"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"090043"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"090044"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"110001"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"110002"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"110003"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"110004"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"110005"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"110006"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"110007"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"110008"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"110009"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"110010"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"110011"},
{"muniType":"County Legislator","region":"County Legislator LD 3","ed":"130019"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"100001"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"100002"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"100003"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"100004"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"100005"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"100006"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"100007"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"100008"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"100009"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"100010"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"100011"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"100012"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"100013"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"100014"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"100015"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"100016"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"180003"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"180004"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"180005"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"180006"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"180007"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"180008"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"180009"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"180010"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"180011"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"180012"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"180013"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"180014"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"180015"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"180017"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"180018"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"190002"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"190003"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"190004"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"190006"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"190007"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"190008"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"190011"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"190012"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"190015"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"190016"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"190017"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"190018"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"190020"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"190021"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"190024"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"190025"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"190026"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"190029"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"190030"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"190031"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"190032"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"190033"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"190034"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"190035"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"190037"},
{"muniType":"County Legislator","region":"County Legislator LD 4","ed":"190043"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"050001"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"050002"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"050011"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"170001"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"170002"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"170003"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"170004"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"170005"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"170006"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"170007"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"170008"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"170009"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"170010"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"170011"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"170012"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"170013"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"170014"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"170015"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"170016"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"170017"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"170018"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"170019"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"240001"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"240002"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"240003"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"240004"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"240005"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"240006"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"240007"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"240008"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"240009"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"240010"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"240011"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"240012"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"240013"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"240014"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"240015"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"240017"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"240018"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"240019"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"240020"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"240021"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"240022"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"240023"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"240024"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"240025"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"240026"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"240027"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"240030"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"240035"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"240036"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"240037"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"240042"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"240043"},
{"muniType":"County Legislator","region":"County Legislator LD 5","ed":"240045"},
{"muniType":"County Legislator","region":"County Legislator LD 6","ed":"050003"},
{"muniType":"County Legislator","region":"County Legislator LD 6","ed":"050004"},
{"muniType":"County Legislator","region":"County Legislator LD 6","ed":"050006"},
{"muniType":"County Legislator","region":"County Legislator LD 6","ed":"050007"},
{"muniType":"County Legislator","region":"County Legislator LD 6","ed":"050008"},
{"muniType":"County Legislator","region":"County Legislator LD 6","ed":"050009"},
{"muniType":"County Legislator","region":"County Legislator LD 6","ed":"050010"},
{"muniType":"County Legislator","region":"County Legislator LD 6","ed":"050013"},
{"muniType":"County Legislator","region":"County Legislator LD 6","ed":"050014"},
{"muniType":"County Legislator","region":"County Legislator LD 6","ed":"050015"},
{"muniType":"County Legislator","region":"County Legislator LD 6","ed":"050016"},
{"muniType":"County Legislator","region":"County Legislator LD 6","ed":"050017"},
{"muniType":"County Legislator","region":"County Legislator LD 6","ed":"050018"},
{"muniType":"County Legislator","region":"County Legislator LD 6","ed":"050019"},
{"muniType":"County Legislator","region":"County Legislator LD 6","ed":"160005"},
{"muniType":"County Legislator","region":"County Legislator LD 6","ed":"160006"},
{"muniType":"County Legislator","region":"County Legislator LD 6","ed":"160007"},
{"muniType":"County Legislator","region":"County Legislator LD 6","ed":"160008"},
{"muniType":"County Legislator","region":"County Legislator LD 6","ed":"160009"},
{"muniType":"County Legislator","region":"County Legislator LD 6","ed":"160010"},
{"muniType":"County Legislator","region":"County Legislator LD 6","ed":"160011"},
{"muniType":"County Legislator","region":"County Legislator LD 6","ed":"160012"},
{"muniType":"County Legislator","region":"County Legislator LD 6","ed":"160013"},
{"muniType":"County Legislator","region":"County Legislator LD 6","ed":"160014"},
{"muniType":"County Legislator","region":"County Legislator LD 6","ed":"160015"},
{"muniType":"County Legislator","region":"County Legislator LD 6","ed":"160016"},
{"muniType":"County Legislator","region":"County Legislator LD 6","ed":"160017"},
{"muniType":"County Legislator","region":"County Legislator LD 6","ed":"160018"},
{"muniType":"County Legislator","region":"County Legislator LD 6","ed":"160019"},
{"muniType":"County Legislator","region":"County Legislator LD 6","ed":"160020"},
{"muniType":"County Legislator","region":"County Legislator LD 6","ed":"160021"},
{"muniType":"County Legislator","region":"County Legislator LD 6","ed":"160022"},
{"muniType":"County Legislator","region":"County Legislator LD 6","ed":"160023"},
{"muniType":"County Legislator","region":"County Legislator LD 6","ed":"160025"},
{"muniType":"County Legislator","region":"County Legislator LD 6","ed":"160026"},
{"muniType":"County Legislator","region":"County Legislator LD 6","ed":"160027"},
{"muniType":"County Legislator","region":"County Legislator LD 6","ed":"160029"},
{"muniType":"County Legislator","region":"County Legislator LD 6","ed":"160030"},
{"muniType":"County Legislator","region":"County Legislator LD 6","ed":"160031"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"050005"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"050012"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"050020"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"070001"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"070002"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"070003"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"070004"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"070005"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"070006"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"070007"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"070008"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"070009"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"070010"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"070011"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"070012"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"070013"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"070014"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"070015"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"070016"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"070017"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"070018"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"070019"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"070020"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"070021"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"070022"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"070023"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"070024"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"070025"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"070026"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"070027"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"070028"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"160001"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"160002"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"160003"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"160004"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"160024"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"160028"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"210011"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"210016"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"210021"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"210038"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"230001"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"230002"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"230003"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"230004"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"230005"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"230006"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"230007"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"230008"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"230009"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"230010"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"230011"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"230012"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"230013"},
{"muniType":"County Legislator","region":"County Legislator LD 7","ed":"230014"},
{"muniType":"County Legislator","region":"County Legislator LD 8","ed":"040001"},
{"muniType":"County Legislator","region":"County Legislator LD 8","ed":"040002"},
{"muniType":"County Legislator","region":"County Legislator LD 8","ed":"040003"},
{"muniType":"County Legislator","region":"County Legislator LD 8","ed":"040004"},
{"muniType":"County Legislator","region":"County Legislator LD 8","ed":"040005"},
{"muniType":"County Legislator","region":"County Legislator LD 8","ed":"040006"},
{"muniType":"County Legislator","region":"County Legislator LD 8","ed":"040007"},
{"muniType":"County Legislator","region":"County Legislator LD 8","ed":"040026"},
{"muniType":"County Legislator","region":"County Legislator LD 8","ed":"040027"},
{"muniType":"County Legislator","region":"County Legislator LD 8","ed":"040028"},
{"muniType":"County Legislator","region":"County Legislator LD 8","ed":"040030"},
{"muniType":"County Legislator","region":"County Legislator LD 8","ed":"040041"},
{"muniType":"County Legislator","region":"County Legislator LD 8","ed":"040042"},
{"muniType":"County Legislator","region":"County Legislator LD 8","ed":"040044"},
{"muniType":"County Legislator","region":"County Legislator LD 8","ed":"040045"},
{"muniType":"County Legislator","region":"County Legislator LD 8","ed":"040046"},
{"muniType":"County Legislator","region":"County Legislator LD 8","ed":"040047"},
{"muniType":"County Legislator","region":"County Legislator LD 8","ed":"040055"},
{"muniType":"County Legislator","region":"County Legislator LD 8","ed":"040056"},
{"muniType":"County Legislator","region":"County Legislator LD 8","ed":"040061"},
{"muniType":"County Legislator","region":"County Legislator LD 8","ed":"040063"},
{"muniType":"County Legislator","region":"County Legislator LD 8","ed":"040064"},
{"muniType":"County Legislator","region":"County Legislator LD 8","ed":"040068"},
{"muniType":"County Legislator","region":"County Legislator LD 8","ed":"040071"},
{"muniType":"County Legislator","region":"County Legislator LD 8","ed":"040074"},
{"muniType":"County Legislator","region":"County Legislator LD 8","ed":"040076"},
{"muniType":"County Legislator","region":"County Legislator LD 8","ed":"040078"},
{"muniType":"County Legislator","region":"County Legislator LD 8","ed":"040081"},
{"muniType":"County Legislator","region":"County Legislator LD 8","ed":"090003"},
{"muniType":"County Legislator","region":"County Legislator LD 8","ed":"090007"},
{"muniType":"County Legislator","region":"County Legislator LD 8","ed":"240016"},
{"muniType":"County Legislator","region":"County Legislator LD 8","ed":"240028"},
{"muniType":"County Legislator","region":"County Legislator LD 8","ed":"240029"},
{"muniType":"County Legislator","region":"County Legislator LD 8","ed":"240031"},
{"muniType":"County Legislator","region":"County Legislator LD 8","ed":"240032"},
{"muniType":"County Legislator","region":"County Legislator LD 8","ed":"240033"},
{"muniType":"County Legislator","region":"County Legislator LD 8","ed":"240034"},
{"muniType":"County Legislator","region":"County Legislator LD 8","ed":"240038"},
{"muniType":"County Legislator","region":"County Legislator LD 8","ed":"240039"},
{"muniType":"County Legislator","region":"County Legislator LD 8","ed":"240040"},
{"muniType":"County Legislator","region":"County Legislator LD 8","ed":"240041"},
{"muniType":"County Legislator","region":"County Legislator LD 8","ed":"240044"},
{"muniType":"County Legislator","region":"County Legislator LD 8","ed":"240046"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"020005"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"020006"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"020007"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"020008"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"020009"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"020010"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"020011"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"020013"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"020017"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"020019"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"020022"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"020023"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"020025"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"020029"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"020030"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"020031"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"020032"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"020033"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"020038"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"020039"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"130001"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"130002"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"130003"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"130004"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"130005"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"130006"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"130007"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"130008"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"130009"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"130010"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"130011"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"130012"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"130013"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"130014"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"130015"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"130016"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"130017"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"130018"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"130020"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"130021"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"130022"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"130023"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"130024"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"130025"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"130026"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"130027"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"130028"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"130029"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"130030"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"220013"},
{"muniType":"County Legislator","region":"County Legislator LD 9","ed":"220020"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"030001"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"030002"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"030003"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"030004"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"030005"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"030006"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"030007"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"030008"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"030009"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"030010"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"030011"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"030012"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"030013"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"030014"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"030015"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"030023"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"030024"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"030025"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"030026"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"030027"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"030028"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"030029"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"030030"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"030031"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"030032"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"030033"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"030034"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"030035"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"030036"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"030037"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"030038"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"030039"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"210002"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"210010"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"210012"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"210013"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"210014"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"210015"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"210027"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"210030"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"210031"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"210032"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"210033"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"210035"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"210039"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"210040"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"210041"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"210042"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"210043"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"210044"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"210045"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"210046"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"210047"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"210049"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"210050"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"210051"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"210053"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"210057"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"210058"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"210059"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"210060"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"210063"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"210064"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"210066"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"210067"},
{"muniType":"County Legislator","region":"County Legislator LD 10","ed":"210076"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"140001"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"140002"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"140003"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"140004"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"140005"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"140006"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"140007"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"140008"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"140009"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"140010"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"210001"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"210003"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"210004"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"210005"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"210006"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"210007"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"210008"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"210009"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"210018"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"210019"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"210022"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"210023"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"210024"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"210025"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"210026"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"210028"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"210029"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"210034"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"210036"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"210037"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"210048"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"210052"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"210054"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"210055"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"210056"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"210061"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"210065"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"210068"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"210069"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"210070"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"210071"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"210072"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"210073"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"210074"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"210075"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"210077"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"210078"},
{"muniType":"County Legislator","region":"County Legislator LD 11","ed":"210079"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040008"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040009"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040010"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040011"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040012"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040013"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040014"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040015"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040016"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040017"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040018"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040019"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040020"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040021"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040022"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040023"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040024"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040025"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040031"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040032"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040033"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040034"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040035"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040036"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040037"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040038"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040039"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040040"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040043"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040049"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040050"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040051"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040052"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040053"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040054"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040057"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040058"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040059"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040060"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040062"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040065"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040066"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040069"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040070"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040072"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040073"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040075"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040077"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040079"},
{"muniType":"County Legislator","region":"County Legislator LD 12","ed":"040080"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200001"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200002"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200003"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200004"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200005"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200006"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200007"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200008"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200009"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200010"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200011"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200012"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200013"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200014"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200015"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200016"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200017"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200018"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200019"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200020"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200023"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200024"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200025"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200029"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200030"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200031"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200032"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200033"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200036"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200037"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200041"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200042"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200043"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200044"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200045"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200048"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200051"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200052"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200053"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200054"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200055"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200056"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200057"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200058"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200059"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200060"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200061"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200062"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200064"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200065"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200066"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200067"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200068"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200069"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200070"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200071"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200072"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200073"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200074"},
{"muniType":"County Legislator","region":"County Legislator LD 13","ed":"200076"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"200021"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"200022"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"200026"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"200027"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"200028"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"200034"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"200035"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"200038"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"200039"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"200040"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"200046"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"200047"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"200049"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"200050"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"250201"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"250202"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"250203"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"250205"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"250206"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"250209"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"250210"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"250211"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"250212"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"250214"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"250216"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"250224"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"250901"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"250902"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"250903"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"250904"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"250905"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"250906"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"250907"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"250908"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"250909"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"250910"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"250911"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"250912"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"250913"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"250915"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"250916"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"250917"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"251017"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"251113"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"251121"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"251201"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"251203"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"251204"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"251205"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"251206"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"251207"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"251208"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"251209"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"251210"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"251211"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"251212"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"251215"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"251216"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"251217"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"251218"},
{"muniType":"County Legislator","region":"County Legislator LD 14","ed":"251219"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"030016"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"030017"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"030018"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"030019"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"030020"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"030021"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"030022"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"250204"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"250213"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"250302"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"250304"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"250305"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"250320"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"250321"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"250325"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"250501"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"250502"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"250503"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"250504"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"250505"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"250506"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"250507"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"250508"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"250509"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"250510"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"250511"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"250512"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"250513"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"250514"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"250515"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"250516"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"250517"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"250518"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"250519"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"250520"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"250522"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"250523"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"250524"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"250607"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"250608"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"250610"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"250611"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"250612"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"250614"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"250618"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"250624"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"250714"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"250716"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"250719"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"250731"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"251004"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"251007"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"251009"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"251011"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"251013"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"251014"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"251020"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"251025"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"251101"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"251102"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"251103"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"251104"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"251105"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"251107"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"251109"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"251110"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"251111"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"251112"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"251116"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"251117"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"251118"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"251119"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"251120"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"251202"},
{"muniType":"County Legislator","region":"County Legislator LD 15","ed":"251213"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250101"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250105"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250106"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250107"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250108"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250109"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250110"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250111"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250112"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250301"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250303"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250306"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250307"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250308"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250310"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250311"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250312"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250313"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250315"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250316"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250317"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250319"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250323"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250324"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250326"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250327"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250328"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250329"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250401"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250601"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250602"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250603"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250604"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250606"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250613"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250616"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250617"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250620"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250621"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250622"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250628"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250632"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250633"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250701"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250703"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250704"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250715"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250717"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250720"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250721"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250723"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250724"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250725"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250729"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250730"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"250824"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"251001"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"251002"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"251003"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"251005"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"251006"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"251008"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"251012"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"251015"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"251016"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"251018"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"251021"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"251106"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"251108"},
{"muniType":"County Legislator","region":"County Legislator LD 16","ed":"251114"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250102"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250103"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250104"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250118"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250122"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250126"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250207"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250208"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250218"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250220"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250222"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250223"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250226"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250229"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250402"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250403"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250404"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250405"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250406"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250407"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250408"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250409"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250410"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250411"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250412"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250415"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250416"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250417"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250418"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250419"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250421"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250702"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250705"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250706"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250707"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250708"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250709"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250711"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250713"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250726"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250801"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250802"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250803"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250805"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250806"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250807"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250809"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250810"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250811"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250812"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250813"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250814"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250815"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250817"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250822"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250823"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250826"},
{"muniType":"County Legislator","region":"County Legislator LD 17","ed":"250828"},
{"muniType":"Council District","region":"New Rochelle City Council 1","ed":"210023"},
{"muniType":"Council District","region":"New Rochelle City Council 1","ed":"210025"},
{"muniType":"Council District","region":"New Rochelle City Council 1","ed":"210051"},
{"muniType":"Council District","region":"New Rochelle City Council 1","ed":"210052"},
{"muniType":"Council District","region":"New Rochelle City Council 1","ed":"210054"},
{"muniType":"Council District","region":"New Rochelle City Council 1","ed":"210055"},
{"muniType":"Council District","region":"New Rochelle City Council 1","ed":"210074"},
{"muniType":"Council District","region":"New Rochelle City Council 2","ed":"210001"},
{"muniType":"Council District","region":"New Rochelle City Council 2","ed":"210003"},
{"muniType":"Council District","region":"New Rochelle City Council 2","ed":"210004"},
{"muniType":"Council District","region":"New Rochelle City Council 2","ed":"210008"},
{"muniType":"Council District","region":"New Rochelle City Council 2","ed":"210018"},
{"muniType":"Council District","region":"New Rochelle City Council 2","ed":"210022"},
{"muniType":"Council District","region":"New Rochelle City Council 2","ed":"210024"},
{"muniType":"Council District","region":"New Rochelle City Council 2","ed":"210028"},
{"muniType":"Council District","region":"New Rochelle City Council 2","ed":"210065"},
{"muniType":"Council District","region":"New Rochelle City Council 2","ed":"210069"},
{"muniType":"Council District","region":"New Rochelle City Council 2","ed":"210071"},
{"muniType":"Council District","region":"New Rochelle City Council 2","ed":"210073"},
{"muniType":"Council District","region":"New Rochelle City Council 2","ed":"210076"},
{"muniType":"Council District","region":"New Rochelle City Council 2","ed":"210077"},
{"muniType":"Council District","region":"New Rochelle City Council 3","ed":"210002"},
{"muniType":"Council District","region":"New Rochelle City Council 3","ed":"210005"},
{"muniType":"Council District","region":"New Rochelle City Council 3","ed":"210010"},
{"muniType":"Council District","region":"New Rochelle City Council 3","ed":"210015"},
{"muniType":"Council District","region":"New Rochelle City Council 3","ed":"210049"},
{"muniType":"Council District","region":"New Rochelle City Council 3","ed":"210053"},
{"muniType":"Council District","region":"New Rochelle City Council 3","ed":"210058"},
{"muniType":"Council District","region":"New Rochelle City Council 3","ed":"210059"},
{"muniType":"Council District","region":"New Rochelle City Council 3","ed":"210060"},
{"muniType":"Council District","region":"New Rochelle City Council 3","ed":"210064"},
{"muniType":"Council District","region":"New Rochelle City Council 3","ed":"210066"},
{"muniType":"Council District","region":"New Rochelle City Council 3","ed":"210078"},
{"muniType":"Council District","region":"New Rochelle City Council 4","ed":"210079"},
{"muniType":"Council District","region":"New Rochelle City Council 4","ed":"210006"},
{"muniType":"Council District","region":"New Rochelle City Council 4","ed":"210009"},
{"muniType":"Council District","region":"New Rochelle City Council 4","ed":"210019"},
{"muniType":"Council District","region":"New Rochelle City Council 4","ed":"210026"},
{"muniType":"Council District","region":"New Rochelle City Council 4","ed":"210029"},
{"muniType":"Council District","region":"New Rochelle City Council 4","ed":"210050"},
{"muniType":"Council District","region":"New Rochelle City Council 4","ed":"210056"},
{"muniType":"Council District","region":"New Rochelle City Council 4","ed":"210057"},
{"muniType":"Council District","region":"New Rochelle City Council 4","ed":"210063"},
{"muniType":"Council District","region":"New Rochelle City Council 4","ed":"210075"},
{"muniType":"Council District","region":"New Rochelle City Council 5","ed":"210011"},
{"muniType":"Council District","region":"New Rochelle City Council 5","ed":"210012"},
{"muniType":"Council District","region":"New Rochelle City Council 5","ed":"210013"},
{"muniType":"Council District","region":"New Rochelle City Council 5","ed":"210014"},
{"muniType":"Council District","region":"New Rochelle City Council 5","ed":"210016"},
{"muniType":"Council District","region":"New Rochelle City Council 5","ed":"210021"},
{"muniType":"Council District","region":"New Rochelle City Council 5","ed":"210027"},
{"muniType":"Council District","region":"New Rochelle City Council 5","ed":"210035"},
{"muniType":"Council District","region":"New Rochelle City Council 5","ed":"210038"},
{"muniType":"Council District","region":"New Rochelle City Council 5","ed":"210041"},
{"muniType":"Council District","region":"New Rochelle City Council 5","ed":"210042"},
{"muniType":"Council District","region":"New Rochelle City Council 5","ed":"210044"},
{"muniType":"Council District","region":"New Rochelle City Council 5","ed":"210061"},
{"muniType":"Council District","region":"New Rochelle City Council 5","ed":"210068"},
{"muniType":"Council District","region":"New Rochelle City Council 5","ed":"210070"},
{"muniType":"Council District","region":"New Rochelle City Council 5","ed":"210072"},
{"muniType":"Council District","region":"New Rochelle City Council 6","ed":"210007"},
{"muniType":"Council District","region":"New Rochelle City Council 6","ed":"210030"},
{"muniType":"Council District","region":"New Rochelle City Council 6","ed":"210031"},
{"muniType":"Council District","region":"New Rochelle City Council 6","ed":"210032"},
{"muniType":"Council District","region":"New Rochelle City Council 6","ed":"210033"},
{"muniType":"Council District","region":"New Rochelle City Council 6","ed":"210034"},
{"muniType":"Council District","region":"New Rochelle City Council 6","ed":"210036"},
{"muniType":"Council District","region":"New Rochelle City Council 6","ed":"210037"},
{"muniType":"Council District","region":"New Rochelle City Council 6","ed":"210039"},
{"muniType":"Council District","region":"New Rochelle City Council 6","ed":"210040"},
{"muniType":"Council District","region":"New Rochelle City Council 6","ed":"210043"},
{"muniType":"Council District","region":"New Rochelle City Council 6","ed":"210045"},
{"muniType":"Council District","region":"New Rochelle City Council 6","ed":"210046"},
{"muniType":"Council District","region":"New Rochelle City Council 6","ed":"210047"},
{"muniType":"Council District","region":"New Rochelle City Council 6","ed":"210048"},
{"muniType":"Council District","region":"New Rochelle City Council 6","ed":"210067"},
{"muniType":"Council District","region":"Yonkers City Council 1","ed":"250101"},
{"muniType":"Council District","region":"Yonkers City Council 1","ed":"250102"},
{"muniType":"Council District","region":"Yonkers City Council 1","ed":"250105"},
{"muniType":"Council District","region":"Yonkers City Council 1","ed":"250106"},
{"muniType":"Council District","region":"Yonkers City Council 1","ed":"250107"},
{"muniType":"Council District","region":"Yonkers City Council 1","ed":"250108"},
{"muniType":"Council District","region":"Yonkers City Council 1","ed":"250109"},
{"muniType":"Council District","region":"Yonkers City Council 1","ed":"250110"},
{"muniType":"Council District","region":"Yonkers City Council 1","ed":"250111"},
{"muniType":"Council District","region":"Yonkers City Council 1","ed":"250112"},
{"muniType":"Council District","region":"Yonkers City Council 1","ed":"250118"},
{"muniType":"Council District","region":"Yonkers City Council 1","ed":"250122"},
{"muniType":"Council District","region":"Yonkers City Council 1","ed":"250303"},
{"muniType":"Council District","region":"Yonkers City Council 1","ed":"250304"},
{"muniType":"Council District","region":"Yonkers City Council 1","ed":"250307"},
{"muniType":"Council District","region":"Yonkers City Council 1","ed":"250308"},
{"muniType":"Council District","region":"Yonkers City Council 1","ed":"250312"},
{"muniType":"Council District","region":"Yonkers City Council 1","ed":"250313"},
{"muniType":"Council District","region":"Yonkers City Council 1","ed":"250316"},
{"muniType":"Council District","region":"Yonkers City Council 1","ed":"250317"},
{"muniType":"Council District","region":"Yonkers City Council 1","ed":"250323"},
{"muniType":"Council District","region":"Yonkers City Council 1","ed":"250324"},
{"muniType":"Council District","region":"Yonkers City Council 1","ed":"250327"},
{"muniType":"Council District","region":"Yonkers City Council 1","ed":"250328"},
{"muniType":"Council District","region":"Yonkers City Council 1","ed":"250401"},
{"muniType":"Council District","region":"Yonkers City Council 1","ed":"250601"},
{"muniType":"Council District","region":"Yonkers City Council 1","ed":"250613"},
{"muniType":"Council District","region":"Yonkers City Council 1","ed":"250620"},
{"muniType":"Council District","region":"Yonkers City Council 1","ed":"250621"},
{"muniType":"Council District","region":"Yonkers City Council 1","ed":"250622"},
{"muniType":"Council District","region":"Yonkers City Council 1","ed":"250628"},
{"muniType":"Council District","region":"Yonkers City Council 1","ed":"250703"},
{"muniType":"Council District","region":"Yonkers City Council 1","ed":"250724"},
{"muniType":"Council District","region":"Yonkers City Council 1","ed":"250828"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250103"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250126"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250207"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250214"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250226"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250229"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250301"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250302"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250305"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250306"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250310"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250311"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250315"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250320"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250321"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250325"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250326"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250329"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250403"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250407"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250410"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250412"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250421"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250602"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250603"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250604"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250606"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250610"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250611"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250612"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250614"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250616"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250617"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250624"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250632"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250633"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250701"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250721"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250801"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250802"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250803"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250805"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250807"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250809"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250811"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250813"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250815"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250822"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"250823"},
{"muniType":"Council District","region":"Yonkers City Council 3","ed":"251017"},
{"muniType":"Council District","region":"Yonkers City Council 5","ed":"250213"},
{"muniType":"Council District","region":"Yonkers City Council 5","ed":"250501"},
{"muniType":"Council District","region":"Yonkers City Council 5","ed":"250505"},
{"muniType":"Council District","region":"Yonkers City Council 5","ed":"250506"},
{"muniType":"Council District","region":"Yonkers City Council 5","ed":"250508"},
{"muniType":"Council District","region":"Yonkers City Council 5","ed":"250509"},
{"muniType":"Council District","region":"Yonkers City Council 5","ed":"250512"},
{"muniType":"Council District","region":"Yonkers City Council 5","ed":"250513"},
{"muniType":"Council District","region":"Yonkers City Council 5","ed":"250514"},
{"muniType":"Council District","region":"Yonkers City Council 5","ed":"250515"},
{"muniType":"Council District","region":"Yonkers City Council 5","ed":"251001"},
{"muniType":"Council District","region":"Yonkers City Council 5","ed":"251012"},
{"muniType":"Council District","region":"Yonkers City Council 5","ed":"251015"},
{"muniType":"Council District","region":"Yonkers City Council 5","ed":"251101"},
{"muniType":"Council District","region":"Yonkers City Council 5","ed":"251102"},
{"muniType":"Council District","region":"Yonkers City Council 5","ed":"251103"},
{"muniType":"Council District","region":"Yonkers City Council 5","ed":"251104"},
{"muniType":"Council District","region":"Yonkers City Council 5","ed":"251105"},
{"muniType":"Council District","region":"Yonkers City Council 5","ed":"251107"},
{"muniType":"Council District","region":"Yonkers City Council 5","ed":"251108"},
{"muniType":"Council District","region":"Yonkers City Council 5","ed":"251110"},
{"muniType":"Council District","region":"Yonkers City Council 5","ed":"251111"},
{"muniType":"Council District","region":"Yonkers City Council 5","ed":"251112"},
{"muniType":"Council District","region":"Yonkers City Council 5","ed":"251113"},
{"muniType":"Council District","region":"Yonkers City Council 5","ed":"251114"},
{"muniType":"Council District","region":"Yonkers City Council 5","ed":"251116"},
{"muniType":"Council District","region":"Yonkers City Council 5","ed":"251117"},
{"muniType":"Council District","region":"Yonkers City Council 5","ed":"251118"},
{"muniType":"Council District","region":"Yonkers City Council 5","ed":"251119"},
{"muniType":"Council District","region":"Yonkers City Council 5","ed":"251120"},
{"muniType":"Council District","region":"Yonkers City Council 5","ed":"251121"},
{"muniType":"Council District","region":"Yonkers City Council 5","ed":"251202"},
{"muniType":"Council District","region":"Yonkers City Council 5","ed":"251204"},
{"muniType":"Council District","region":"Yonkers City Council 5","ed":"251206"},
{"muniType":"Council District","region":"Yonkers City Council 5","ed":"251207"},
{"muniType":"Council District","region":"Yonkers City Council 5","ed":"251208"},
{"muniType":"Council District","region":"Yonkers City Council 5","ed":"251209"},
{"muniType":"Council District","region":"Yonkers City Council 5","ed":"251212"},
{"muniType":"Council District","region":"Yonkers City Council 5","ed":"251213"},
{"muniType":"Council District","region":"Yonkers City Council 5","ed":"251218"},
{"muniType":"Council District","region":"Yonkers City Council 5","ed":"251219"},
{"muniType":"Council District","region":"Yonkers City Council 2","ed":"250104"},
{"muniType":"Council District","region":"Yonkers City Council 2","ed":"250218"},
{"muniType":"Council District","region":"Yonkers City Council 2","ed":"250402"},
{"muniType":"Council District","region":"Yonkers City Council 2","ed":"250404"},
{"muniType":"Council District","region":"Yonkers City Council 2","ed":"250405"},
{"muniType":"Council District","region":"Yonkers City Council 2","ed":"250406"},
{"muniType":"Council District","region":"Yonkers City Council 2","ed":"250408"},
{"muniType":"Council District","region":"Yonkers City Council 2","ed":"250409"},
{"muniType":"Council District","region":"Yonkers City Council 2","ed":"250411"},
{"muniType":"Council District","region":"Yonkers City Council 2","ed":"250415"},
{"muniType":"Council District","region":"Yonkers City Council 2","ed":"250416"},
{"muniType":"Council District","region":"Yonkers City Council 2","ed":"250417"},
{"muniType":"Council District","region":"Yonkers City Council 2","ed":"250418"},
{"muniType":"Council District","region":"Yonkers City Council 2","ed":"250419"},
{"muniType":"Council District","region":"Yonkers City Council 2","ed":"250702"},
{"muniType":"Council District","region":"Yonkers City Council 2","ed":"250705"},
{"muniType":"Council District","region":"Yonkers City Council 2","ed":"250706"},
{"muniType":"Council District","region":"Yonkers City Council 2","ed":"250707"},
{"muniType":"Council District","region":"Yonkers City Council 2","ed":"250708"},
{"muniType":"Council District","region":"Yonkers City Council 2","ed":"250709"},
{"muniType":"Council District","region":"Yonkers City Council 2","ed":"250711"},
{"muniType":"Council District","region":"Yonkers City Council 2","ed":"250713"},
{"muniType":"Council District","region":"Yonkers City Council 2","ed":"250717"},
{"muniType":"Council District","region":"Yonkers City Council 2","ed":"250723"},
{"muniType":"Council District","region":"Yonkers City Council 2","ed":"250725"},
{"muniType":"Council District","region":"Yonkers City Council 2","ed":"250726"},
{"muniType":"Council District","region":"Yonkers City Council 2","ed":"250729"},
{"muniType":"Council District","region":"Yonkers City Council 2","ed":"250810"},
{"muniType":"Council District","region":"Yonkers City Council 2","ed":"250812"},
{"muniType":"Council District","region":"Yonkers City Council 2","ed":"250814"},
{"muniType":"Council District","region":"Yonkers City Council 2","ed":"250817"},
{"muniType":"Council District","region":"Yonkers City Council 2","ed":"250824"},
{"muniType":"Council District","region":"Yonkers City Council 2","ed":"250826"},
{"muniType":"Council District","region":"Yonkers City Council 4","ed":"250201"},
{"muniType":"Council District","region":"Yonkers City Council 4","ed":"250202"},
{"muniType":"Council District","region":"Yonkers City Council 4","ed":"250203"},
{"muniType":"Council District","region":"Yonkers City Council 4","ed":"250205"},
{"muniType":"Council District","region":"Yonkers City Council 4","ed":"250206"},
{"muniType":"Council District","region":"Yonkers City Council 4","ed":"250208"},
{"muniType":"Council District","region":"Yonkers City Council 4","ed":"250209"},
{"muniType":"Council District","region":"Yonkers City Council 4","ed":"250210"},
{"muniType":"Council District","region":"Yonkers City Council 4","ed":"250211"},
{"muniType":"Council District","region":"Yonkers City Council 4","ed":"250220"},
{"muniType":"Council District","region":"Yonkers City Council 4","ed":"250222"},
{"muniType":"Council District","region":"Yonkers City Council 4","ed":"250223"},
{"muniType":"Council District","region":"Yonkers City Council 4","ed":"250224"},
{"muniType":"Council District","region":"Yonkers City Council 4","ed":"250227"},
{"muniType":"Council District","region":"Yonkers City Council 4","ed":"250806"},
{"muniType":"Council District","region":"Yonkers City Council 4","ed":"250818"},
{"muniType":"Council District","region":"Yonkers City Council 4","ed":"250901"},
{"muniType":"Council District","region":"Yonkers City Council 4","ed":"250902"},
{"muniType":"Council District","region":"Yonkers City Council 4","ed":"250903"},
{"muniType":"Council District","region":"Yonkers City Council 4","ed":"250904"},
{"muniType":"Council District","region":"Yonkers City Council 4","ed":"250905"},
{"muniType":"Council District","region":"Yonkers City Council 4","ed":"250906"},
{"muniType":"Council District","region":"Yonkers City Council 4","ed":"250907"},
{"muniType":"Council District","region":"Yonkers City Council 4","ed":"250908"},
{"muniType":"Council District","region":"Yonkers City Council 4","ed":"250909"},
{"muniType":"Council District","region":"Yonkers City Council 4","ed":"250910"},
{"muniType":"Council District","region":"Yonkers City Council 4","ed":"250911"},
{"muniType":"Council District","region":"Yonkers City Council 4","ed":"250912"},
{"muniType":"Council District","region":"Yonkers City Council 4","ed":"250913"},
{"muniType":"Council District","region":"Yonkers City Council 4","ed":"250915"},
{"muniType":"Council District","region":"Yonkers City Council 4","ed":"250916"},
{"muniType":"Council District","region":"Yonkers City Council 4","ed":"250917"},
{"muniType":"Council District","region":"Yonkers City Council 4","ed":"251201"},
{"muniType":"Council District","region":"Yonkers City Council 4","ed":"251203"},
{"muniType":"Council District","region":"Yonkers City Council 4","ed":"251205"},
{"muniType":"Council District","region":"Yonkers City Council 4","ed":"251210"},
{"muniType":"Council District","region":"Yonkers City Council 4","ed":"251211"},
{"muniType":"Council District","region":"Yonkers City Council 4","ed":"251215"},
{"muniType":"Council District","region":"Yonkers City Council 4","ed":"251216"},
{"muniType":"Council District","region":"Yonkers City Council 4","ed":"251217"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"250204"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"250212"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"250216"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"250314"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"250319"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"250502"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"250503"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"250504"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"250507"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"250510"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"250511"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"250516"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"250517"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"250518"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"250519"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"250520"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"250522"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"250523"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"250524"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"250607"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"250608"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"250618"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"250704"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"250714"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"250715"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"250716"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"250719"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"250720"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"250730"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"250731"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"251002"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"251003"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"251004"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"251005"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"251006"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"251007"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"251008"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"251009"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"251011"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"251013"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"251014"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"251016"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"251018"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"251020"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"251021"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"251022"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"251025"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"251106"},
{"muniType":"Council District","region":"Yonkers City Council 6","ed":"251109"},
{"muniType":"Village","region":"Ardsley","ed":"040024"},
{"muniType":"Village","region":"Ardsley","ed":"040025"},
{"muniType":"Village","region":"Ardsley","ed":"040053"},
{"muniType":"Village","region":"Ardsley","ed":"040054"},
{"muniType":"Village","region":"Briarcliff","ed":"090017"},
{"muniType":"Village","region":"Briarcliff","ed":"130015"},
{"muniType":"Village","region":"Briarcliff","ed":"130016"},
{"muniType":"Village","region":"Briarcliff","ed":"130019"},
{"muniType":"Village","region":"Briarcliff","ed":"130026"},
{"muniType":"Village","region":"Briarcliff","ed":"130027"},
{"muniType":"Village","region":"Briarcliff","ed":"130029"},
{"muniType":"Village","region":"Bronxville","ed":"030016"},
{"muniType":"Village","region":"Bronxville","ed":"030017"},
{"muniType":"Village","region":"Bronxville","ed":"030018"},
{"muniType":"Village","region":"Bronxville","ed":"030019"},
{"muniType":"Village","region":"Bronxville","ed":"030020"},
{"muniType":"Village","region":"Bronxville","ed":"030021"},
{"muniType":"Village","region":"Bronxville","ed":"030022"},
{"muniType":"Village","region":"Buchanan","ed":"020001"},
{"muniType":"Village","region":"Buchanan","ed":"020002"},
{"muniType":"Village","region":"Croton","ed":"020006"},
{"muniType":"Village","region":"Croton","ed":"020007"},
{"muniType":"Village","region":"Croton","ed":"020008"},
{"muniType":"Village","region":"Croton","ed":"020009"},
{"muniType":"Village","region":"Croton","ed":"020010"},
{"muniType":"Village","region":"Croton","ed":"020025"},
{"muniType":"Village","region":"Croton","ed":"020031"},
{"muniType":"Village","region":"Croton","ed":"020039"},
{"muniType":"Village","region":"Dobbs Ferry","ed":"040012"},
{"muniType":"Village","region":"Dobbs Ferry","ed":"040013"},
{"muniType":"Village","region":"Dobbs Ferry","ed":"040014"},
{"muniType":"Village","region":"Dobbs Ferry","ed":"040015"},
{"muniType":"Village","region":"Dobbs Ferry","ed":"040016"},
{"muniType":"Village","region":"Dobbs Ferry","ed":"040017"},
{"muniType":"Village","region":"Dobbs Ferry","ed":"040058"},
{"muniType":"Village","region":"Dobbs Ferry","ed":"040065"},
{"muniType":"Village","region":"Dobbs Ferry","ed":"040080"},
{"muniType":"Village","region":"Elmsford","ed":"040026"},
{"muniType":"Village","region":"Elmsford","ed":"040027"},
{"muniType":"Village","region":"Elmsford","ed":"040028"},
{"muniType":"Village","region":"Hastings","ed":"040018"},
{"muniType":"Village","region":"Hastings","ed":"040019"},
{"muniType":"Village","region":"Hastings","ed":"040020"},
{"muniType":"Village","region":"Hastings","ed":"040021"},
{"muniType":"Village","region":"Hastings","ed":"040022"},
{"muniType":"Village","region":"Hastings","ed":"040023"},
{"muniType":"Village","region":"Hastings","ed":"040052"},
{"muniType":"Village","region":"Hastings","ed":"040059"},
{"muniType":"Village","region":"Irvington","ed":"040008"},
{"muniType":"Village","region":"Irvington","ed":"040009"},
{"muniType":"Village","region":"Irvington","ed":"040010"},
{"muniType":"Village","region":"Irvington","ed":"040011"},
{"muniType":"Village","region":"Irvington","ed":"040060"},
{"muniType":"Village","region":"Irvington","ed":"040079"},
{"muniType":"Village","region":"Larchmont","ed":"070006"},
{"muniType":"Village","region":"Larchmont","ed":"070007"},
{"muniType":"Village","region":"Larchmont","ed":"070008"},
{"muniType":"Village","region":"Larchmont","ed":"070009"},
{"muniType":"Village","region":"Larchmont","ed":"070010"},
{"muniType":"Village","region":"Larchmont","ed":"070025"},
{"muniType":"Village","region":"Larchmont","ed":"070026"},
{"muniType":"Village","region":"Mamaroneck Village","ed":"070001"},
{"muniType":"Village","region":"Mamaroneck Village","ed":"070002"},
{"muniType":"Village","region":"Mamaroneck Village","ed":"070003"},
{"muniType":"Village","region":"Mamaroneck Village","ed":"070004"},
{"muniType":"Village","region":"Mamaroneck Village","ed":"070005"},
{"muniType":"Village","region":"Mamaroneck Village","ed":"070017"},
{"muniType":"Village","region":"Mamaroneck Village","ed":"070022"},
{"muniType":"Village","region":"Mamaroneck Village","ed":"070023"},
{"muniType":"Village","region":"Mamaroneck Village","ed":"070027"},
{"muniType":"Village","region":"Mamaroneck Village","ed":"160001"},
{"muniType":"Village","region":"Mamaroneck Village","ed":"160002"},
{"muniType":"Village","region":"Mamaroneck Village","ed":"160003"},
{"muniType":"Village","region":"Mamaroneck Village","ed":"160004"},
{"muniType":"Village","region":"Mamaroneck Village","ed":"160024"},
{"muniType":"Village","region":"Mamaroneck Village","ed":"160028"},
{"muniType":"Village","region":"Ossining Village","ed":"130001"},
{"muniType":"Village","region":"Ossining Village","ed":"130002"},
{"muniType":"Village","region":"Ossining Village","ed":"130003"},
{"muniType":"Village","region":"Ossining Village","ed":"130004"},
{"muniType":"Village","region":"Ossining Village","ed":"130005"},
{"muniType":"Village","region":"Ossining Village","ed":"130006"},
{"muniType":"Village","region":"Ossining Village","ed":"130007"},
{"muniType":"Village","region":"Ossining Village","ed":"130008"},
{"muniType":"Village","region":"Ossining Village","ed":"130009"},
{"muniType":"Village","region":"Ossining Village","ed":"130010"},
{"muniType":"Village","region":"Ossining Village","ed":"130011"},
{"muniType":"Village","region":"Ossining Village","ed":"130012"},
{"muniType":"Village","region":"Ossining Village","ed":"130018"},
{"muniType":"Village","region":"Ossining Village","ed":"130021"},
{"muniType":"Village","region":"Ossining Village","ed":"130022"},
{"muniType":"Village","region":"Ossining Village","ed":"130023"},
{"muniType":"Village","region":"Ossining Village","ed":"130030"},
{"muniType":"Village","region":"Pelham Village","ed":"140001"},
{"muniType":"Village","region":"Pelham Village","ed":"140002"},
{"muniType":"Village","region":"Pelham Village","ed":"140003"},
{"muniType":"Village","region":"Pelham Village","ed":"140004"},
{"muniType":"Village","region":"Pelham Village","ed":"140005"},
{"muniType":"Village","region":"Pelham Manor","ed":"140006"},
{"muniType":"Village","region":"Pelham Manor","ed":"140007"},
{"muniType":"Village","region":"Pelham Manor","ed":"140008"},
{"muniType":"Village","region":"Pelham Manor","ed":"140009"},
{"muniType":"Village","region":"Pelham Manor","ed":"140010"},
{"muniType":"Village","region":"Pleasantville","ed":"090013"},
{"muniType":"Village","region":"Pleasantville","ed":"090014"},
{"muniType":"Village","region":"Pleasantville","ed":"090015"},
{"muniType":"Village","region":"Pleasantville","ed":"090020"},
{"muniType":"Village","region":"Pleasantville","ed":"090031"},
{"muniType":"Village","region":"Pleasantville","ed":"090043"},
{"muniType":"Village","region":"Port Chester","ed":"160005"},
{"muniType":"Village","region":"Port Chester","ed":"160006"},
{"muniType":"Village","region":"Port Chester","ed":"160007"},
{"muniType":"Village","region":"Port Chester","ed":"160008"},
{"muniType":"Village","region":"Port Chester","ed":"160009"},
{"muniType":"Village","region":"Port Chester","ed":"160010"},
{"muniType":"Village","region":"Port Chester","ed":"160011"},
{"muniType":"Village","region":"Port Chester","ed":"160012"},
{"muniType":"Village","region":"Port Chester","ed":"160013"},
{"muniType":"Village","region":"Port Chester","ed":"160014"},
{"muniType":"Village","region":"Port Chester","ed":"160015"},
{"muniType":"Village","region":"Port Chester","ed":"160016"},
{"muniType":"Village","region":"Port Chester","ed":"160017"},
{"muniType":"Village","region":"Port Chester","ed":"160018"},
{"muniType":"Village","region":"Port Chester","ed":"160019"},
{"muniType":"Village","region":"Port Chester","ed":"160025"},
{"muniType":"Village","region":"Rye Brook","ed":"160020"},
{"muniType":"Village","region":"Rye Brook","ed":"160021"},
{"muniType":"Village","region":"Rye Brook","ed":"160022"},
{"muniType":"Village","region":"Rye Brook","ed":"160023"},
{"muniType":"Village","region":"Rye Brook","ed":"160026"},
{"muniType":"Village","region":"Rye Brook","ed":"160027"},
{"muniType":"Village","region":"Rye Brook","ed":"160029"},
{"muniType":"Village","region":"Rye Brook","ed":"160030"},
{"muniType":"Village","region":"Rye Brook","ed":"160031"},
{"muniType":"Village","region":"Sleepy Hollow","ed":"090001"},
{"muniType":"Village","region":"Sleepy Hollow","ed":"090002"},
{"muniType":"Village","region":"Sleepy Hollow","ed":"090003"},
{"muniType":"Village","region":"Sleepy Hollow","ed":"090004"},
{"muniType":"Village","region":"Sleepy Hollow","ed":"090005"},
{"muniType":"Village","region":"Sleepy Hollow","ed":"090006"},
{"muniType":"Village","region":"Sleepy Hollow","ed":"090007"},
{"muniType":"Village","region":"Sleepy Hollow","ed":"090033"},
{"muniType":"Village","region":"Tarrytown","ed":"040001"},
{"muniType":"Village","region":"Tarrytown","ed":"040002"},
{"muniType":"Village","region":"Tarrytown","ed":"040003"},
{"muniType":"Village","region":"Tarrytown","ed":"040004"},
{"muniType":"Village","region":"Tarrytown","ed":"040005"},
{"muniType":"Village","region":"Tarrytown","ed":"040006"},
{"muniType":"Village","region":"Tarrytown","ed":"040007"},
{"muniType":"Village","region":"Tarrytown","ed":"040055"},
{"muniType":"Village","region":"Tarrytown","ed":"040074"},
{"muniType":"Village","region":"Tarrytown","ed":"040076"},
{"muniType":"Village","region":"Tuckahoe","ed":"030010"},
{"muniType":"Village","region":"Tuckahoe","ed":"030011"},
{"muniType":"Village","region":"Tuckahoe","ed":"030012"},
{"muniType":"Village","region":"Tuckahoe","ed":"030013"},
{"muniType":"Village","region":"Tuckahoe","ed":"030014"},
{"muniType":"Village","region":"Tuckahoe","ed":"030032"},
{"muniType":"Village","region":"Tuckahoe","ed":"030033"},
{"muniType":"Congress","region":"Congress CD 16","ed":"030001"},
{"muniType":"Congress","region":"Congress CD 16","ed":"030002"},
{"muniType":"Congress","region":"Congress CD 16","ed":"030003"},
{"muniType":"Congress","region":"Congress CD 16","ed":"030004"},
{"muniType":"Congress","region":"Congress CD 16","ed":"030005"},
{"muniType":"Congress","region":"Congress CD 16","ed":"030006"},
{"muniType":"Congress","region":"Congress CD 16","ed":"030007"},
{"muniType":"Congress","region":"Congress CD 16","ed":"030008"},
{"muniType":"Congress","region":"Congress CD 16","ed":"030009"},
{"muniType":"Congress","region":"Congress CD 16","ed":"030010"},
{"muniType":"Congress","region":"Congress CD 16","ed":"030011"},
{"muniType":"Congress","region":"Congress CD 16","ed":"030012"},
{"muniType":"Congress","region":"Congress CD 16","ed":"030013"},
{"muniType":"Congress","region":"Congress CD 16","ed":"030014"},
{"muniType":"Congress","region":"Congress CD 16","ed":"030015"},
{"muniType":"Congress","region":"Congress CD 16","ed":"030016"},
{"muniType":"Congress","region":"Congress CD 16","ed":"030017"},
{"muniType":"Congress","region":"Congress CD 16","ed":"030018"},
{"muniType":"Congress","region":"Congress CD 16","ed":"030019"},
{"muniType":"Congress","region":"Congress CD 16","ed":"030020"},
{"muniType":"Congress","region":"Congress CD 16","ed":"030021"},
{"muniType":"Congress","region":"Congress CD 16","ed":"030022"},
{"muniType":"Congress","region":"Congress CD 16","ed":"030023"},
{"muniType":"Congress","region":"Congress CD 16","ed":"030024"},
{"muniType":"Congress","region":"Congress CD 16","ed":"030025"},
{"muniType":"Congress","region":"Congress CD 16","ed":"030026"},
{"muniType":"Congress","region":"Congress CD 16","ed":"030027"},
{"muniType":"Congress","region":"Congress CD 16","ed":"030028"},
{"muniType":"Congress","region":"Congress CD 16","ed":"030029"},
{"muniType":"Congress","region":"Congress CD 16","ed":"030030"},
{"muniType":"Congress","region":"Congress CD 16","ed":"030031"},
{"muniType":"Congress","region":"Congress CD 16","ed":"030032"},
{"muniType":"Congress","region":"Congress CD 16","ed":"030033"},
{"muniType":"Congress","region":"Congress CD 16","ed":"030034"},
{"muniType":"Congress","region":"Congress CD 16","ed":"030035"},
{"muniType":"Congress","region":"Congress CD 16","ed":"030036"},
{"muniType":"Congress","region":"Congress CD 16","ed":"030037"},
{"muniType":"Congress","region":"Congress CD 16","ed":"030038"},
{"muniType":"Congress","region":"Congress CD 16","ed":"030039"},
{"muniType":"Congress","region":"Congress CD 16","ed":"040018"},
{"muniType":"Congress","region":"Congress CD 16","ed":"040019"},
{"muniType":"Congress","region":"Congress CD 16","ed":"040020"},
{"muniType":"Congress","region":"Congress CD 16","ed":"040021"},
{"muniType":"Congress","region":"Congress CD 16","ed":"040022"},
{"muniType":"Congress","region":"Congress CD 16","ed":"040023"},
{"muniType":"Congress","region":"Congress CD 16","ed":"040024"},
{"muniType":"Congress","region":"Congress CD 16","ed":"040025"},
{"muniType":"Congress","region":"Congress CD 16","ed":"040032"},
{"muniType":"Congress","region":"Congress CD 16","ed":"040033"},
{"muniType":"Congress","region":"Congress CD 16","ed":"040034"},
{"muniType":"Congress","region":"Congress CD 16","ed":"040035"},
{"muniType":"Congress","region":"Congress CD 16","ed":"040049"},
{"muniType":"Congress","region":"Congress CD 16","ed":"040052"},
{"muniType":"Congress","region":"Congress CD 16","ed":"040053"},
{"muniType":"Congress","region":"Congress CD 16","ed":"040054"},
{"muniType":"Congress","region":"Congress CD 16","ed":"040059"},
{"muniType":"Congress","region":"Congress CD 16","ed":"040069"},
{"muniType":"Congress","region":"Congress CD 16","ed":"040073"},
{"muniType":"Congress","region":"Congress CD 16","ed":"070001"},
{"muniType":"Congress","region":"Congress CD 16","ed":"070002"},
{"muniType":"Congress","region":"Congress CD 16","ed":"070003"},
{"muniType":"Congress","region":"Congress CD 16","ed":"070004"},
{"muniType":"Congress","region":"Congress CD 16","ed":"070005"},
{"muniType":"Congress","region":"Congress CD 16","ed":"070006"},
{"muniType":"Congress","region":"Congress CD 16","ed":"070007"},
{"muniType":"Congress","region":"Congress CD 16","ed":"070008"},
{"muniType":"Congress","region":"Congress CD 16","ed":"070009"},
{"muniType":"Congress","region":"Congress CD 16","ed":"070010"},
{"muniType":"Congress","region":"Congress CD 16","ed":"070011"},
{"muniType":"Congress","region":"Congress CD 16","ed":"070012"},
{"muniType":"Congress","region":"Congress CD 16","ed":"070013"},
{"muniType":"Congress","region":"Congress CD 16","ed":"070014"},
{"muniType":"Congress","region":"Congress CD 16","ed":"070015"},
{"muniType":"Congress","region":"Congress CD 16","ed":"070016"},
{"muniType":"Congress","region":"Congress CD 16","ed":"070017"},
{"muniType":"Congress","region":"Congress CD 16","ed":"070018"},
{"muniType":"Congress","region":"Congress CD 16","ed":"070019"},
{"muniType":"Congress","region":"Congress CD 16","ed":"070020"},
{"muniType":"Congress","region":"Congress CD 16","ed":"070021"},
{"muniType":"Congress","region":"Congress CD 16","ed":"070022"},
{"muniType":"Congress","region":"Congress CD 16","ed":"070023"},
{"muniType":"Congress","region":"Congress CD 16","ed":"070024"},
{"muniType":"Congress","region":"Congress CD 16","ed":"070025"},
{"muniType":"Congress","region":"Congress CD 16","ed":"070026"},
{"muniType":"Congress","region":"Congress CD 16","ed":"070027"},
{"muniType":"Congress","region":"Congress CD 16","ed":"070028"},
{"muniType":"Congress","region":"Congress CD 16","ed":"140001"},
{"muniType":"Congress","region":"Congress CD 16","ed":"140002"},
{"muniType":"Congress","region":"Congress CD 16","ed":"140003"},
{"muniType":"Congress","region":"Congress CD 16","ed":"140004"},
{"muniType":"Congress","region":"Congress CD 16","ed":"140005"},
{"muniType":"Congress","region":"Congress CD 16","ed":"140006"},
{"muniType":"Congress","region":"Congress CD 16","ed":"140007"},
{"muniType":"Congress","region":"Congress CD 16","ed":"140008"},
{"muniType":"Congress","region":"Congress CD 16","ed":"140009"},
{"muniType":"Congress","region":"Congress CD 16","ed":"140010"},
{"muniType":"Congress","region":"Congress CD 16","ed":"160001"},
{"muniType":"Congress","region":"Congress CD 16","ed":"160002"},
{"muniType":"Congress","region":"Congress CD 16","ed":"160003"},
{"muniType":"Congress","region":"Congress CD 16","ed":"160004"},
{"muniType":"Congress","region":"Congress CD 16","ed":"160024"},
{"muniType":"Congress","region":"Congress CD 16","ed":"160028"},
{"muniType":"Congress","region":"Congress CD 16","ed":"170001"},
{"muniType":"Congress","region":"Congress CD 16","ed":"170002"},
{"muniType":"Congress","region":"Congress CD 16","ed":"170003"},
{"muniType":"Congress","region":"Congress CD 16","ed":"170004"},
{"muniType":"Congress","region":"Congress CD 16","ed":"170005"},
{"muniType":"Congress","region":"Congress CD 16","ed":"170006"},
{"muniType":"Congress","region":"Congress CD 16","ed":"170007"},
{"muniType":"Congress","region":"Congress CD 16","ed":"170008"},
{"muniType":"Congress","region":"Congress CD 16","ed":"170009"},
{"muniType":"Congress","region":"Congress CD 16","ed":"170010"},
{"muniType":"Congress","region":"Congress CD 16","ed":"170011"},
{"muniType":"Congress","region":"Congress CD 16","ed":"170012"},
{"muniType":"Congress","region":"Congress CD 16","ed":"170013"},
{"muniType":"Congress","region":"Congress CD 16","ed":"170014"},
{"muniType":"Congress","region":"Congress CD 16","ed":"170015"},
{"muniType":"Congress","region":"Congress CD 16","ed":"170016"},
{"muniType":"Congress","region":"Congress CD 16","ed":"170017"},
{"muniType":"Congress","region":"Congress CD 16","ed":"170018"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200001"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200002"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200003"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200004"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200005"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200006"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200007"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200008"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200009"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200010"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200011"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200012"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200013"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200014"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200015"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200016"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200017"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200018"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200019"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200020"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200021"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200022"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200023"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200024"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200025"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200026"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200027"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200028"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200029"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200030"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200031"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200032"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200033"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200034"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200035"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200036"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200037"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200038"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200039"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200040"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200041"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200042"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200043"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200044"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200045"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200046"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200047"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200048"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200049"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200050"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200051"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200052"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200053"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200054"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200055"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200056"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200057"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200058"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200059"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200060"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200061"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200062"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200064"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200065"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200066"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200067"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200068"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200069"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200070"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200071"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200072"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200073"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200074"},
{"muniType":"Congress","region":"Congress CD 16","ed":"200076"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210001"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210002"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210003"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210004"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210005"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210006"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210007"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210008"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210009"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210010"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210011"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210012"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210013"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210014"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210015"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210016"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210018"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210019"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210021"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210022"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210023"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210024"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210025"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210026"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210027"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210028"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210029"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210030"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210031"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210032"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210033"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210034"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210035"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210036"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210037"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210038"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210039"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210040"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210041"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210042"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210043"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210044"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210045"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210046"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210047"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210048"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210049"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210050"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210051"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210052"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210053"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210054"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210055"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210056"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210057"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210058"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210059"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210060"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210061"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210063"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210064"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210065"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210066"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210067"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210068"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210069"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210070"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210071"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210072"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210073"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210074"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210075"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210076"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210077"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210078"},
{"muniType":"Congress","region":"Congress CD 16","ed":"210079"},
{"muniType":"Congress","region":"Congress CD 16","ed":"230001"},
{"muniType":"Congress","region":"Congress CD 16","ed":"230002"},
{"muniType":"Congress","region":"Congress CD 16","ed":"230003"},
{"muniType":"Congress","region":"Congress CD 16","ed":"230004"},
{"muniType":"Congress","region":"Congress CD 16","ed":"230005"},
{"muniType":"Congress","region":"Congress CD 16","ed":"230006"},
{"muniType":"Congress","region":"Congress CD 16","ed":"230007"},
{"muniType":"Congress","region":"Congress CD 16","ed":"230008"},
{"muniType":"Congress","region":"Congress CD 16","ed":"230009"},
{"muniType":"Congress","region":"Congress CD 16","ed":"230010"},
{"muniType":"Congress","region":"Congress CD 16","ed":"230011"},
{"muniType":"Congress","region":"Congress CD 16","ed":"230012"},
{"muniType":"Congress","region":"Congress CD 16","ed":"230013"},
{"muniType":"Congress","region":"Congress CD 16","ed":"230014"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250101"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250102"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250103"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250104"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250105"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250106"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250107"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250108"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250109"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250110"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250111"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250112"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250118"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250122"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250126"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250201"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250202"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250203"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250204"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250205"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250206"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250207"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250208"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250209"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250210"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250211"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250212"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250213"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250214"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250216"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250218"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250220"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250222"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250223"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250224"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250226"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250229"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250301"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250302"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250303"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250304"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250305"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250306"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250307"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250308"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250310"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250311"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250312"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250313"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250315"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250316"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250317"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250319"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250320"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250321"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250323"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250324"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250325"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250326"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250327"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250328"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250329"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250401"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250402"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250403"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250404"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250405"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250406"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250407"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250408"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250409"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250410"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250411"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250412"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250415"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250416"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250417"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250418"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250419"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250421"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250501"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250502"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250503"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250504"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250505"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250506"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250507"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250508"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250509"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250510"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250511"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250512"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250513"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250514"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250515"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250516"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250517"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250518"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250519"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250520"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250522"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250523"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250524"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250601"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250602"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250603"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250604"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250606"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250607"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250608"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250610"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250611"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250612"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250613"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250614"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250616"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250617"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250618"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250620"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250621"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250622"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250624"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250628"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250632"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250633"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250701"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250702"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250703"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250704"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250705"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250706"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250707"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250708"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250709"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250711"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250713"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250714"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250715"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250716"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250717"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250719"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250720"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250721"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250723"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250724"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250725"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250726"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250729"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250730"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250731"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250801"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250802"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250803"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250805"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250806"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250807"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250809"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250810"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250811"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250812"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250813"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250814"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250815"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250817"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250822"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250823"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250824"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250826"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250828"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250901"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250902"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250903"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250904"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250905"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250906"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250907"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250908"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250909"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250910"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250911"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250912"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250913"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250915"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250916"},
{"muniType":"Congress","region":"Congress CD 16","ed":"250917"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251001"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251002"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251003"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251004"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251005"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251006"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251007"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251008"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251009"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251011"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251012"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251013"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251014"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251015"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251016"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251017"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251018"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251020"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251021"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251025"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251101"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251102"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251103"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251104"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251105"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251106"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251107"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251108"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251109"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251110"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251111"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251112"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251113"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251114"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251116"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251117"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251118"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251119"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251120"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251121"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251201"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251202"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251203"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251204"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251205"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251206"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251207"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251208"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251209"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251210"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251211"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251212"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251213"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251215"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251216"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251217"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251218"},
{"muniType":"Congress","region":"Congress CD 16","ed":"251219"},
{"muniType":"Congress","region":"Congress CD 17","ed":"020001"},
{"muniType":"Congress","region":"Congress CD 17","ed":"020002"},
{"muniType":"Congress","region":"Congress CD 17","ed":"020003"},
{"muniType":"Congress","region":"Congress CD 17","ed":"020004"},
{"muniType":"Congress","region":"Congress CD 17","ed":"020005"},
{"muniType":"Congress","region":"Congress CD 17","ed":"020006"},
{"muniType":"Congress","region":"Congress CD 17","ed":"020007"},
{"muniType":"Congress","region":"Congress CD 17","ed":"020008"},
{"muniType":"Congress","region":"Congress CD 17","ed":"020009"},
{"muniType":"Congress","region":"Congress CD 17","ed":"020010"},
{"muniType":"Congress","region":"Congress CD 17","ed":"020011"},
{"muniType":"Congress","region":"Congress CD 17","ed":"020012"},
{"muniType":"Congress","region":"Congress CD 17","ed":"020013"},
{"muniType":"Congress","region":"Congress CD 17","ed":"020014"},
{"muniType":"Congress","region":"Congress CD 17","ed":"020015"},
{"muniType":"Congress","region":"Congress CD 17","ed":"020016"},
{"muniType":"Congress","region":"Congress CD 17","ed":"020017"},
{"muniType":"Congress","region":"Congress CD 17","ed":"020018"},
{"muniType":"Congress","region":"Congress CD 17","ed":"020019"},
{"muniType":"Congress","region":"Congress CD 17","ed":"020020"},
{"muniType":"Congress","region":"Congress CD 17","ed":"020021"},
{"muniType":"Congress","region":"Congress CD 17","ed":"020022"},
{"muniType":"Congress","region":"Congress CD 17","ed":"020023"},
{"muniType":"Congress","region":"Congress CD 17","ed":"020024"},
{"muniType":"Congress","region":"Congress CD 17","ed":"020025"},
{"muniType":"Congress","region":"Congress CD 17","ed":"020026"},
{"muniType":"Congress","region":"Congress CD 17","ed":"020027"},
{"muniType":"Congress","region":"Congress CD 17","ed":"020028"},
{"muniType":"Congress","region":"Congress CD 17","ed":"020029"},
{"muniType":"Congress","region":"Congress CD 17","ed":"020030"},
{"muniType":"Congress","region":"Congress CD 17","ed":"020031"},
{"muniType":"Congress","region":"Congress CD 17","ed":"020032"},
{"muniType":"Congress","region":"Congress CD 17","ed":"020033"},
{"muniType":"Congress","region":"Congress CD 17","ed":"020034"},
{"muniType":"Congress","region":"Congress CD 17","ed":"020035"},
{"muniType":"Congress","region":"Congress CD 17","ed":"020038"},
{"muniType":"Congress","region":"Congress CD 17","ed":"020039"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040001"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040002"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040003"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040004"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040005"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040006"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040007"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040008"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040009"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040010"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040011"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040012"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040013"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040014"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040015"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040016"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040017"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040026"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040027"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040028"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040029"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040030"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040031"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040036"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040037"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040038"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040039"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040040"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040041"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040042"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040043"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040044"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040045"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040046"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040047"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040048"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040050"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040051"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040055"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040056"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040057"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040058"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040060"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040061"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040062"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040063"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040064"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040065"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040066"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040067"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040068"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040070"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040071"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040072"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040074"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040075"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040076"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040077"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040078"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040079"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040080"},
{"muniType":"Congress","region":"Congress CD 17","ed":"040081"},
{"muniType":"Congress","region":"Congress CD 17","ed":"050001"},
{"muniType":"Congress","region":"Congress CD 17","ed":"050002"},
{"muniType":"Congress","region":"Congress CD 17","ed":"050003"},
{"muniType":"Congress","region":"Congress CD 17","ed":"050004"},
{"muniType":"Congress","region":"Congress CD 17","ed":"050005"},
{"muniType":"Congress","region":"Congress CD 17","ed":"050006"},
{"muniType":"Congress","region":"Congress CD 17","ed":"050007"},
{"muniType":"Congress","region":"Congress CD 17","ed":"050008"},
{"muniType":"Congress","region":"Congress CD 17","ed":"050009"},
{"muniType":"Congress","region":"Congress CD 17","ed":"050010"},
{"muniType":"Congress","region":"Congress CD 17","ed":"050011"},
{"muniType":"Congress","region":"Congress CD 17","ed":"050012"},
{"muniType":"Congress","region":"Congress CD 17","ed":"050013"},
{"muniType":"Congress","region":"Congress CD 17","ed":"050014"},
{"muniType":"Congress","region":"Congress CD 17","ed":"050015"},
{"muniType":"Congress","region":"Congress CD 17","ed":"050016"},
{"muniType":"Congress","region":"Congress CD 17","ed":"050017"},
{"muniType":"Congress","region":"Congress CD 17","ed":"050018"},
{"muniType":"Congress","region":"Congress CD 17","ed":"050019"},
{"muniType":"Congress","region":"Congress CD 17","ed":"050020"},
{"muniType":"Congress","region":"Congress CD 17","ed":"080001"},
{"muniType":"Congress","region":"Congress CD 17","ed":"080002"},
{"muniType":"Congress","region":"Congress CD 17","ed":"080003"},
{"muniType":"Congress","region":"Congress CD 17","ed":"080004"},
{"muniType":"Congress","region":"Congress CD 17","ed":"080005"},
{"muniType":"Congress","region":"Congress CD 17","ed":"080006"},
{"muniType":"Congress","region":"Congress CD 17","ed":"080007"},
{"muniType":"Congress","region":"Congress CD 17","ed":"090001"},
{"muniType":"Congress","region":"Congress CD 17","ed":"090002"},
{"muniType":"Congress","region":"Congress CD 17","ed":"090003"},
{"muniType":"Congress","region":"Congress CD 17","ed":"090004"},
{"muniType":"Congress","region":"Congress CD 17","ed":"090005"},
{"muniType":"Congress","region":"Congress CD 17","ed":"090006"},
{"muniType":"Congress","region":"Congress CD 17","ed":"090007"},
{"muniType":"Congress","region":"Congress CD 17","ed":"090009"},
{"muniType":"Congress","region":"Congress CD 17","ed":"090010"},
{"muniType":"Congress","region":"Congress CD 17","ed":"090011"},
{"muniType":"Congress","region":"Congress CD 17","ed":"090012"},
{"muniType":"Congress","region":"Congress CD 17","ed":"090013"},
{"muniType":"Congress","region":"Congress CD 17","ed":"090014"},
{"muniType":"Congress","region":"Congress CD 17","ed":"090015"},
{"muniType":"Congress","region":"Congress CD 17","ed":"090016"},
{"muniType":"Congress","region":"Congress CD 17","ed":"090017"},
{"muniType":"Congress","region":"Congress CD 17","ed":"090018"},
{"muniType":"Congress","region":"Congress CD 17","ed":"090019"},
{"muniType":"Congress","region":"Congress CD 17","ed":"090020"},
{"muniType":"Congress","region":"Congress CD 17","ed":"090022"},
{"muniType":"Congress","region":"Congress CD 17","ed":"090023"},
{"muniType":"Congress","region":"Congress CD 17","ed":"090024"},
{"muniType":"Congress","region":"Congress CD 17","ed":"090025"},
{"muniType":"Congress","region":"Congress CD 17","ed":"090026"},
{"muniType":"Congress","region":"Congress CD 17","ed":"090027"},
{"muniType":"Congress","region":"Congress CD 17","ed":"090028"},
{"muniType":"Congress","region":"Congress CD 17","ed":"090029"},
{"muniType":"Congress","region":"Congress CD 17","ed":"090030"},
{"muniType":"Congress","region":"Congress CD 17","ed":"090031"},
{"muniType":"Congress","region":"Congress CD 17","ed":"090032"},
{"muniType":"Congress","region":"Congress CD 17","ed":"090033"},
{"muniType":"Congress","region":"Congress CD 17","ed":"090034"},
{"muniType":"Congress","region":"Congress CD 17","ed":"090035"},
{"muniType":"Congress","region":"Congress CD 17","ed":"090036"},
{"muniType":"Congress","region":"Congress CD 17","ed":"090037"},
{"muniType":"Congress","region":"Congress CD 17","ed":"090038"},
{"muniType":"Congress","region":"Congress CD 17","ed":"090039"},
{"muniType":"Congress","region":"Congress CD 17","ed":"090040"},
{"muniType":"Congress","region":"Congress CD 17","ed":"090041"},
{"muniType":"Congress","region":"Congress CD 17","ed":"090042"},
{"muniType":"Congress","region":"Congress CD 17","ed":"090043"},
{"muniType":"Congress","region":"Congress CD 17","ed":"090044"},
{"muniType":"Congress","region":"Congress CD 17","ed":"100001"},
{"muniType":"Congress","region":"Congress CD 17","ed":"100002"},
{"muniType":"Congress","region":"Congress CD 17","ed":"100003"},
{"muniType":"Congress","region":"Congress CD 17","ed":"100004"},
{"muniType":"Congress","region":"Congress CD 17","ed":"100005"},
{"muniType":"Congress","region":"Congress CD 17","ed":"100006"},
{"muniType":"Congress","region":"Congress CD 17","ed":"100008"},
{"muniType":"Congress","region":"Congress CD 17","ed":"100010"},
{"muniType":"Congress","region":"Congress CD 17","ed":"100011"},
{"muniType":"Congress","region":"Congress CD 17","ed":"100012"},
{"muniType":"Congress","region":"Congress CD 17","ed":"100013"},
{"muniType":"Congress","region":"Congress CD 17","ed":"100014"},
{"muniType":"Congress","region":"Congress CD 17","ed":"100015"},
{"muniType":"Congress","region":"Congress CD 17","ed":"100016"},
{"muniType":"Congress","region":"Congress CD 17","ed":"110002"},
{"muniType":"Congress","region":"Congress CD 17","ed":"110003"},
{"muniType":"Congress","region":"Congress CD 17","ed":"110004"},
{"muniType":"Congress","region":"Congress CD 17","ed":"110005"},
{"muniType":"Congress","region":"Congress CD 17","ed":"110008"},
{"muniType":"Congress","region":"Congress CD 17","ed":"110010"},
{"muniType":"Congress","region":"Congress CD 17","ed":"130001"},
{"muniType":"Congress","region":"Congress CD 17","ed":"130002"},
{"muniType":"Congress","region":"Congress CD 17","ed":"130003"},
{"muniType":"Congress","region":"Congress CD 17","ed":"130004"},
{"muniType":"Congress","region":"Congress CD 17","ed":"130005"},
{"muniType":"Congress","region":"Congress CD 17","ed":"130006"},
{"muniType":"Congress","region":"Congress CD 17","ed":"130007"},
{"muniType":"Congress","region":"Congress CD 17","ed":"130008"},
{"muniType":"Congress","region":"Congress CD 17","ed":"130009"},
{"muniType":"Congress","region":"Congress CD 17","ed":"130010"},
{"muniType":"Congress","region":"Congress CD 17","ed":"130011"},
{"muniType":"Congress","region":"Congress CD 17","ed":"130012"},
{"muniType":"Congress","region":"Congress CD 17","ed":"130013"},
{"muniType":"Congress","region":"Congress CD 17","ed":"130014"},
{"muniType":"Congress","region":"Congress CD 17","ed":"130015"},
{"muniType":"Congress","region":"Congress CD 17","ed":"130016"},
{"muniType":"Congress","region":"Congress CD 17","ed":"130017"},
{"muniType":"Congress","region":"Congress CD 17","ed":"130018"},
{"muniType":"Congress","region":"Congress CD 17","ed":"130019"},
{"muniType":"Congress","region":"Congress CD 17","ed":"130020"},
{"muniType":"Congress","region":"Congress CD 17","ed":"130021"},
{"muniType":"Congress","region":"Congress CD 17","ed":"130022"},
{"muniType":"Congress","region":"Congress CD 17","ed":"130023"},
{"muniType":"Congress","region":"Congress CD 17","ed":"130024"},
{"muniType":"Congress","region":"Congress CD 17","ed":"130025"},
{"muniType":"Congress","region":"Congress CD 17","ed":"130026"},
{"muniType":"Congress","region":"Congress CD 17","ed":"130027"},
{"muniType":"Congress","region":"Congress CD 17","ed":"130028"},
{"muniType":"Congress","region":"Congress CD 17","ed":"130029"},
{"muniType":"Congress","region":"Congress CD 17","ed":"130030"},
{"muniType":"Congress","region":"Congress CD 17","ed":"160005"},
{"muniType":"Congress","region":"Congress CD 17","ed":"160006"},
{"muniType":"Congress","region":"Congress CD 17","ed":"160007"},
{"muniType":"Congress","region":"Congress CD 17","ed":"160008"},
{"muniType":"Congress","region":"Congress CD 17","ed":"160009"},
{"muniType":"Congress","region":"Congress CD 17","ed":"160010"},
{"muniType":"Congress","region":"Congress CD 17","ed":"160011"},
{"muniType":"Congress","region":"Congress CD 17","ed":"160012"},
{"muniType":"Congress","region":"Congress CD 17","ed":"160013"},
{"muniType":"Congress","region":"Congress CD 17","ed":"160014"},
{"muniType":"Congress","region":"Congress CD 17","ed":"160015"},
{"muniType":"Congress","region":"Congress CD 17","ed":"160016"},
{"muniType":"Congress","region":"Congress CD 17","ed":"160017"},
{"muniType":"Congress","region":"Congress CD 17","ed":"160018"},
{"muniType":"Congress","region":"Congress CD 17","ed":"160019"},
{"muniType":"Congress","region":"Congress CD 17","ed":"160020"},
{"muniType":"Congress","region":"Congress CD 17","ed":"160021"},
{"muniType":"Congress","region":"Congress CD 17","ed":"160022"},
{"muniType":"Congress","region":"Congress CD 17","ed":"160023"},
{"muniType":"Congress","region":"Congress CD 17","ed":"160025"},
{"muniType":"Congress","region":"Congress CD 17","ed":"160026"},
{"muniType":"Congress","region":"Congress CD 17","ed":"160027"},
{"muniType":"Congress","region":"Congress CD 17","ed":"160029"},
{"muniType":"Congress","region":"Congress CD 17","ed":"160030"},
{"muniType":"Congress","region":"Congress CD 17","ed":"160031"},
{"muniType":"Congress","region":"Congress CD 17","ed":"170019"},
{"muniType":"Congress","region":"Congress CD 17","ed":"190001"},
{"muniType":"Congress","region":"Congress CD 17","ed":"190002"},
{"muniType":"Congress","region":"Congress CD 17","ed":"190003"},
{"muniType":"Congress","region":"Congress CD 17","ed":"190004"},
{"muniType":"Congress","region":"Congress CD 17","ed":"190005"},
{"muniType":"Congress","region":"Congress CD 17","ed":"190006"},
{"muniType":"Congress","region":"Congress CD 17","ed":"190007"},
{"muniType":"Congress","region":"Congress CD 17","ed":"190008"},
{"muniType":"Congress","region":"Congress CD 17","ed":"190009"},
{"muniType":"Congress","region":"Congress CD 17","ed":"190010"},
{"muniType":"Congress","region":"Congress CD 17","ed":"190011"},
{"muniType":"Congress","region":"Congress CD 17","ed":"190012"},
{"muniType":"Congress","region":"Congress CD 17","ed":"190013"},
{"muniType":"Congress","region":"Congress CD 17","ed":"190014"},
{"muniType":"Congress","region":"Congress CD 17","ed":"190015"},
{"muniType":"Congress","region":"Congress CD 17","ed":"190016"},
{"muniType":"Congress","region":"Congress CD 17","ed":"190017"},
{"muniType":"Congress","region":"Congress CD 17","ed":"190018"},
{"muniType":"Congress","region":"Congress CD 17","ed":"190019"},
{"muniType":"Congress","region":"Congress CD 17","ed":"190020"},
{"muniType":"Congress","region":"Congress CD 17","ed":"190021"},
{"muniType":"Congress","region":"Congress CD 17","ed":"190022"},
{"muniType":"Congress","region":"Congress CD 17","ed":"190023"},
{"muniType":"Congress","region":"Congress CD 17","ed":"190024"},
{"muniType":"Congress","region":"Congress CD 17","ed":"190025"},
{"muniType":"Congress","region":"Congress CD 17","ed":"190026"},
{"muniType":"Congress","region":"Congress CD 17","ed":"190027"},
{"muniType":"Congress","region":"Congress CD 17","ed":"190028"},
{"muniType":"Congress","region":"Congress CD 17","ed":"190029"},
{"muniType":"Congress","region":"Congress CD 17","ed":"190030"},
{"muniType":"Congress","region":"Congress CD 17","ed":"190031"},
{"muniType":"Congress","region":"Congress CD 17","ed":"190033"},
{"muniType":"Congress","region":"Congress CD 17","ed":"190034"},
{"muniType":"Congress","region":"Congress CD 17","ed":"190035"},
{"muniType":"Congress","region":"Congress CD 17","ed":"190036"},
{"muniType":"Congress","region":"Congress CD 17","ed":"190037"},
{"muniType":"Congress","region":"Congress CD 17","ed":"190038"},
{"muniType":"Congress","region":"Congress CD 17","ed":"190039"},
{"muniType":"Congress","region":"Congress CD 17","ed":"190040"},
{"muniType":"Congress","region":"Congress CD 17","ed":"190041"},
{"muniType":"Congress","region":"Congress CD 17","ed":"190042"},
{"muniType":"Congress","region":"Congress CD 17","ed":"190043"},
{"muniType":"Congress","region":"Congress CD 17","ed":"220001"},
{"muniType":"Congress","region":"Congress CD 17","ed":"220002"},
{"muniType":"Congress","region":"Congress CD 17","ed":"220003"},
{"muniType":"Congress","region":"Congress CD 17","ed":"220004"},
{"muniType":"Congress","region":"Congress CD 17","ed":"220005"},
{"muniType":"Congress","region":"Congress CD 17","ed":"220006"},
{"muniType":"Congress","region":"Congress CD 17","ed":"220007"},
{"muniType":"Congress","region":"Congress CD 17","ed":"220008"},
{"muniType":"Congress","region":"Congress CD 17","ed":"220009"},
{"muniType":"Congress","region":"Congress CD 17","ed":"220010"},
{"muniType":"Congress","region":"Congress CD 17","ed":"220011"},
{"muniType":"Congress","region":"Congress CD 17","ed":"220013"},
{"muniType":"Congress","region":"Congress CD 17","ed":"220014"},
{"muniType":"Congress","region":"Congress CD 17","ed":"220015"},
{"muniType":"Congress","region":"Congress CD 17","ed":"220016"},
{"muniType":"Congress","region":"Congress CD 17","ed":"220017"},
{"muniType":"Congress","region":"Congress CD 17","ed":"220018"},
{"muniType":"Congress","region":"Congress CD 17","ed":"220019"},
{"muniType":"Congress","region":"Congress CD 17","ed":"220020"},
{"muniType":"Congress","region":"Congress CD 17","ed":"220021"},
{"muniType":"Congress","region":"Congress CD 17","ed":"220022"},
{"muniType":"Congress","region":"Congress CD 17","ed":"220023"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240001"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240002"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240003"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240004"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240005"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240006"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240007"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240008"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240009"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240010"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240011"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240012"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240013"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240014"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240015"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240016"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240017"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240018"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240019"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240020"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240021"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240022"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240023"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240024"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240025"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240026"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240027"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240028"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240029"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240030"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240031"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240032"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240033"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240034"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240035"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240036"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240037"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240038"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240039"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240040"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240041"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240042"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240043"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240044"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240045"},
{"muniType":"Congress","region":"Congress CD 17","ed":"240046"},
{"muniType":"Congress","region":"Congress CD 18","ed":"010001"},
{"muniType":"Congress","region":"Congress CD 18","ed":"010002"},
{"muniType":"Congress","region":"Congress CD 18","ed":"010003"},
{"muniType":"Congress","region":"Congress CD 18","ed":"010004"},
{"muniType":"Congress","region":"Congress CD 18","ed":"010005"},
{"muniType":"Congress","region":"Congress CD 18","ed":"010006"},
{"muniType":"Congress","region":"Congress CD 18","ed":"010007"},
{"muniType":"Congress","region":"Congress CD 18","ed":"010008"},
{"muniType":"Congress","region":"Congress CD 18","ed":"010009"},
{"muniType":"Congress","region":"Congress CD 18","ed":"010010"},
{"muniType":"Congress","region":"Congress CD 18","ed":"010011"},
{"muniType":"Congress","region":"Congress CD 18","ed":"010012"},
{"muniType":"Congress","region":"Congress CD 18","ed":"010013"},
{"muniType":"Congress","region":"Congress CD 18","ed":"010014"},
{"muniType":"Congress","region":"Congress CD 18","ed":"010015"},
{"muniType":"Congress","region":"Congress CD 18","ed":"010016"},
{"muniType":"Congress","region":"Congress CD 18","ed":"010017"},
{"muniType":"Congress","region":"Congress CD 18","ed":"010018"},
{"muniType":"Congress","region":"Congress CD 18","ed":"060001"},
{"muniType":"Congress","region":"Congress CD 18","ed":"060002"},
{"muniType":"Congress","region":"Congress CD 18","ed":"060003"},
{"muniType":"Congress","region":"Congress CD 18","ed":"060004"},
{"muniType":"Congress","region":"Congress CD 18","ed":"060005"},
{"muniType":"Congress","region":"Congress CD 18","ed":"060006"},
{"muniType":"Congress","region":"Congress CD 18","ed":"060007"},
{"muniType":"Congress","region":"Congress CD 18","ed":"060008"},
{"muniType":"Congress","region":"Congress CD 18","ed":"060009"},
{"muniType":"Congress","region":"Congress CD 18","ed":"060010"},
{"muniType":"Congress","region":"Congress CD 18","ed":"060011"},
{"muniType":"Congress","region":"Congress CD 18","ed":"060012"},
{"muniType":"Congress","region":"Congress CD 18","ed":"100007"},
{"muniType":"Congress","region":"Congress CD 18","ed":"100009"},
{"muniType":"Congress","region":"Congress CD 18","ed":"110001"},
{"muniType":"Congress","region":"Congress CD 18","ed":"110006"},
{"muniType":"Congress","region":"Congress CD 18","ed":"110007"},
{"muniType":"Congress","region":"Congress CD 18","ed":"110009"},
{"muniType":"Congress","region":"Congress CD 18","ed":"110011"},
{"muniType":"Congress","region":"Congress CD 18","ed":"120001"},
{"muniType":"Congress","region":"Congress CD 18","ed":"120002"},
{"muniType":"Congress","region":"Congress CD 18","ed":"120003"},
{"muniType":"Congress","region":"Congress CD 18","ed":"120004"},
{"muniType":"Congress","region":"Congress CD 18","ed":"120005"},
{"muniType":"Congress","region":"Congress CD 18","ed":"120006"},
{"muniType":"Congress","region":"Congress CD 18","ed":"120007"},
{"muniType":"Congress","region":"Congress CD 18","ed":"120008"},
{"muniType":"Congress","region":"Congress CD 18","ed":"150001"},
{"muniType":"Congress","region":"Congress CD 18","ed":"150002"},
{"muniType":"Congress","region":"Congress CD 18","ed":"150003"},
{"muniType":"Congress","region":"Congress CD 18","ed":"150004"},
{"muniType":"Congress","region":"Congress CD 18","ed":"180001"},
{"muniType":"Congress","region":"Congress CD 18","ed":"180002"},
{"muniType":"Congress","region":"Congress CD 18","ed":"180003"},
{"muniType":"Congress","region":"Congress CD 18","ed":"180004"},
{"muniType":"Congress","region":"Congress CD 18","ed":"180005"},
{"muniType":"Congress","region":"Congress CD 18","ed":"180006"},
{"muniType":"Congress","region":"Congress CD 18","ed":"180007"},
{"muniType":"Congress","region":"Congress CD 18","ed":"180008"},
{"muniType":"Congress","region":"Congress CD 18","ed":"180009"},
{"muniType":"Congress","region":"Congress CD 18","ed":"180010"},
{"muniType":"Congress","region":"Congress CD 18","ed":"180011"},
{"muniType":"Congress","region":"Congress CD 18","ed":"180012"},
{"muniType":"Congress","region":"Congress CD 18","ed":"180013"},
{"muniType":"Congress","region":"Congress CD 18","ed":"180014"},
{"muniType":"Congress","region":"Congress CD 18","ed":"180015"},
{"muniType":"Congress","region":"Congress CD 18","ed":"180016"},
{"muniType":"Congress","region":"Congress CD 18","ed":"180017"},
{"muniType":"Congress","region":"Congress CD 18","ed":"180018"},
{"muniType":"Congress","region":"Congress CD 18","ed":"190032"},
{"muniType":"State Senate","region":"State Senate SD 34","ed":"140001"},
{"muniType":"State Senate","region":"State Senate SD 34","ed":"140002"},
{"muniType":"State Senate","region":"State Senate SD 34","ed":"140003"},
{"muniType":"State Senate","region":"State Senate SD 34","ed":"140004"},
{"muniType":"State Senate","region":"State Senate SD 34","ed":"140005"},
{"muniType":"State Senate","region":"State Senate SD 34","ed":"140006"},
{"muniType":"State Senate","region":"State Senate SD 34","ed":"140007"},
{"muniType":"State Senate","region":"State Senate SD 34","ed":"140008"},
{"muniType":"State Senate","region":"State Senate SD 34","ed":"140009"},
{"muniType":"State Senate","region":"State Senate SD 34","ed":"140010"},
{"muniType":"State Senate","region":"State Senate SD 34","ed":"200035"},
{"muniType":"State Senate","region":"State Senate SD 34","ed":"200038"},
{"muniType":"State Senate","region":"State Senate SD 34","ed":"200039"},
{"muniType":"State Senate","region":"State Senate SD 34","ed":"200040"},
{"muniType":"State Senate","region":"State Senate SD 34","ed":"200047"},
{"muniType":"State Senate","region":"State Senate SD 34","ed":"200053"},
{"muniType":"State Senate","region":"State Senate SD 34","ed":"200054"},
{"muniType":"State Senate","region":"State Senate SD 34","ed":"200061"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040001"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040002"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040003"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040004"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040005"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040006"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040007"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040008"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040009"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040010"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040011"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040012"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040013"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040014"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040015"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040016"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040017"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040018"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040019"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040020"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040021"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040022"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040023"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040024"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040025"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040026"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040027"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040028"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040029"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040030"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040031"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040032"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040033"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040034"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040035"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040036"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040037"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040038"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040039"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040040"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040041"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040042"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040043"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040044"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040045"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040046"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040047"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040048"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040049"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040050"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040051"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040052"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040053"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040054"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040055"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040056"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040057"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040058"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040059"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040060"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040061"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040062"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040063"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040064"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040065"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040066"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040067"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040068"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040069"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040070"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040071"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040072"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040073"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040074"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040075"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040076"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040077"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040078"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040079"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040080"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"040081"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"170001"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"170002"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"170003"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"170004"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"170005"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"170006"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"170007"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"170008"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"170009"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"170010"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"170011"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"170012"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"170013"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"170014"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"170015"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"170016"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"170017"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"170018"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"170019"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"210002"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"210005"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"210006"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"210009"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"210010"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"210011"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"210012"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"210014"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"210015"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"210019"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"210021"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"210033"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"210035"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"210038"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"210042"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"210044"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"210049"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"210050"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"210051"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"210052"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"210054"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"210057"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"210058"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"210059"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"210060"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"210064"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"210066"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"210075"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"210076"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"210077"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"210078"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"210079"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"240004"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"240005"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"240007"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"240009"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"240010"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"240012"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"240013"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"240015"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"240016"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"240017"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"240019"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"240021"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"240023"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"240024"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"240025"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"240026"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"240028"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"240029"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"240030"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"240031"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"240032"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"240033"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"240034"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"240035"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"240036"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"240037"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"240038"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"240039"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"240040"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"240041"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"240042"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"240043"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"240044"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"240045"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"240046"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250101"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250102"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250103"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250104"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250105"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250106"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250107"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250108"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250109"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250110"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250111"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250112"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250118"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250122"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250126"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250201"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250202"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250207"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250208"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250209"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250210"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250211"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250214"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250218"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250220"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250222"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250224"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250226"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250301"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250302"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250303"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250304"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250305"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250306"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250307"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250308"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250310"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250311"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250312"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250313"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250315"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250316"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250317"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250319"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250320"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250321"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250323"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250324"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250325"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250326"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250327"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250328"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250329"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250401"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250402"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250403"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250404"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250405"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250406"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250407"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250408"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250409"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250410"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250411"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250412"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250415"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250416"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250417"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250418"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250419"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250421"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250601"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250602"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250603"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250604"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250606"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250607"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250608"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250610"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250611"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250612"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250613"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250614"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250616"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250617"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250618"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250620"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250621"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250622"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250624"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250628"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250632"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250633"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250702"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250703"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250704"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250705"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250706"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250707"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250708"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250709"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250711"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250713"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250714"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250715"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250716"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250717"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250719"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250720"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250721"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250723"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250724"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250725"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250726"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250729"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250730"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250731"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250801"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250802"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250803"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250805"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250806"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250807"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250809"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250810"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250811"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250812"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250813"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250814"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250815"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250817"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250822"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250823"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250824"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250826"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"250828"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"251003"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"251005"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"251006"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"251007"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"251011"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"251013"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"251018"},
{"muniType":"State Senate","region":"State Senate SD 35","ed":"251025"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200001"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200002"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200003"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200004"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200005"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200006"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200007"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200008"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200009"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200010"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200011"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200012"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200013"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200014"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200015"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200016"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200017"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200018"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200019"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200020"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200021"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200022"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200023"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200024"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200025"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200026"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200027"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200028"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200029"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200030"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200031"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200032"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200033"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200034"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200036"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200037"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200041"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200042"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200043"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200044"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200045"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200046"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200048"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200049"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200050"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200051"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200052"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200055"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200056"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200057"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200058"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200059"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200060"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200062"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200064"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200065"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200066"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200067"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200068"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200069"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200070"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200071"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200072"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200073"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200074"},
{"muniType":"State Senate","region":"State Senate SD 36","ed":"200076"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"010001"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"010002"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"010003"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"010004"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"010005"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"010006"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"010007"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"010008"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"010009"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"010010"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"010011"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"010012"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"010013"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"010014"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"010015"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"010016"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"010017"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"010018"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"030001"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"030002"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"030003"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"030004"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"030005"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"030006"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"030007"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"030008"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"030009"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"030010"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"030011"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"030012"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"030013"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"030014"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"030015"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"030016"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"030017"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"030018"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"030019"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"030020"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"030021"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"030022"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"030023"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"030024"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"030025"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"030026"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"030027"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"030028"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"030029"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"030030"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"030031"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"030032"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"030033"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"030034"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"030035"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"030036"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"030037"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"030038"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"030039"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"050001"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"050002"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"050003"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"050004"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"050005"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"050006"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"050007"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"050008"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"050009"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"050010"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"050011"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"050012"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"050013"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"050014"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"050015"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"050016"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"050017"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"050018"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"050019"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"050020"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"070001"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"070002"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"070003"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"070004"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"070005"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"070006"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"070007"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"070008"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"070009"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"070010"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"070011"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"070012"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"070013"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"070014"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"070015"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"070016"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"070017"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"070018"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"070019"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"070020"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"070021"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"070022"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"070023"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"070024"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"070025"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"070026"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"070027"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"070028"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"110001"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"110002"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"110003"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"110004"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"110005"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"110006"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"110007"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"110008"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"110009"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"110010"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"110011"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"160001"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"160002"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"160003"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"160004"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"160005"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"160006"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"160007"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"160008"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"160009"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"160010"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"160011"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"160012"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"160013"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"160014"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"160015"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"160016"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"160017"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"160018"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"160019"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"160020"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"160021"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"160022"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"160023"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"160024"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"160025"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"160026"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"160027"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"160028"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"160029"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"160030"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"160031"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210001"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210003"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210004"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210007"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210008"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210013"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210016"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210018"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210022"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210023"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210024"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210025"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210026"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210027"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210028"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210029"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210030"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210031"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210032"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210034"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210036"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210037"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210039"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210040"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210041"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210043"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210045"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210046"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210047"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210048"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210053"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210055"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210056"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210061"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210063"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210065"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210067"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210068"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210069"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210070"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210071"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210072"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210073"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"210074"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"230001"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"230002"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"230003"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"230004"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"230005"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"230006"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"230007"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"230008"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"230009"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"230010"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"230011"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"230012"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"230013"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"230014"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"240001"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"240002"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"240003"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"240006"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"240008"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"240011"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"240014"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"240018"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"240020"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"240022"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"240027"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250203"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250204"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250205"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250206"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250212"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250213"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250216"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250223"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250229"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250501"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250502"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250503"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250504"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250505"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250506"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250507"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250508"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250509"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250510"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250511"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250512"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250513"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250514"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250515"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250516"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250517"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250518"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250519"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250520"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250522"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250523"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250524"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250701"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250901"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250902"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250903"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250904"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250905"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250906"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250907"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250908"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250909"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250910"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250911"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250912"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250913"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250915"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250916"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"250917"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251001"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251002"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251004"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251008"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251009"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251012"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251014"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251015"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251016"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251017"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251020"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251021"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251101"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251102"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251103"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251104"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251105"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251106"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251107"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251108"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251109"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251110"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251111"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251112"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251113"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251114"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251116"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251117"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251118"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251119"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251120"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251121"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251201"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251202"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251203"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251204"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251205"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251206"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251207"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251208"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251209"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251210"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251211"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251212"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251213"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251215"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251216"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251217"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251218"},
{"muniType":"State Senate","region":"State Senate SD 37","ed":"251219"},
{"muniType":"State Senate","region":"State Senate SD 38","ed":"130001"},
{"muniType":"State Senate","region":"State Senate SD 38","ed":"130002"},
{"muniType":"State Senate","region":"State Senate SD 38","ed":"130003"},
{"muniType":"State Senate","region":"State Senate SD 38","ed":"130004"},
{"muniType":"State Senate","region":"State Senate SD 38","ed":"130005"},
{"muniType":"State Senate","region":"State Senate SD 38","ed":"130006"},
{"muniType":"State Senate","region":"State Senate SD 38","ed":"130007"},
{"muniType":"State Senate","region":"State Senate SD 38","ed":"130008"},
{"muniType":"State Senate","region":"State Senate SD 38","ed":"130009"},
{"muniType":"State Senate","region":"State Senate SD 38","ed":"130010"},
{"muniType":"State Senate","region":"State Senate SD 38","ed":"130011"},
{"muniType":"State Senate","region":"State Senate SD 38","ed":"130012"},
{"muniType":"State Senate","region":"State Senate SD 38","ed":"130013"},
{"muniType":"State Senate","region":"State Senate SD 38","ed":"130014"},
{"muniType":"State Senate","region":"State Senate SD 38","ed":"130015"},
{"muniType":"State Senate","region":"State Senate SD 38","ed":"130016"},
{"muniType":"State Senate","region":"State Senate SD 38","ed":"130017"},
{"muniType":"State Senate","region":"State Senate SD 38","ed":"130018"},
{"muniType":"State Senate","region":"State Senate SD 38","ed":"130019"},
{"muniType":"State Senate","region":"State Senate SD 38","ed":"130020"},
{"muniType":"State Senate","region":"State Senate SD 38","ed":"130021"},
{"muniType":"State Senate","region":"State Senate SD 38","ed":"130022"},
{"muniType":"State Senate","region":"State Senate SD 38","ed":"130023"},
{"muniType":"State Senate","region":"State Senate SD 38","ed":"130024"},
{"muniType":"State Senate","region":"State Senate SD 38","ed":"130025"},
{"muniType":"State Senate","region":"State Senate SD 38","ed":"130026"},
{"muniType":"State Senate","region":"State Senate SD 38","ed":"130027"},
{"muniType":"State Senate","region":"State Senate SD 38","ed":"130028"},
{"muniType":"State Senate","region":"State Senate SD 38","ed":"130029"},
{"muniType":"State Senate","region":"State Senate SD 38","ed":"130030"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"020001"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"020002"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"020003"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"020004"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"020005"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"020006"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"020007"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"020008"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"020009"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"020010"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"020011"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"020012"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"020013"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"020014"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"020015"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"020016"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"020017"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"020018"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"020019"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"020020"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"020021"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"020022"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"020023"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"020024"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"020025"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"020026"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"020027"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"020028"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"020029"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"020030"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"020031"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"020032"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"020033"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"020034"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"020035"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"020038"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"020039"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"060001"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"060002"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"060003"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"060004"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"060005"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"060006"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"060007"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"060008"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"060009"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"060010"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"060011"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"060012"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"080001"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"080002"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"080003"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"080004"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"080005"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"080006"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"080007"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"090001"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"090002"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"090003"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"090004"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"090005"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"090006"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"090007"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"090009"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"090010"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"090011"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"090012"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"090013"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"090014"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"090015"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"090016"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"090017"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"090018"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"090019"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"090020"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"090022"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"090023"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"090024"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"090025"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"090026"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"090027"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"090028"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"090029"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"090030"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"090031"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"090032"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"090033"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"090034"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"090035"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"090036"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"090037"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"090038"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"090039"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"090040"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"090041"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"090042"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"090043"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"090044"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"100001"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"100002"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"100003"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"100004"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"100005"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"100006"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"100007"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"100008"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"100009"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"100010"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"100011"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"100012"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"100013"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"100014"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"100015"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"100016"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"120001"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"120002"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"120003"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"120004"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"120005"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"120006"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"120007"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"120008"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"150001"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"150002"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"150003"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"150004"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"180001"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"180002"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"180003"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"180004"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"180005"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"180006"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"180007"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"180008"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"180009"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"180010"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"180011"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"180012"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"180013"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"180014"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"180015"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"180016"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"180017"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"180018"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"190001"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"190002"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"190003"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"190004"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"190005"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"190006"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"190007"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"190008"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"190009"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"190010"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"190011"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"190012"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"190013"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"190014"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"190015"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"190016"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"190017"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"190018"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"190019"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"190020"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"190021"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"190022"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"190023"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"190024"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"190025"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"190026"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"190027"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"190028"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"190029"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"190030"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"190031"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"190032"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"190033"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"190034"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"190035"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"190036"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"190037"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"190038"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"190039"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"190040"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"190041"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"190042"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"190043"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"220001"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"220002"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"220003"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"220004"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"220005"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"220006"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"220007"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"220008"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"220009"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"220010"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"220011"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"220013"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"220014"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"220015"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"220016"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"220017"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"220018"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"220019"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"220020"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"220021"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"220022"},
{"muniType":"State Senate","region":"State Senate SD 40","ed":"220023"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"030001"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"030002"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"030003"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"030004"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"030005"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"030006"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"030007"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"030008"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"030009"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"030010"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"030011"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"030012"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"030013"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"030014"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"030015"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"030016"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"030017"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"030018"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"030019"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"030020"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"030021"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"030022"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"030023"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"030024"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"030025"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"030026"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"030027"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"030028"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"030029"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"030030"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"030031"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"030032"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"030033"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"030034"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"030035"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"030036"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"030037"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"030038"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"030039"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"140001"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"140002"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"140003"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"140004"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"140005"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"140006"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"140007"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"140008"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"140009"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"140010"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"170001"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"170002"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"170003"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"170004"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"170005"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"170006"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"170007"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"170008"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"170009"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"170010"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"170011"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"170012"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"170013"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"170014"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"170015"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"170016"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"170017"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"170018"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"170019"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210004"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210007"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210010"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210011"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210012"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210013"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210014"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210015"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210016"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210021"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210027"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210030"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210031"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210032"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210033"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210034"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210035"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210036"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210037"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210038"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210039"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210040"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210041"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210042"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210043"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210044"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210045"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210046"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210047"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210048"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210049"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210053"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210057"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210058"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210059"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210060"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210061"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210063"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210066"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210067"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210068"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210069"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210070"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210072"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210076"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"210078"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"240002"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"240003"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"240004"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"240005"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"240006"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"240007"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"240009"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"240010"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"240011"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"240012"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"240013"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"240014"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"240015"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"240021"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"240032"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"240033"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"240034"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"240035"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"240036"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"240038"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"240039"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"240040"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"240041"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"240042"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"240043"},
{"muniType":"State Assembly","region":"State Assembly AD 88","ed":"240045"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200001"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200002"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200003"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200004"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200005"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200006"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200007"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200008"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200009"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200010"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200011"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200012"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200013"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200014"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200015"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200016"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200017"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200018"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200019"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200020"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200021"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200022"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200023"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200024"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200025"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200026"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200027"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200028"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200029"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200030"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200031"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200032"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200033"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200034"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200035"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200036"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200037"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200038"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200039"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200040"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200041"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200042"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200043"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200044"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200045"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200046"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200047"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200048"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200049"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200050"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200051"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200052"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200053"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200054"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200055"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200056"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200057"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200058"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200059"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200060"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200061"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200062"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200064"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200065"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200066"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200067"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200068"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200069"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200070"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200071"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200072"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200073"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200074"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"200076"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250101"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250102"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250103"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250104"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250107"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250108"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250111"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250112"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250118"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250122"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250126"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250208"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250209"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250210"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250211"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250214"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250220"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250224"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250226"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250303"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250305"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250307"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250308"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250313"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250316"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250323"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250324"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250327"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250328"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250329"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250402"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250403"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250405"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250406"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250407"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250408"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250409"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250410"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250411"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250412"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250415"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250416"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250418"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250419"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250421"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250801"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250802"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250803"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250806"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250809"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250815"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250822"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250823"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250901"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250902"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250903"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250904"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250905"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250906"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250907"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250908"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250911"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250912"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250913"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250915"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"250916"},
{"muniType":"State Assembly","region":"State Assembly AD 89","ed":"251201"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250105"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250106"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250109"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250110"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250201"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250202"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250203"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250204"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250205"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250206"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250207"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250212"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250213"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250216"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250218"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250222"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250223"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250229"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250301"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250302"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250304"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250306"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250310"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250311"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250312"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250315"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250317"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250319"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250320"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250321"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250325"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250326"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250401"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250404"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250417"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250501"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250502"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250503"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250504"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250505"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250506"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250507"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250508"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250509"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250510"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250511"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250512"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250513"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250514"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250515"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250516"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250517"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250518"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250519"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250520"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250522"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250523"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250524"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250601"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250602"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250603"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250604"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250606"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250607"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250608"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250610"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250611"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250612"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250613"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250614"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250616"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250617"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250618"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250620"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250621"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250622"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250624"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250628"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250632"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250633"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250701"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250702"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250703"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250704"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250705"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250706"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250707"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250708"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250709"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250711"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250713"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250714"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250715"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250716"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250717"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250719"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250720"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250721"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250723"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250724"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250725"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250726"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250729"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250730"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250731"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250805"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250807"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250810"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250811"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250812"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250813"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250814"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250817"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250824"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250826"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250828"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250909"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250910"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"250917"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251001"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251002"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251003"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251004"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251005"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251006"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251007"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251008"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251009"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251011"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251012"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251013"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251014"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251015"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251016"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251017"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251018"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251020"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251021"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251025"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251101"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251102"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251103"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251104"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251105"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251106"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251107"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251108"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251109"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251110"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251111"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251112"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251113"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251114"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251116"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251117"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251118"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251119"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251120"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251121"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251202"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251203"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251204"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251205"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251206"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251207"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251208"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251209"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251210"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251211"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251212"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251213"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251215"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251216"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251217"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251218"},
{"muniType":"State Assembly","region":"State Assembly AD 90","ed":"251219"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"070001"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"070002"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"070003"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"070004"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"070005"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"070006"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"070007"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"070008"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"070009"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"070010"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"070011"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"070012"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"070013"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"070014"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"070015"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"070016"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"070017"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"070018"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"070019"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"070020"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"070021"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"070022"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"070023"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"070024"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"070025"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"070026"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"070027"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"070028"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"160001"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"160002"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"160003"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"160004"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"160005"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"160006"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"160007"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"160008"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"160009"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"160010"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"160011"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"160012"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"160013"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"160014"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"160015"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"160016"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"160017"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"160018"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"160019"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"160020"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"160021"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"160022"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"160023"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"160024"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"160025"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"160026"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"160027"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"160028"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"160029"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"160030"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"160031"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"210001"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"210002"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"210003"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"210005"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"210006"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"210008"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"210009"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"210018"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"210019"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"210022"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"210023"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"210024"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"210025"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"210026"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"210028"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"210029"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"210050"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"210051"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"210052"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"210054"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"210055"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"210056"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"210064"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"210065"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"210071"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"210073"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"210074"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"210075"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"210077"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"210079"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"230001"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"230002"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"230003"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"230004"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"230005"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"230006"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"230007"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"230008"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"230009"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"230010"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"230011"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"230012"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"230013"},
{"muniType":"State Assembly","region":"State Assembly AD 91","ed":"230014"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040001"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040002"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040003"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040004"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040005"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040006"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040007"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040008"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040009"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040010"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040011"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040012"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040013"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040014"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040015"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040016"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040017"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040018"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040019"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040020"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040021"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040022"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040023"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040024"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040025"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040026"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040027"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040028"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040029"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040030"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040031"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040032"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040033"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040034"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040035"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040036"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040037"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040038"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040039"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040040"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040041"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040042"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040043"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040044"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040045"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040046"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040047"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040048"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040049"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040050"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040051"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040052"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040053"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040054"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040055"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040056"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040057"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040058"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040059"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040060"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040061"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040062"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040063"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040064"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040065"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040066"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040067"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040068"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040069"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040070"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040071"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040072"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040073"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040074"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040075"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040076"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040077"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040078"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040079"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040080"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"040081"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"090001"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"090002"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"090003"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"090004"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"090005"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"090006"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"090007"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"090009"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"090010"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"090011"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"090012"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"090013"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"090014"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"090015"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"090016"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"090017"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"090018"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"090019"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"090020"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"090022"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"090023"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"090024"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"090025"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"090026"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"090027"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"090028"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"090029"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"090030"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"090031"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"090032"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"090033"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"090034"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"090035"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"090036"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"090037"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"090038"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"090039"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"090040"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"090041"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"090042"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"090043"},
{"muniType":"State Assembly","region":"State Assembly AD 92","ed":"090044"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"010001"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"010002"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"010003"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"010004"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"010005"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"010006"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"010007"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"010008"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"010009"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"010010"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"010011"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"010012"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"010013"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"010014"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"010015"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"010016"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"010017"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"010018"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"050001"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"050002"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"050003"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"050004"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"050005"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"050006"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"050007"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"050008"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"050009"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"050010"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"050011"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"050012"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"050013"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"050014"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"050015"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"050016"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"050017"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"050018"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"050019"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"050020"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"060001"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"060002"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"060003"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"060004"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"060005"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"060006"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"060007"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"060008"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"060009"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"060010"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"060011"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"060012"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"080001"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"080002"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"080003"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"080004"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"080005"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"080006"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"080007"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"100001"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"100002"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"100003"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"100004"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"100005"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"100006"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"100007"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"100008"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"100009"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"100010"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"100011"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"100012"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"100013"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"100014"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"100015"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"100016"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"110001"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"110002"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"110003"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"110004"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"110005"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"110006"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"110007"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"110008"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"110009"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"110010"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"110011"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"120001"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"120002"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"120003"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"120004"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"120005"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"120006"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"120007"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"120008"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"150001"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"150002"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"150003"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"150004"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"240001"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"240008"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"240016"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"240017"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"240018"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"240019"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"240020"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"240022"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"240023"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"240024"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"240025"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"240026"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"240027"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"240028"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"240029"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"240030"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"240031"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"240037"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"240044"},
{"muniType":"State Assembly","region":"State Assembly AD 93","ed":"240046"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"180001"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"180002"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"180003"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"180004"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"180005"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"180006"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"180007"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"180008"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"180009"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"180010"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"180011"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"180012"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"180013"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"180014"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"180015"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"180016"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"180017"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"180018"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"190001"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"190002"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"190003"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"190004"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"190005"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"190006"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"190007"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"190008"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"190009"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"190010"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"190011"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"190012"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"190013"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"190014"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"190015"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"190016"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"190017"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"190018"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"190019"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"190020"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"190021"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"190022"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"190023"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"190024"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"190025"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"190026"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"190027"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"190028"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"190029"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"190030"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"190031"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"190032"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"190033"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"190034"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"190035"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"190036"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"190037"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"190038"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"190039"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"190040"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"190041"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"190042"},
{"muniType":"State Assembly","region":"State Assembly AD 94","ed":"190043"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"020001"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"020002"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"020003"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"020004"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"020005"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"020006"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"020007"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"020008"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"020009"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"020010"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"020011"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"020012"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"020013"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"020014"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"020015"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"020016"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"020017"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"020018"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"020019"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"020020"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"020021"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"020022"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"020023"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"020024"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"020025"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"020026"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"020027"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"020028"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"020029"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"020030"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"020031"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"020032"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"020033"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"020034"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"020035"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"020038"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"020039"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"130001"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"130002"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"130003"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"130004"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"130005"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"130006"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"130007"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"130008"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"130009"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"130010"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"130011"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"130012"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"130013"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"130014"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"130015"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"130016"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"130017"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"130018"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"130019"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"130020"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"130021"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"130022"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"130023"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"130024"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"130025"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"130026"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"130027"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"130028"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"130029"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"130030"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"220001"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"220002"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"220003"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"220004"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"220005"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"220006"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"220007"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"220008"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"220009"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"220010"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"220011"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"220013"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"220014"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"220015"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"220016"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"220017"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"220018"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"220019"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"220020"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"220021"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"220022"},
{"muniType":"State Assembly","region":"State Assembly AD 95","ed":"220023"}]


export default regionPicked;